import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid, IconButton, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

import Select from '../components/Select'
import DialogMini from '../components/DialogMini'
import InputField from '../components/InputField'
import AutoComplete from '../components/AutoComplete'
import { ColouredButton } from '../components/Buttons'
import { sendRequest } from '../hooks/http-hook'
import { errorFunctions } from '../hooks/error-functions'
import { sortFunctions } from '../hooks/sort-functions'
import {
  colours,
  titlesIscrizione as titles,
  phrasesIscrizione as phrases,
  GIORNI,
  ORARI,
} from '../settings/settings'
import { SET_ERROR } from '../container/home/types'
import { SET_ISCRIZIONI } from '../container/iscrizioni/types'
import {
  RESET_ISCRIZIONE,
  SET_CODICE_CAMPIONATO,
  SET_CODICE_SOCIETA,
  SET_NOME_SQUADRA,
  SET_CATEGORIA,
  SET_SENZA_PALESTRA,
  SET_GIORNO,
  SET_ORARIO,
  SET_PRESSO,
  SET_INDIRIZZO,
  SET_CITTA,
  SET_NOME_REFERENTE,
  SET_TELEFONO_REFERENTE,
  SET_MAIL_REFERENTE,
  SET_NOME_REFERENTE_2,
  SET_TELEFONO_REFERENTE_2,
  SET_MAIL_REFERENTE_2,
  SET_ABBINAMENTI,
  SET_ALTERNANZE,
  SET_NOTE,
  SET_CHECK_PRIVACY,
  SET_HAS_DEROGA,
  SET_MOTIVO_DEROGA,
} from '../container/iscrizione/types'

const Iscrizione = ({
  admin,
  codiceUtente,
  impostazioni,
  categorie,
  selectedCampionato,
  selectedCampionatoIscrizioniAperte,
  iscrizioni,
  mode,
  idSquadra,
  codiceCampionato,
  codiceSocieta,
  nomeSquadra,
  categoria,
  senzaPalestra,
  giorno,
  orario,
  presso,
  indirizzo,
  citta,
  nomeReferente,
  telefonoReferente,
  mailReferente,
  nomeReferente2,
  telefonoReferente2,
  mailReferente2,
  abbinamenti,
  alternanze,
  note,
  checkPrivacy,
  girone,
  posizione,
  hasDeroga,
  motivoDeroga,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = `PGS Milano - ${
      mode === 'add' ? 'Nuova' : 'Modifica'
    } iscrizione`
  }, [mode])

  const [campionatoIscrizioni, setCampionatoIscrizioni] = useState()

  useEffect(() => {
    if (!!impostazioni?.iscrizioniAperteInvernale) {
      setCampionatoIscrizioni(impostazioni?.campionatoInvernale)
    } else if (!!impostazioni?.iscrizioniApertePrimaverile) {
      setCampionatoIscrizioni(impostazioni?.campionatoPrimaverile)
    } else {
      setCampionatoIscrizioni()
    }
  }, [impostazioni])

  const [iscrizioniAperte, setIscrizioniAperte] = useState()

  useEffect(() => {
    setIscrizioniAperte(
      admin ||
        ((admin || mode !== 'add' ? codiceCampionato : campionatoIscrizioni) ===
          selectedCampionato &&
          selectedCampionatoIscrizioniAperte)
    )
  }, [
    admin,
    campionatoIscrizioni,
    codiceCampionato,
    mode,
    selectedCampionato,
    selectedCampionatoIscrizioniAperte,
  ])

  const [availableCampionati, setAvailableCampionati] = useState()
  const [availableSocieta, setAvailableSocieta] = useState()
  const [completeInputs, setCompleteInputs] = useState(false)
  const [validInputs, setValidInputs] = useState(false)
  const [gironiChangingCheck, setGironiChangingCheck] = useState(false)

  // error functions
  const { errorMinLength, errorMaxLength, errorTelefono, errorMail } =
    errorFunctions()
  const errorNome = useCallback(x => errorMinLength(x, 6), [errorMinLength])
  const errorIndirizzo = useCallback(
    x => errorMinLength(x, 6),
    [errorMinLength]
  )
  const errorCitta = useCallback(x => errorMinLength(x, 3), [errorMinLength])
  const errorReferente = useCallback(
    x => errorMinLength(x, 6),
    [errorMinLength]
  )
  const errorReferente2 = useCallback(
    x => x && errorReferente(x),
    [errorReferente]
  )
  const errorTelefono2 = useCallback(
    x => x && errorTelefono(x),
    [errorTelefono]
  )
  const errorMail2 = useCallback(x => x && errorMail(x), [errorMail])
  const errorRichieste = useCallback(
    x => errorMaxLength(x, 70),
    [errorMaxLength]
  )

  // sort functions
  const { sortCampionati, sortCodiceSocieta } = sortFunctions()
  const sortCampionatiM = useCallback(sortCampionati, [])
  const sortCodiceSocietaM = useCallback(sortCodiceSocieta, [])

  const [openUndo, setOpenUndo] = useState(false)
  const [openCheck, setOpenCheck] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openFailure, setOpenFailure] = useState(false)
  const [openInfo, setOpenInfo] = useState(false)
  const [openDeroga, setOpenDeroga] = useState(false)

  const handleClickOpenUndo = () => {
    setOpenUndo(true)
  }

  const handleClickOpenCheck = () => {
    setOpenCheck(true)
  }

  const handleClickOpenSuccess = () => {
    setOpenSuccess(true)
  }

  const handleClickOpenFailure = () => {
    setOpenFailure(true)
  }

  const handleClickOpenInfo = () => {
    setOpenInfo(true)
  }

  const handleClickOpenDeroga = () => {
    setOpenDeroga(true)
  }

  const handleCloseUndo = () => {
    setOpenUndo(false)
  }

  const handleCloseCheck = () => {
    setOpenCheck(false)
  }

  const handleCloseSuccess = () => {
    setOpenSuccess(false)
  }

  const handleCloseFailure = () => {
    setOpenFailure(false)
  }

  const handleCloseInfo = () => {
    setOpenInfo(false)
  }

  const handleCloseDeroga = () => {
    setOpenDeroga(false)
  }

  // check inputs completeness
  useEffect(() => {
    if (
      (admin || mode !== 'add' ? codiceCampionato : campionatoIscrizioni) &&
      (admin
        ? availableSocieta?.map(s => s.codiceSocieta)?.includes(codiceSocieta)
        : codiceUtente) &&
      !errorNome(nomeSquadra) &&
      categorie?.map(c => c.categoria)?.includes(categoria) &&
      (!!senzaPalestra ||
        (GIORNI.includes(giorno) &&
          ORARI.includes(orario) &&
          !errorIndirizzo(indirizzo) &&
          !errorCitta(citta))) &&
      !errorReferente(nomeReferente) &&
      !errorTelefono(telefonoReferente) &&
      !errorMail(mailReferente) &&
      !errorReferente2(nomeReferente2) &&
      !errorTelefono2(telefonoReferente2) &&
      !errorMail2(mailReferente2) &&
      !errorRichieste(note) &&
      !!checkPrivacy
    ) {
      setCompleteInputs(true)
    } else {
      setCompleteInputs(false)
    }
  }, [
    admin,
    availableSocieta,
    campionatoIscrizioni,
    categoria,
    categorie,
    checkPrivacy,
    citta,
    codiceCampionato,
    codiceSocieta,
    codiceUtente,
    errorCitta,
    errorIndirizzo,
    errorMail,
    errorMail2,
    errorNome,
    errorReferente,
    errorReferente2,
    errorRichieste,
    errorTelefono,
    errorTelefono2,
    giorno,
    indirizzo,
    mailReferente,
    mailReferente2,
    mode,
    nomeReferente,
    nomeReferente2,
    nomeSquadra,
    note,
    orario,
    senzaPalestra,
    telefonoReferente,
    telefonoReferente2,
  ])

  // check inputs validity
  useEffect(() => {
    if (
      (admin || mode !== 'add' ? codiceCampionato : campionatoIscrizioni) &&
      (admin
        ? availableSocieta?.map(s => s.codiceSocieta)?.includes(codiceSocieta)
        : codiceUtente) &&
      !errorNome(nomeSquadra) &&
      categorie?.map(c => c.categoria)?.includes(categoria) &&
      !errorReferente(nomeReferente) &&
      !errorTelefono(telefonoReferente) &&
      !errorMail(mailReferente) &&
      !!checkPrivacy
    ) {
      setValidInputs(true)
    } else {
      setValidInputs(false)
    }
  }, [
    admin,
    availableSocieta,
    campionatoIscrizioni,
    categoria,
    categorie,
    checkPrivacy,
    codiceCampionato,
    codiceSocieta,
    codiceUtente,
    errorMail,
    errorNome,
    errorReferente,
    errorTelefono,
    mailReferente,
    mode,
    nomeReferente,
    nomeSquadra,
    telefonoReferente,
  ])

  // array equality
  const arrayEquality = (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    )
  }

  // check gironi changing checks
  useEffect(() => {
    if (admin && mode === 'update') {
      const oldValues = iscrizioni?.filter(i => i.id === idSquadra)[0]
      if (
        !!oldValues &&
        !!oldValues.girone &&
        !!oldValues.posizione &&
        ((!!categoria && categoria !== oldValues.categoria) ||
          (!!abbinamenti &&
            !!oldValues.abbinamenti &&
            !arrayEquality(abbinamenti, oldValues.abbinamenti)) ||
          (!!alternanze &&
            !!oldValues.alternanze &&
            !arrayEquality(alternanze, oldValues.alternanze)))
      ) {
        setGironiChangingCheck(true)
      } else {
        setGironiChangingCheck(false)
      }
    }
  }, [abbinamenti, admin, alternanze, categoria, idSquadra, iscrizioni, mode])

  // fetch campionati
  useEffect(() => {
    const fetchAvailableCampionati = async () => {
      try {
        const campData = await sendRequest('iscrizioni/campionati')

        if (!campData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i campionati.',
          })
        } else {
          setAvailableCampionati(
            [
              ...campData,
              impostazioni?.campionatoInvernale,
              impostazioni?.campionatoPrimaverile,
            ]
              ?.filter(x => x)
              ?.filter((v, i, s) => s.indexOf(v) === i)
              ?.sort((a, b) => sortCampionatiM(a, b))
          )
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (admin) fetchAvailableCampionati()
  }, [admin, dispatch, impostazioni, sortCampionatiM])

  // fetch società
  useEffect(() => {
    const fetchAvailableSocieta = async () => {
      try {
        const affData = await sendRequest('affiliazioni/all', 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!affData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le società.',
          })
        } else {
          setAvailableSocieta(
            affData?.sort((a, b) =>
              sortCodiceSocietaM(a.codiceSocieta, b.codiceSocieta)
            )
          )
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (admin && codiceUtente) fetchAvailableSocieta()
  }, [admin, codiceUtente, dispatch, sortCodiceSocietaM])

  // fetch iscrizioni
  useEffect(() => {
    const fetchIscrizioni = async () => {
      try {
        const iscrizioniData = await sendRequest(
          `iscrizioni/campionato/${
            admin || mode !== 'add' ? codiceCampionato : campionatoIscrizioni
          }/societa/${admin ? codiceSocieta : codiceUtente}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!iscrizioniData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le squadre iscritte.',
          })
        } else {
          dispatch({ type: SET_ISCRIZIONI, payload: iscrizioniData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (
      codiceUtente &&
      (admin ? codiceSocieta : true) &&
      (admin || mode !== 'add' ? codiceCampionato : campionatoIscrizioni)
    ) {
      fetchIscrizioni()
    } else {
      dispatch({ type: SET_ISCRIZIONI, payload: undefined })
    }
  }, [
    admin,
    campionatoIscrizioni,
    codiceCampionato,
    codiceSocieta,
    codiceUtente,
    dispatch,
    mode,
  ])

  // add function
  const addIscrizione = async () => {
    try {
      await sendRequest(
        `iscrizioni/add`,
        'POST',
        JSON.stringify({
          codiceCampionato: admin ? codiceCampionato : campionatoIscrizioni,
          codiceSocieta: admin ? codiceSocieta : codiceUtente,
          nomeSquadra,
          categoria,
          abbinamenti,
          alternanze,
          note,
          senzaPalestra,
          giorno,
          orario,
          citta,
          indirizzo,
          presso,
          nomeReferente,
          telefonoReferente,
          mailReferente,
          nomeReferente2,
          telefonoReferente2,
          mailReferente2,
          checkPrivacy,
          girone,
          posizione,
          status: !!completeInputs ? 'complete' : 'incomplete',
        }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      handleClickOpenSuccess()
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
      handleClickOpenFailure()
    }
  }

  // update function
  const updateIscrizione = async () => {
    try {
      await sendRequest(
        `iscrizioni/${idSquadra}`,
        'PUT',
        JSON.stringify({
          codiceCampionato,
          codiceSocieta: admin ? codiceSocieta : codiceUtente,
          nomeSquadra,
          categoria,
          abbinamenti,
          alternanze,
          note,
          senzaPalestra,
          giorno,
          orario,
          citta,
          indirizzo,
          presso,
          nomeReferente,
          telefonoReferente,
          mailReferente,
          nomeReferente2,
          telefonoReferente2,
          mailReferente2,
          checkPrivacy,
          girone,
          posizione,
          status: !!completeInputs ? 'complete' : 'incomplete',
          hasDeroga: admin ? hasDeroga : undefined,
          motivoDeroga: admin ? motivoDeroga : undefined,
        }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      handleClickOpenSuccess()
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
      handleClickOpenFailure()
    }
  }

  const confirmIscrizione = () => {
    handleCloseCheck()
    if (mode === 'add') addIscrizione()
    if (mode === 'update') updateIscrizione()
  }

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: RESET_ISCRIZIONE })
  }, [dispatch])

  return (
    <Grid container direction='column' paddingY={2}>
      <Grid item>
        <center>
          <h2>{`${mode === 'add' ? 'Nuova' : 'Modifica'} iscrizione`}</h2>
        </center>
      </Grid>
      {!admin && (
        <Grid item>
          <center>
            {(!iscrizioniAperte && (
              <h4 style={{ color: colours.red }}>
                Le iscrizioni a questo campionato sono chiuse.
                <br />
                <br />
                È comunque possibile modificare i contatti dei referenti e gli
                indirizzi delle palestre.
                <br />
                In caso di modifiche, si consiglia di avvisare le squadre
                avversarie.
                <br />
                <br />
                Per altre richieste scrivere a{' '}
                <a href='mailto:iscrizioni@pgsmilano.org'>
                  iscrizioni@pgsmilano.org
                </a>
                .
              </h4>
            )) || (
              <p>
                Per domande sulle iscrizioni ai campionati scrivere a{' '}
                <a href='mailto:iscrizioni@pgsmilano.org'>
                  iscrizioni@pgsmilano.org
                </a>
                .
              </p>
            )}
          </center>
        </Grid>
      )}
      &nbsp;
      <Grid container paddingX={3}>
        {admin && (
          <Grid container item xs={12} columnSpacing={5}>
            <Grid item xs={12} md={6}>
              <Select
                id='codice-campionato'
                value={
                  availableCampionati?.includes(codiceCampionato)
                    ? codiceCampionato
                    : null
                }
                options={availableCampionati}
                optionLabels={availableCampionati?.map(
                  a =>
                    a?.charAt(0).toUpperCase() +
                    a?.slice(1).replace('_', ' ').replace('-', '/')
                )}
                label='Campionato'
                disabled={!availableCampionati}
                onChange={i =>
                  dispatch({ type: SET_CODICE_CAMPIONATO, payload: i })
                }
                errorText='Selezionare un campionato!'
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AutoComplete
                id='codice-societa'
                value={
                  availableSocieta?.filter(
                    s => s.codiceSocieta === codiceSocieta
                  )?.[0]
                }
                options={availableSocieta}
                getOptionLabel={op => `${op.codiceSocieta} - ${op.nomeSocieta}`}
                label='Società'
                disabled={!availableSocieta}
                onChange={i =>
                  dispatch({
                    type: SET_CODICE_SOCIETA,
                    payload: i?.codiceSocieta,
                  })
                }
                errorText='Selezionare una società!'
              />
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12} columnSpacing={5}>
          <Grid item xs={12} md={6}>
            <InputField
              id='nome-squadra'
              value={nomeSquadra}
              onChange={i =>
                dispatch({
                  type: SET_NOME_SQUADRA,
                  payload:
                    i
                      .toUpperCase()
                      ?.slice(0, 30 + (i?.match(/\s/g)?.length || 0)) || '',
                })
              }
              autoFocus={!admin && iscrizioniAperte}
              label='Nome della squadra'
              errorText='Nome troppo corto!'
              errorFunc={errorNome}
              mode={mode}
              readOnly={!admin && !iscrizioniAperte}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              id='categoria'
              value={categoria}
              options={categorie?.map(c => c.categoria)}
              optionLabels={categorie?.map(c => c.nome)}
              label='Categoria'
              onChange={i => dispatch({ type: SET_CATEGORIA, payload: i })}
              errorText='Selezionare una categoria!'
              mode={mode}
              readOnly={!admin && !iscrizioniAperte}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h4>Gare casalinghe</h4>
        </Grid>
        <Grid item xs={12} md={12}>
          Palestra non disponibile &nbsp;
          <Checkbox
            id='senza-palestra'
            checked={senzaPalestra}
            disabled={!admin && !iscrizioniAperte}
            onChange={event => {
              if (!!event.target.checked) {
                dispatch({ type: SET_GIORNO, payload: null })
                dispatch({ type: SET_ORARIO, payload: null })
                dispatch({ type: SET_CITTA, payload: '' })
                dispatch({ type: SET_INDIRIZZO, payload: '' })
                dispatch({ type: SET_PRESSO, payload: '' })
                dispatch({ type: SET_ABBINAMENTI, payload: [] })
                dispatch({ type: SET_ALTERNANZE, payload: [] })
              }
              dispatch({
                type: SET_SENZA_PALESTRA,
                payload: event.target.checked,
              })
            }}
            color='default'
          />
        </Grid>
        &nbsp;
        {!senzaPalestra && (
          <Grid container item xs={12} columnSpacing={5}>
            <Grid item xs={12} md={6}>
              <Select
                id='giorno'
                value={giorno}
                options={GIORNI}
                label='Giorno di disputa'
                onChange={i => dispatch({ type: SET_GIORNO, payload: i })}
                errorText='Selezionare un giorno!'
                mode={mode}
                readOnly={!admin && !iscrizioniAperte}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                id='orario'
                value={orario}
                options={ORARI}
                label='Orario di disputa'
                onChange={i => dispatch({ type: SET_ORARIO, payload: i })}
                errorText='Selezionare un orario!'
                mode={mode}
                readOnly={!admin && !iscrizioniAperte}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                id='presso'
                value={presso}
                onChange={i =>
                  dispatch({ type: SET_PRESSO, payload: i.toUpperCase() || '' })
                }
                label='Istituto o centro sportivo'
                adornment='place'
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                id='indirizzo'
                value={indirizzo}
                onChange={i =>
                  dispatch({
                    type: SET_INDIRIZZO,
                    payload: i.toUpperCase() || '',
                  })
                }
                label='Indirizzo'
                errorText='Indirizzo troppo corto!'
                errorFunc={errorIndirizzo}
                adornment='zone'
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                id='città'
                value={citta}
                onChange={i =>
                  dispatch({ type: SET_CITTA, payload: i.toUpperCase() || '' })
                }
                label='Città'
                errorText='Città troppo corta!'
                errorFunc={errorCitta}
                adornment='city'
                mode={mode}
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <h4>Primo referente della squadra</h4>
        </Grid>
        <Grid container item xs={12} columnSpacing={5}>
          <Grid item xs={12} md={4}>
            <InputField
              id='nome-referente'
              value={nomeReferente}
              onChange={i =>
                dispatch({
                  type: SET_NOME_REFERENTE,
                  payload: i.toUpperCase() || '',
                })
              }
              label='Nome e Cognome'
              errorText='Nome troppo corto!'
              errorFunc={errorReferente}
              adornment='person'
              mode={mode}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              id='telefono-referente'
              value={telefonoReferente}
              onChange={i =>
                dispatch({
                  type: SET_TELEFONO_REFERENTE,
                  payload: i.replace(/\s/g, '') || '',
                })
              }
              label='Telefono'
              errorText='Telefono non valido!'
              errorFunc={errorTelefono}
              type='tel'
              adornment='phone'
              mode={mode}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              id='mail-referente'
              value={mailReferente}
              onChange={i =>
                dispatch({
                  type: SET_MAIL_REFERENTE,
                  payload: i.toLowerCase().replace(/\s/g, '') || '',
                })
              }
              label='E-mail'
              errorText='E-mail non valida!'
              errorFunc={errorMail}
              type='email'
              adornment='email'
              mode={mode}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h4>Secondo referente della squadra (facoltativo)</h4>
        </Grid>
        <Grid container item xs={12} columnSpacing={5}>
          <Grid item xs={12} md={4}>
            <InputField
              id='nome-referente-2'
              value={nomeReferente2}
              onChange={i =>
                dispatch({
                  type: SET_NOME_REFERENTE_2,
                  payload: i.toUpperCase() || '',
                })
              }
              label='Nome e Cognome'
              errorText='Nome troppo corto!'
              errorFunc={errorReferente2}
              adornment='person'
              mode={mode}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              id='telefono-referente-2'
              value={telefonoReferente2}
              onChange={i =>
                dispatch({
                  type: SET_TELEFONO_REFERENTE_2,
                  payload: i.replace(/\s/g, '') || '',
                })
              }
              label='Telefono'
              errorText='Telefono non valido!'
              errorFunc={errorTelefono2}
              type='tel'
              adornment='phone'
              mode={mode}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              id='mail-referente-2'
              value={mailReferente2}
              onChange={i =>
                dispatch({
                  type: SET_MAIL_REFERENTE_2,
                  payload: i.toLowerCase().replace(/\s/g, '') || '',
                })
              }
              label='E-mail'
              errorText='E-mail non valida!'
              errorFunc={errorMail2}
              type='email'
              adornment='email'
              mode={mode}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} alignItems='center' columnSpacing={5}>
          <Grid item xs={12}>
            <h4>
              Preferenze di calendario (facoltativo) &nbsp;
              <IconButton
                style={{ color: colours.grey }}
                onClick={handleClickOpenInfo}
              >
                <Tooltip key='abb-alt' title='Maggiori informazioni'>
                  <InfoIcon />
                </Tooltip>
              </IconButton>
            </h4>
          </Grid>
          <Grid item xs={12} md={6}>
            <AutoComplete
              id='abbinamenti'
              value={iscrizioni?.filter(s => abbinamenti?.includes(s.id))}
              blurOnSelect={false}
              multiple={true}
              options={iscrizioni?.filter(
                s =>
                  s.id !== idSquadra &&
                  !alternanze?.includes(s.id) &&
                  s.codiceSocieta === ((admin && codiceSocieta) || codiceUtente)
              )}
              getOptionLabel={op => `${op.categoria} ${op.nomeSquadra}`}
              noOptionsText='Nessuna squadra iscritta'
              helperText=''
              label='Abbinamenti'
              onChange={i =>
                dispatch({
                  type: SET_ABBINAMENTI,
                  payload: i.map(a => a.id) || [],
                })
              }
              mode={mode}
              readOnly={(!admin && !iscrizioniAperte) || senzaPalestra}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutoComplete
              id='alternanze'
              value={iscrizioni?.filter(s => alternanze?.includes(s.id))}
              blurOnSelect={false}
              multiple={true}
              options={iscrizioni?.filter(
                s =>
                  s.id !== idSquadra &&
                  !abbinamenti?.includes(s.id) &&
                  s.codiceSocieta === ((admin && codiceSocieta) || codiceUtente)
              )}
              getOptionLabel={op => `${op.categoria} ${op.nomeSquadra}`}
              noOptionsText='Nessuna squadra iscritta'
              helperText=''
              label='Alternanze'
              onChange={i =>
                dispatch({
                  type: SET_ALTERNANZE,
                  payload: i.map(a => a.id) || [],
                })
              }
              mode={mode}
              readOnly={(!admin && !iscrizioniAperte) || senzaPalestra}
            />
          </Grid>
          &nbsp;
          {senzaPalestra && (
            <>
              <Grid item xs={12}>
                <p
                  style={{ color: colours.red, marginTop: 5, marginBottom: 20 }}
                >
                  Le squadre senza palestra non possono inserire preferenze di
                  abbinamento o alternanza perché giocano sempre fuori casa.
                </p>
              </Grid>
              &nbsp;
            </>
          )}
          <Grid item xs={12}>
            <InputField
              id='note'
              value={note}
              onChange={i =>
                dispatch({ type: SET_NOTE, payload: i?.slice(0, 100) || '' })
              }
              multiline={true}
              label='Altre richieste particolari'
              errorText='Testo troppo lungo!'
              errorFunc={errorRichieste}
              mode={mode}
              readOnly={!admin && !iscrizioniAperte}
            />
          </Grid>
        </Grid>
        {(!admin || mode === 'add') && (
          <Grid container item xs={12} alignItems='center' columnSpacing={5}>
            <Grid item xs={12}>
              <h4>
                Legge sulla privacy e accettazione Statuto e Regolamenti della
                PGS
              </h4>
            </Grid>
            <Grid item xs={2} md={1}>
              <center>
                <Checkbox
                  id='check-privacy'
                  checked={!!checkPrivacy}
                  disabled={!admin && !iscrizioniAperte}
                  onChange={event =>
                    dispatch({
                      type: SET_CHECK_PRIVACY,
                      payload: event.target.checked,
                    })
                  }
                  color='default'
                />
              </center>
            </Grid>
            <Grid item xs={10} md={11}>
              <div style={{ color: colours.greyDark }}>
                Dichiaro di aver preso visione dell'
                <a
                  target='_blank'
                  rel='noreferrer noopener'
                  href='https://www.pgsmilano.org/pdf/privacy.pdf'
                >
                  informativa
                </a>{' '}
                sul trattamento dei dati personali e di prestare il consenso al
                loro trattamento per gli scopi indicati. La società dichiara
                inoltre di accettare lo Statuto e i Regolamenti della PGS e che
                i propri atleti tesserati sono in possesso dell'idonea
                certificazione medica per l'attività sportiva.
              </div>
            </Grid>
            &nbsp;
          </Grid>
        )}
        {admin && (
          <Grid container item xs={12} alignItems='left' columnSpacing={5}>
            <Grid item xs={12}>
              <h4>
                Deroga &nbsp;
                <IconButton
                  style={{ color: colours.grey }}
                  onClick={handleClickOpenDeroga}
                >
                  <Tooltip key='deroga' title='Maggiori informazioni'>
                    <InfoIcon />
                  </Tooltip>
                </IconButton>
              </h4>
            </Grid>
            <Grid item>
              Deroga concessa &nbsp;
              <Checkbox
                id='has-deroga'
                checked={hasDeroga}
                onChange={event => {
                  dispatch({
                    type: SET_HAS_DEROGA,
                    payload: event.target.checked,
                  })
                  if (event.target.checked)
                    dispatch({ type: SET_MOTIVO_DEROGA, payload: '' })
                }}
                color='default'
              />
            </Grid>
            {hasDeroga && (
              <Grid item xs={12} md={6}>
                <InputField
                  id='motivo-deroga'
                  value={motivoDeroga}
                  onChange={i =>
                    dispatch({ type: SET_MOTIVO_DEROGA, payload: i || '' })
                  }
                  label='Motivo della deroga'
                  helperText=''
                  mode={mode}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <center>
              {!validInputs ? (
                <h4 style={{ color: colours.red }}>{phrases.phraseInvalid}</h4>
              ) : !!completeInputs ? (
                <h4 style={{ color: colours.blueDark }}>
                  {phrases.phraseComplete}
                </h4>
              ) : (
                <h4 style={{ color: colours.red }}>
                  {phrases.phraseIncomplete}
                </h4>
              )}
            </center>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs>
            <center>
              <ColouredButton
                textcolour={colours.white}
                backgroundcolour={colours.red}
                hovercolour={colours.redDark}
                onClick={handleClickOpenUndo}
              >
                Indietro
              </ColouredButton>
            </center>
          </Grid>
          <Grid item xs>
            <center>
              <ColouredButton
                textcolour={colours.white}
                textbold='bold'
                backgroundcolour={colours.blueDark}
                hovercolour={colours.blueVeryDark}
                disabled={!validInputs}
                onClick={handleClickOpenCheck}
              >
                Salva
              </ColouredButton>
            </center>
          </Grid>
        </Grid>
      </Grid>
      <DialogMini
        open={openUndo}
        handleClose={handleCloseUndo}
        title={titles.titleUndo}
        textContent={phrases.phraseUndo}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={handleCloseUndo}
        triggerPath='/iscrizioni'
        colourBackground={colours.red}
        colourHover={colours.redDark}
      />
      <DialogMini
        open={openCheck}
        handleClose={handleCloseCheck}
        title={titles.titleCheck}
        textContent={
          !!gironiChangingCheck
            ? phrases.phraseGironiChange
            : !!completeInputs
            ? phrases.phraseComplete
            : phrases.phraseIncomplete
        }
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={confirmIscrizione}
      />
      <DialogMini
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title={
          (!!completeInputs &&
            (mode === 'add'
              ? titles.titleCompleteAdd
              : titles.titleCompleteUpdate)) ||
          titles.titleIncomplete
        }
        textContent={
          (!!completeInputs &&
            (mode === 'add'
              ? phrases.phraseCompleteSuccessAdd
              : phrases.phraseCompleteSuccessUpdate)) ||
          phrases.phraseIncompleteSuccess
        }
        textConfirm='Ok'
        triggerFunction={handleCloseSuccess}
        triggerPath='/iscrizioni'
      />
      <DialogMini
        open={openFailure}
        handleClose={handleCloseFailure}
        title={titles.titleFailure}
        textContent={phrases.phraseFailure}
        textConfirm='Ok'
        triggerFunction={handleCloseFailure}
      />
      <DialogMini
        open={openInfo}
        handleClose={handleCloseInfo}
        title={titles.titleInfo}
        textContent={phrases.phraseInfo}
        textConfirm='Ok'
        triggerFunction={handleCloseInfo}
      />
      <DialogMini
        open={openDeroga}
        handleClose={handleCloseDeroga}
        title={titles.titleDeroga}
        textContent={phrases.phraseDeroga}
        textConfirm='Ok'
        triggerFunction={handleCloseDeroga}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  impostazioni: state.home.impostazioni,
  categorie: state.home.categorie,
  selectedCampionato: state.home.selectedCampionato,
  selectedCampionatoIscrizioniAperte:
    state.home.selectedCampionatoIscrizioniAperte,
  iscrizioni: state.iscrizioni.iscrizioni,
  mode: state.iscrizione.mode,
  idSquadra: state.iscrizione.idSquadra,
  codiceCampionato: state.iscrizione.codiceCampionato,
  codiceSocieta: state.iscrizione.codiceSocieta,
  nomeSquadra: state.iscrizione.nomeSquadra,
  categoria: state.iscrizione.categoria,
  senzaPalestra: state.iscrizione.senzaPalestra,
  giorno: state.iscrizione.giorno,
  orario: state.iscrizione.orario,
  presso: state.iscrizione.presso,
  indirizzo: state.iscrizione.indirizzo,
  citta: state.iscrizione.citta,
  nomeReferente: state.iscrizione.nomeReferente,
  telefonoReferente: state.iscrizione.telefonoReferente,
  mailReferente: state.iscrizione.mailReferente,
  nomeReferente2: state.iscrizione.nomeReferente2,
  telefonoReferente2: state.iscrizione.telefonoReferente2,
  mailReferente2: state.iscrizione.mailReferente2,
  abbinamenti: state.iscrizione.abbinamenti,
  alternanze: state.iscrizione.alternanze,
  note: state.iscrizione.note,
  checkPrivacy: state.iscrizione.checkPrivacy,
  girone: state.iscrizione.girone,
  posizione: state.iscrizione.posizione,
  hasDeroga: state.iscrizione.hasDeroga,
  motivoDeroga: state.iscrizione.motivoDeroga,
})

const ConnectedIscrizione = connect(mapStateToProps)(Iscrizione)

export default ConnectedIscrizione
