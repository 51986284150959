import {
  RESET_AFFILIAZIONE,
  SET_AFFILIAZIONE,
  SET_MODE,
  SET_ID_SOCIETA,
  SET_CODICE_SOCIETA,
  SET_DENOMINAZIONE_SOCIETA,
  SET_NOME_SOCIETA,
  SET_PASSWORD,
  SET_REFERENTE,
  SET_RUOLO,
  SET_TELEFONO,
  SET_MAIL,
  SET_ZONA,
  SET_IS_ACTIVE,
} from './types'

const INIT_STATE = {
  mode: 'add',
  idSocieta: undefined,
  codiceSocieta: '',
  denominazioneSocieta: '',
  nomeSocieta: '',
  password: '',
  referente: '',
  ruolo: '',
  telefono: '',
  mail: '',
  zona: '',
  isActive: true,
}

export const affiliazioneReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_AFFILIAZIONE:
      return INIT_STATE
    case SET_AFFILIAZIONE:
      return { ...state, ...action.payload, idSocieta: action.payload._id }
    case SET_MODE:
      return { ...state, mode: action.payload }
    case SET_ID_SOCIETA:
      return { ...state, idSocieta: action.payload }
    case SET_CODICE_SOCIETA:
      return { ...state, codiceSocieta: action.payload }
    case SET_DENOMINAZIONE_SOCIETA:
      return { ...state, denominazioneSocieta: action.payload }
    case SET_NOME_SOCIETA:
      return { ...state, nomeSocieta: action.payload }
    case SET_PASSWORD:
      return { ...state, password: action.payload }
    case SET_REFERENTE:
      return { ...state, referente: action.payload }
    case SET_RUOLO:
      return { ...state, ruolo: action.payload }
    case SET_TELEFONO:
      return { ...state, telefono: action.payload }
    case SET_MAIL:
      return { ...state, mail: action.payload }
    case SET_ZONA:
      return { ...state, zona: action.payload }
    case SET_IS_ACTIVE:
      return { ...state, isActive: action.payload }
    default:
      return state
  }
}
