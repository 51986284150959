import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { validateIBAN } from 'ibantools'
import DatePicker, { DateObject } from 'react-multi-date-picker'
import 'react-multi-date-picker/styles/layouts/mobile.css'
import { Checkbox, Grid, IconButton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import InfoIcon from '@mui/icons-material/Info'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import DateRangeIcon from '@mui/icons-material/DateRange'

import Select from '../components/Select'
import InputField from '../components/InputField'
import DialogMini from '../components/DialogMini'
import AutoComplete from '../components/AutoComplete'
import { ColouredButton } from '../components/Buttons'
import {
  colours,
  GIORNI,
  ORARI,
  WEEK_DAYS,
  MONTHS,
  titlesArbitro as titles,
  phrasesArbitro as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { errorFunctions } from '../hooks/error-functions'
import { sortFunctions } from '../hooks/sort-functions'
import { utilsFunctions } from '../hooks/utils-functions'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import {
  RESET_ARBITRO,
  SET_ARBITRO,
  SET_MODE,
  SET_ID_ARBITRO,
  SET_NOME,
  SET_COGNOME,
  SET_CODICE_FISCALE,
  SET_PASSWORD,
  SET_DATA_NASCITA,
  SET_LUOGO_NASCITA,
  SET_INDIRIZZO_RESIDENZA,
  SET_CITTA_RESIDENZA,
  SET_CAP_RESIDENZA,
  SET_INDIRIZZO_DOMICILIO,
  SET_CITTA_DOMICILIO,
  SET_CAP_DOMICILIO,
  SET_TELEFONO,
  SET_MAIL,
  SET_CODICE_IBAN,
  SET_NOME_BANCA,
  SET_COSTO_KM,
  SET_CODICI_SOCIETA_INCOMPATIBILI,
  SET_DISPONIBILITA_SETTIMANALI,
  SET_INDISPONIBILITA_PUNTUALI,
  SET_NOTE,
  SET_IS_ACTIVE,
} from '../container/arbitro/types'

const Arbitro = ({
  admin,
  arbitro,
  designatore,
  codiceUtente,
  infoUtente,
  affiliazioniLight,
  dummyUpdate,
  isMobile,
  mode,
  idArbitro,
  nome,
  cognome,
  codiceFiscale,
  password,
  dataNascita,
  luogoNascita,
  indirizzoResidenza,
  cittaResidenza,
  capResidenza,
  indirizzoDomicilio,
  cittaDomicilio,
  capDomicilio,
  telefono,
  mail,
  codiceIBAN,
  nomeBanca,
  costoKm,
  codiciSocietaIncompatibili,
  disponibilitaSettimanali,
  indisponibilitaPuntuali,
  note,
  isActive,
}) => {
  const dispatch = useDispatch()

  if (arbitro) dispatch({ type: SET_MODE, payload: 'update' })

  useEffect(() => {
    document.title = `PGS Milano - ${
      mode === 'add' ? 'Nuova' : 'Modifica'
    } arbitro`
  }, [mode])

  const [domicilioResidenza, setDomicilioResidenza] = useState(true)

  // parameters
  const [completeInputs, setCompleteInputs] = useState(false)
  const [validInputs, setValidInputs] = useState(false)

  // parameters for disponibilità
  const [addingDisponibilita, setAddingDisponibilita] = useState(false)
  const [newGiorno, setNewGiorno] = useState()
  const [newDalle, setNewDalle] = useState()
  const [newAlle, setNewAlle] = useState()

  // set current season range
  const cM = new Date().getMonth()
  const cY = new Date().getFullYear()
  const minDate = new Date(cM > 6 ? cY : cY - 1, 7, 1)
  const maxDate = new Date(cM > 6 ? cY + 1 : cY, 6, 31)

  // filter indisponibilitaPuntuali inside/outside current season range
  const fIndPun = interval =>
    indisponibilitaPuntuali
      ?.map(i => fromStringToDate(i))
      ?.filter(i =>
        interval === 'in'
          ? i >= minDate && i <= maxDate
          : interval === 'out'
          ? i < minDate || i > maxDate
          : true
      ) || []

  // sort functions
  const { sortAlphabetically } = sortFunctions()

  // utils functions
  const { fromDateToString, fromStringToDate } = utilsFunctions()

  const setIndisponibilitaPuntuali = i =>
    dispatch({
      type: SET_INDISPONIBILITA_PUNTUALI,
      payload:
        [...fIndPun('out'), ...(i || [])]
          ?.sort((a, b) => a - b)
          ?.map(x => fromDateToString(new Date(x))) || [],
    })

  // error functions
  const {
    errorMinLength,
    errorNumber0,
    errorTelefono,
    errorMail,
    errorCodiceFiscale,
    errorDataRange,
    errorCAP,
  } = errorFunctions()

  const errorNome = useCallback(x => errorMinLength(x, 3), [errorMinLength])
  const errorPassword = useCallback(x => errorMinLength(x, 8), [errorMinLength])
  const errorIndirizzo = useCallback(
    x => errorMinLength(x, 6),
    [errorMinLength]
  )
  const errorCitta = useCallback(x => errorMinLength(x, 3), [errorMinLength])
  const errorCodiceIBAN = useCallback(x => !validateIBAN(x)?.valid, [])
  const errorNomeBanca = useCallback(
    x => errorMinLength(x, 3),
    [errorMinLength]
  )

  const errorNewDisponibilita = useCallback(() => {
    if (!newDalle || !newAlle) return false

    if (newDalle?.split(':')?.length !== 2 || newAlle?.split(':')?.length !== 2)
      return true

    const [hD, mD] = newDalle.split(':')
    const [hA, mA] = newAlle.split(':')

    return hD > hA || (hD === hA && mD >= mA)
  }, [newAlle, newDalle])

  const [openUndo, setOpenUndo] = useState(false)
  const [openCheck, setOpenCheck] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openFailure, setOpenFailure] = useState(false)
  const [openDisponibilita, setOpenDisponibilita] = useState(false)
  const [openSocieta, setOpenSocieta] = useState(false)
  const [openIndisp, setOpenIndisp] = useState(false)
  const [openIndispTyping, setOpenIndispTyping] = useState(false)
  const [openActive, setOpenActive] = useState(false)

  const handleClickOpenUndo = () => {
    setOpenUndo(true)
  }

  const handleClickOpenCheck = () => {
    setOpenCheck(true)
  }

  const handleClickOpenSuccess = () => {
    setOpenSuccess(true)
  }

  const handleClickOpenFailure = () => {
    setOpenFailure(true)
  }

  const handleClickOpenDisponibilita = () => {
    setOpenDisponibilita(true)
  }

  const handleClickOpenSocieta = () => {
    setOpenSocieta(true)
  }

  const handleClickOpenIndisp = () => {
    setOpenIndisp(true)
  }

  const handleClickOpenIndispTyping = () => {
    setOpenIndispTyping(true)
  }

  const handleClickOpenActive = () => {
    setOpenActive(true)
  }

  const handleCloseUndo = () => {
    setOpenUndo(false)
  }

  const handleCloseCheck = () => {
    setOpenCheck(false)
  }

  const handleCloseSuccess = () => {
    setOpenSuccess(false)
  }

  const handleCloseFailure = () => {
    setOpenFailure(false)
  }

  const handleCloseDisponibilita = () => {
    setOpenDisponibilita(false)
  }

  const handleCloseSocieta = () => {
    setOpenSocieta(false)
  }

  const handleCloseIndisp = () => {
    setOpenIndisp(false)
  }

  const handleCloseIndispTyping = () => {
    setOpenIndispTyping(false)
  }

  const handleCloseActive = () => {
    setOpenActive(false)
  }

  // update domicilioResidenza
  useEffect(() => {
    if (
      domicilioResidenza &&
      (indirizzoResidenza !== indirizzoDomicilio ||
        cittaResidenza !== cittaDomicilio ||
        capResidenza !== capDomicilio)
    )
      setDomicilioResidenza(false)
  }, [
    capDomicilio,
    capResidenza,
    cittaDomicilio,
    cittaResidenza,
    domicilioResidenza,
    indirizzoDomicilio,
    indirizzoResidenza,
  ])

  // check inputs completeness
  useEffect(() => {
    if (
      !errorNome(nome) &&
      !errorNome(cognome) &&
      !errorCodiceFiscale(codiceFiscale) &&
      !errorPassword(password) &&
      !errorDataRange(dataNascita) &&
      !errorCitta(luogoNascita) &&
      !errorIndirizzo(indirizzoResidenza) &&
      !errorCitta(cittaResidenza) &&
      !errorCAP(capResidenza) &&
      !errorIndirizzo(indirizzoDomicilio) &&
      !errorCitta(cittaDomicilio) &&
      !errorCAP(capDomicilio) &&
      !errorTelefono(telefono) &&
      !errorMail(mail) &&
      !errorCodiceIBAN(codiceIBAN) &&
      !errorNomeBanca(nomeBanca)
    ) {
      setCompleteInputs(true)
    } else {
      setCompleteInputs(false)
    }
  }, [
    capDomicilio,
    capResidenza,
    cittaDomicilio,
    cittaResidenza,
    codiceFiscale,
    codiceIBAN,
    cognome,
    dataNascita,
    errorCAP,
    errorCitta,
    errorCodiceFiscale,
    errorCodiceIBAN,
    errorDataRange,
    errorIndirizzo,
    errorMail,
    errorNome,
    errorNomeBanca,
    errorPassword,
    errorTelefono,
    indirizzoDomicilio,
    indirizzoResidenza,
    luogoNascita,
    mail,
    nome,
    nomeBanca,
    password,
    telefono,
  ])

  // check inputs validity
  useEffect(() => {
    if (
      !errorNome(nome) &&
      !errorNome(cognome) &&
      !errorCodiceFiscale(codiceFiscale) &&
      !errorPassword(password)
    ) {
      setValidInputs(true)
    } else {
      setValidInputs(false)
    }
  }, [
    codiceFiscale,
    cognome,
    errorCodiceFiscale,
    errorNome,
    errorPassword,
    nome,
    password,
  ])

  // fetch arbitro
  useEffect(() => {
    const fetchArbitro = async () => {
      try {
        const arbitroData = await sendRequest(
          `arbitri/${infoUtente?.id}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!arbitroData) {
          dispatch({
            type: SET_ERROR,
            payload: "Impossibile trovare l'arbitro.",
          })
        } else {
          dispatch({ type: SET_ARBITRO, payload: arbitroData.data })
          dispatch({ type: SET_ID_ARBITRO, payload: arbitroData.data.id })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (arbitro && codiceUtente && infoUtente?.id) fetchArbitro()
  }, [arbitro, codiceUtente, dispatch, infoUtente])

  const addArbitro = async () => {
    try {
      await sendRequest(
        `arbitri/add`,
        'POST',
        JSON.stringify({
          nome,
          cognome,
          codiceFiscale,
          password,
          dataNascita,
          luogoNascita,
          indirizzoResidenza,
          cittaResidenza,
          capResidenza,
          indirizzoDomicilio,
          cittaDomicilio,
          capDomicilio,
          telefono,
          mail,
          codiceIBAN,
          nomeBanca,
          costoKm: admin || designatore ? costoKm : undefined,
          codiciSocietaIncompatibili:
            admin || designatore ? codiciSocietaIncompatibili : undefined,
          disponibilitaSettimanali,
          indisponibilitaPuntuali,
          note: admin || designatore ? note : undefined,
          isActive: admin || designatore ? isActive : undefined,
          status: (completeInputs && 'complete') || 'incomplete',
        }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      handleClickOpenSuccess()
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
      handleClickOpenFailure()
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  const updateArbitro = async () => {
    try {
      await sendRequest(
        `arbitri/${idArbitro}`,
        'PUT',
        JSON.stringify({
          nome,
          cognome,
          codiceFiscale,
          password,
          dataNascita,
          luogoNascita,
          indirizzoResidenza,
          cittaResidenza,
          capResidenza,
          indirizzoDomicilio,
          cittaDomicilio,
          capDomicilio,
          telefono,
          mail,
          codiceIBAN,
          nomeBanca,
          costoKm: admin || designatore ? costoKm : undefined,
          codiciSocietaIncompatibili:
            admin || designatore ? codiciSocietaIncompatibili : undefined,
          disponibilitaSettimanali,
          indisponibilitaPuntuali,
          note: admin || designatore ? note : undefined,
          isActive: admin || designatore ? isActive : undefined,
          status: (completeInputs && 'complete') || 'incomplete',
        }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      handleClickOpenSuccess()
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
      handleClickOpenFailure()
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // clear disponibilità
  const clearNewDisponibilita = () => {
    setAddingDisponibilita(false)
    setNewGiorno()
    setNewDalle()
    setNewAlle()
  }

  const confirmArbitro = () => {
    handleCloseCheck()
    if (mode === 'add') addArbitro()
    if (mode === 'update') updateArbitro()
  }

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: RESET_ARBITRO })
  }, [dispatch])

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <center>
          <h2>{`${mode === 'add' ? 'Nuovo' : 'Modifica'} arbitro`}</h2>
        </center>
      </Grid>
      &nbsp;
      <Grid container item xs={12} paddingX={3} columnSpacing={5}>
        <Grid item xs={12} md={6}>
          <InputField
            id='nome'
            value={nome}
            onChange={i =>
              dispatch({ type: SET_NOME, payload: i.toUpperCase() || '' })
            }
            autoFocus={true}
            fullWidth
            label='Nome'
            errorText='Nome troppo corto!'
            errorFunc={errorNome}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='cognome'
            value={cognome}
            onChange={i =>
              dispatch({ type: SET_COGNOME, payload: i.toUpperCase() || '' })
            }
            fullWidth
            label='Cognome'
            errorText='Cognome troppo corto!'
            errorFunc={errorNome}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='codice-fiscale'
            value={codiceFiscale}
            onChange={i =>
              dispatch({
                type: SET_CODICE_FISCALE,
                payload: i.toUpperCase().replace(/\W/g, '') || '',
              })
            }
            fullWidth
            label='Codice fiscale'
            errorText='Codice fiscale non valido!'
            errorFunc={errorCodiceFiscale}
            mode={mode}
            readOnly={!admin}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='password'
            value={password}
            onChange={i => dispatch({ type: SET_PASSWORD, payload: i })}
            fullWidth
            label='Password'
            errorText='Password troppo corta!'
            errorFunc={errorPassword}
            mode={mode}
            readOnly={!admin}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='data-nascita'
            value={dataNascita}
            onChange={i => dispatch({ type: SET_DATA_NASCITA, payload: i })}
            fullWidth
            label='Data di nascita'
            errorText='Data non valida! Il formato deve essere GG/MM/AAAA.'
            errorFunc={errorDataRange}
            adornment='date'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='luogo-nascita'
            value={luogoNascita}
            onChange={i =>
              dispatch({ type: SET_LUOGO_NASCITA, payload: i.toUpperCase() })
            }
            fullWidth
            label='Luogo di nascita'
            errorText='Città non valida!'
            errorFunc={errorCitta}
            adornment='city'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12}>
          <h4>Residenza</h4>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='indirizzo-residenza'
            value={indirizzoResidenza}
            onChange={i => {
              dispatch({
                type: SET_INDIRIZZO_RESIDENZA,
                payload: i.toUpperCase() || '',
              })
              if (domicilioResidenza)
                dispatch({
                  type: SET_INDIRIZZO_DOMICILIO,
                  payload: i.toUpperCase() || '',
                })
            }}
            fullWidth
            label='Indirizzo'
            errorText='Indirizzo non valido!'
            errorFunc={errorIndirizzo}
            adornment='zone'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputField
            id='citta-residenza'
            value={cittaResidenza}
            onChange={i => {
              dispatch({
                type: SET_CITTA_RESIDENZA,
                payload: i.toUpperCase() || '',
              })
              if (domicilioResidenza)
                dispatch({
                  type: SET_CITTA_DOMICILIO,
                  payload: i.toUpperCase() || '',
                })
            }}
            fullWidth
            label='Città'
            errorText='Città non valida!'
            errorFunc={errorCitta}
            adornment='city'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputField
            id='cap-residenza'
            value={capResidenza}
            onChange={i => {
              dispatch({ type: SET_CAP_RESIDENZA, payload: i })
              if (domicilioResidenza)
                dispatch({ type: SET_CAP_DOMICILIO, payload: i })
            }}
            fullWidth
            label='CAP'
            errorText='CAP non valido!'
            errorFunc={errorCAP}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          Domicilio uguale a residenza &nbsp;
          <Checkbox
            id='domicilio-residenza'
            checked={domicilioResidenza}
            onChange={event => {
              if (event.target.checked) {
                dispatch({
                  type: SET_INDIRIZZO_DOMICILIO,
                  payload: indirizzoResidenza,
                })
                dispatch({
                  type: SET_CITTA_DOMICILIO,
                  payload: cittaResidenza,
                })
                dispatch({ type: SET_CAP_DOMICILIO, payload: capResidenza })
              } else {
                dispatch({ type: SET_INDIRIZZO_DOMICILIO, payload: '' })
                dispatch({ type: SET_CITTA_DOMICILIO, payload: '' })
                dispatch({ type: SET_CAP_DOMICILIO, payload: '' })
              }
              setDomicilioResidenza(event.target.checked)
            }}
            color='default'
          />
        </Grid>
        {!domicilioResidenza && (
          <>
            <Grid item xs={12}>
              <h4>Domicilio</h4>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                id='indirizzo-domicilio'
                value={indirizzoDomicilio}
                onChange={i =>
                  dispatch({
                    type: SET_INDIRIZZO_DOMICILIO,
                    payload: i.toUpperCase() || '',
                  })
                }
                fullWidth
                label='Indirizzo'
                errorText='Indirizzo non valido!'
                errorFunc={errorIndirizzo}
                adornment='zone'
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                id='citta-domicilio'
                value={cittaDomicilio}
                onChange={i =>
                  dispatch({
                    type: SET_CITTA_DOMICILIO,
                    payload: i.toUpperCase() || '',
                  })
                }
                fullWidth
                label='Città'
                errorText='Città non valida!'
                errorFunc={errorCitta}
                adornment='city'
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputField
                id='cap-domicilio'
                value={capDomicilio}
                onChange={i =>
                  dispatch({ type: SET_CAP_DOMICILIO, payload: i })
                }
                fullWidth
                label='CAP'
                errorText='CAP non valido!'
                errorFunc={errorCAP}
                mode={mode}
              />
            </Grid>
          </>
        )}
        &nbsp;
        <Grid item xs={12}>
          <h4>Contatti</h4>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='telefono'
            value={telefono}
            onChange={i =>
              dispatch({
                type: SET_TELEFONO,
                payload: i.replace(/\s/g, '') || '',
              })
            }
            fullWidth
            label='Telefono'
            errorText='Telefono non valido!'
            errorFunc={errorTelefono}
            type='tel'
            adornment='phone'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='mail'
            value={mail}
            onChange={i =>
              dispatch({
                type: SET_MAIL,
                payload: i.toLowerCase().replace(/\s/g, '') || '',
              })
            }
            fullWidth
            label='E-mail'
            errorText='E-mail non valida!'
            errorFunc={errorMail}
            type='email'
            adornment='email'
            mode={mode}
          />
        </Grid>
        &nbsp;
        <Grid item xs={12}>
          <h4>Coordinate bancarie</h4>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='codiceIBAN'
            value={codiceIBAN}
            onChange={i =>
              dispatch({
                type: SET_CODICE_IBAN,
                payload: i.toUpperCase().replace(/\W/g, '') || '',
              })
            }
            fullWidth
            label='Codice IBAN'
            errorText='Codice IBAN non valido!'
            errorFunc={errorCodiceIBAN}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={admin || designatore ? 4 : 6}>
          <InputField
            id='nome-banca'
            value={nomeBanca}
            onChange={i =>
              dispatch({ type: SET_NOME_BANCA, payload: i.toUpperCase() || '' })
            }
            fullWidth
            label='Nome banca'
            errorText='Nome banca troppo corto!'
            errorFunc={errorNomeBanca}
            mode={mode}
          />
        </Grid>
        {(admin || designatore) && (
          <Grid item xs={12} md={2}>
            <InputField
              id='costo-km'
              value={costoKm}
              onChange={i => dispatch({ type: SET_COSTO_KM, payload: i })}
              fullWidth
              label='Costo €/km'
              errorText='Costo non valido!'
              errorFunc={errorNumber0}
              type='number'
              mode={mode}
            />
          </Grid>
        )}
        &nbsp;
        <Grid item xs={12}>
          <h4>
            Disponibilità settimanali &nbsp;
            <IconButton
              style={{ color: colours.grey }}
              onClick={handleClickOpenDisponibilita}
            >
              <Tooltip key='disponibilita' title='Maggiori informazioni'>
                <InfoIcon />
              </Tooltip>
            </IconButton>
          </h4>
        </Grid>
        <Grid item xs={12}>
          {(!disponibilitaSettimanali?.length && (
            <p>Nessuna disponibilità inserita.</p>
          )) ||
            disponibilitaSettimanali.map((x, index) => {
              return (
                <div key={index}>
                  {x.giorno} dalle {x.dalle} alle {x.alle}&nbsp;
                  <IconButton
                    style={{ color: colours.red }}
                    onClick={() =>
                      dispatch({
                        type: SET_DISPONIBILITA_SETTIMANALI,
                        payload: [
                          ...disponibilitaSettimanali.slice(0, index),
                          ...disponibilitaSettimanali.slice(index + 1),
                        ],
                      })
                    }
                  >
                    <Tooltip key='Elimina' title='Elimina'>
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>
                </div>
              )
            })}
        </Grid>
        &nbsp;
        {(addingDisponibilita && (
          <Grid container item xs={12} spacing={3} alignItems='center'>
            <Grid item xs={12} md>
              <Select
                id='giorno'
                label='Giorno'
                value={newGiorno}
                options={GIORNI}
                onChange={setNewGiorno}
              />
            </Grid>
            <Grid item xs={12} md>
              <Select
                id='dalle'
                label='Dalle (inizio gara)'
                value={newDalle}
                options={ORARI}
                onChange={setNewDalle}
                forceError={errorNewDisponibilita()}
              />
            </Grid>
            <Grid item xs={12} md>
              <Select
                id='alle'
                label='Alle (inizio gara)'
                value={newAlle}
                options={ORARI}
                onChange={setNewAlle}
                forceError={errorNewDisponibilita()}
              />
            </Grid>
            <Grid item xs={6} md={1}>
              <center>
                <IconButton
                  style={{ color: colours.blueDark }}
                  onClick={() => {
                    dispatch({
                      type: SET_DISPONIBILITA_SETTIMANALI,
                      payload: disponibilitaSettimanali
                        .concat({
                          giorno: newGiorno,
                          dalle: newDalle,
                          alle: newAlle,
                        })
                        .sort(
                          (a, b) =>
                            GIORNI.findIndex(d => d === a.giorno) -
                              GIORNI.findIndex(d => d === b.giorno) ||
                            sortAlphabetically(a.dalle, b.dalle)
                        ),
                    })
                    clearNewDisponibilita()
                  }}
                  disabled={
                    !newGiorno ||
                    !newDalle ||
                    !newAlle ||
                    errorNewDisponibilita()
                  }
                >
                  <Tooltip key='Salva' title='Salva'>
                    <SaveIcon />
                  </Tooltip>
                </IconButton>
              </center>
            </Grid>
            <Grid item xs={6} md={1}>
              <center>
                <IconButton
                  style={{ color: colours.red }}
                  onClick={clearNewDisponibilita}
                >
                  <Tooltip key='Annulla' title='Annulla'>
                    <ClearIcon />
                  </Tooltip>
                </IconButton>
              </center>
            </Grid>
          </Grid>
        )) || (
          <Grid item xs={12}>
            <IconButton
              size='small'
              style={{ color: colours.black }}
              onClick={() => {
                setAddingDisponibilita(true)
                setNewGiorno()
                setNewDalle()
                setNewAlle()
              }}
            >
              <AddIcon />
              &nbsp; Nuova disponibilità
            </IconButton>
          </Grid>
        )}
        &nbsp;
        <Grid item xs={12}>
          <h4>
            Indisponibilità puntuali &nbsp;
            <IconButton
              style={{ color: colours.grey }}
              onClick={handleClickOpenIndisp}
            >
              <Tooltip key='info' title='Maggiori informazioni'>
                <InfoIcon />
              </Tooltip>
            </IconButton>
          </h4>
        </Grid>
        <Grid container item xs={12} alignItems='center'>
          <Grid item xs={10} md={11}>
            <AutoComplete
              id='indisponibilita-puntuali'
              label='Indisponibilità puntuali'
              value={fIndPun('in')}
              options={[]}
              onChange={setIndisponibilitaPuntuali}
              onInputChange={(_, __, reason) => {
                if (reason === 'input') handleClickOpenIndispTyping()
              }}
              getOptionLabel={op => fromDateToString(op)}
              helperText=''
              multiple={true}
              openable={false}
              optional={true}
              showPopupIcon={false}
              mode={mode}
            />
          </Grid>
          <Grid item xs={2} md={1} sx={{ cursor: 'pointer' }}>
            <center>
              <DatePicker
                id='date-picker'
                value={fIndPun('in')}
                onChange={setIndisponibilitaPuntuali}
                disabled={false}
                disableYearPicker={true}
                disableMonthPicker={true}
                fullYear={false}
                hideOnScroll={true}
                highlightToday={false}
                multiple={true}
                readOnly={false}
                sort={true}
                calendarPosition='left'
                format='DD/MM/YYYY'
                numberOfMonths={1}
                weekStartDayIndex={1}
                months={MONTHS}
                weekDays={WEEK_DAYS}
                minDate={minDate}
                maxDate={maxDate}
                currentDate={new DateObject()}
                className={isMobile && 'rmdp-mobile'}
                mobileLabels={{ OK: 'OK', CANCEL: 'ANNULLA' }}
                render={(_, openCalendar) => (
                  <DateRangeIcon onClick={openCalendar} />
                )}
              />
            </center>
          </Grid>
        </Grid>
        &nbsp;
        {(admin || designatore) && (
          <>
            <Grid item xs={12}>
              <h4>
                Società incompatibili &nbsp;
                <IconButton
                  style={{ color: colours.grey }}
                  onClick={handleClickOpenSocieta}
                >
                  <Tooltip key='info' title='Maggiori informazioni'>
                    <InfoIcon />
                  </Tooltip>
                </IconButton>
              </h4>
            </Grid>
            <Grid item xs={12}>
              <AutoComplete
                id='codici-societa-incompatibili'
                value={affiliazioniLight?.filter(a =>
                  codiciSocietaIncompatibili?.includes(a.id)
                )}
                multiple={true}
                optional={true}
                options={affiliazioniLight}
                getOptionLabel={op => `${op.codiceSocieta} - ${op.nomeSocieta}`}
                noOptionsText='Nessuna società trovata'
                helperText=''
                label='Società incompatibili'
                onChange={i =>
                  dispatch({
                    type: SET_CODICI_SOCIETA_INCOMPATIBILI,
                    payload: i.map(a => a.id) || [],
                  })
                }
                mode={mode}
              />
            </Grid>
          </>
        )}
        &nbsp;
        {(admin || designatore) && (
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <h4>
                Arbitro in attività &nbsp;
                <IconButton
                  style={{ color: colours.grey }}
                  onClick={handleClickOpenActive}
                >
                  <Tooltip key='active' title='Maggiori informazioni'>
                    <InfoIcon />
                  </Tooltip>
                </IconButton>
              </h4>
            </Grid>
            <Grid item xs={12}>
              Arbitro attivo &nbsp;
              <Checkbox
                id='is-active'
                checked={isActive}
                onChange={event =>
                  dispatch({
                    type: SET_IS_ACTIVE,
                    payload: event.target.checked,
                  })
                }
                color='default'
              />
            </Grid>
          </Grid>
        )}
        &nbsp;
        {(admin || designatore) && (
          <Grid item xs={12}>
            <InputField
              id='note'
              value={note}
              onChange={i => dispatch({ type: SET_NOTE, payload: i })}
              fullWidth
              label='Note del designatore'
              mode={mode}
            />
          </Grid>
        )}
      </Grid>
      &nbsp;
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <center>
            {!validInputs ? (
              <h4 style={{ color: colours.red }}>{phrases.phraseInvalid}</h4>
            ) : !!completeInputs ? (
              <h4 style={{ color: colours.blueDark }}>
                {mode === 'add'
                  ? phrases.phraseCompleteAdd
                  : phrases.phraseCompleteUpdate}
              </h4>
            ) : (
              <h4 style={{ color: colours.red }}>
                {mode === 'add'
                  ? phrases.phraseIncompleteAdd
                  : phrases.phraseIncompleteUpdate}
              </h4>
            )}
          </center>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs>
          <center>
            <ColouredButton
              textcolour={colours.white}
              backgroundcolour={colours.red}
              hovercolour={colours.redDark}
              onClick={handleClickOpenUndo}
            >
              Indietro
            </ColouredButton>
          </center>
        </Grid>
        <Grid item xs>
          <center>
            <ColouredButton
              textcolour={colours.white}
              textbold='bold'
              backgroundcolour={colours.blueDark}
              hovercolour={colours.blueVeryDark}
              disabled={!validInputs}
              onClick={handleClickOpenCheck}
            >
              Salva
            </ColouredButton>
          </center>
        </Grid>
      </Grid>
      <DialogMini
        open={openUndo}
        handleClose={handleCloseUndo}
        title={titles.titleUndo}
        textContent={phrases.phraseUndo}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={handleCloseUndo}
        triggerPath='/arbitri'
        colourBackground={colours.red}
        colourHover={colours.redDark}
      />
      <DialogMini
        open={openCheck}
        handleClose={handleCloseCheck}
        title={titles.titleCheck}
        textContent={
          (!!completeInputs &&
            (mode === 'add'
              ? phrases.phraseCompleteAdd
              : phrases.phraseCompleteUpdate)) ||
          (mode === 'add'
            ? phrases.phraseIncompleteAdd
            : phrases.phraseIncompleteUpdate)
        }
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={confirmArbitro}
      />
      <DialogMini
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title={
          mode === 'add' ? titles.titleSuccessAdd : titles.titleSuccessUpdate
        }
        textContent={
          (!!completeInputs &&
            (mode === 'add'
              ? phrases.phraseCompleteSuccessAdd
              : phrases.phraseCompleteSuccessUpdate)) ||
          (mode === 'add'
            ? phrases.phraseIncompleteSuccessAdd
            : phrases.phraseIncompleteSuccessUpdate)
        }
        textConfirm='Ok'
        triggerFunction={handleCloseSuccess}
        triggerPath='/arbitri'
      />
      <DialogMini
        open={openFailure}
        handleClose={handleCloseFailure}
        title={titles.titleFailure}
        textContent={phrases.phraseFailure}
        textConfirm='Ok'
        triggerFunction={handleCloseFailure}
      />
      <DialogMini
        open={openDisponibilita}
        handleClose={handleCloseDisponibilita}
        title={titles.titleDisponibilita}
        textContent={phrases.phraseDisponibilita}
        textConfirm='Ok'
        triggerFunction={handleCloseDisponibilita}
      />
      <DialogMini
        open={openSocieta}
        handleClose={handleCloseSocieta}
        title={titles.titleSocieta}
        textContent={phrases.phraseSocieta}
        textConfirm='Ok'
        triggerFunction={handleCloseSocieta}
      />
      <DialogMini
        open={openIndisp}
        handleClose={handleCloseIndisp}
        title={titles.titleIndisp}
        textContent={phrases.phraseIndisp}
        textConfirm='Ok'
        triggerFunction={handleCloseIndisp}
      />
      <DialogMini
        open={openIndispTyping}
        handleClose={handleCloseIndispTyping}
        title={titles.titleIndispTyping}
        textContent={phrases.phraseIndispTyping}
        textConfirm='Ok'
        triggerFunction={handleCloseIndispTyping}
      />
      <DialogMini
        open={openActive}
        handleClose={handleCloseActive}
        title={titles.titleActive}
        textContent={phrases.phraseActive}
        textConfirm='Ok'
        triggerFunction={handleCloseActive}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  arbitro: state.home.arbitro,
  designatore: state.home.designatore,
  codiceUtente: state.home.codiceUtente,
  infoUtente: state.home.infoUtente,
  affiliazioniLight: state.home.affiliazioniLight,
  dummyUpdate: state.home.dummyUpdate,
  isMobile: state.home.isMobile,
  mode: state.arbitro.mode,
  idArbitro: state.arbitro.idArbitro,
  nome: state.arbitro.nome,
  cognome: state.arbitro.cognome,
  codiceFiscale: state.arbitro.codiceFiscale,
  password: state.arbitro.password,
  dataNascita: state.arbitro.dataNascita,
  luogoNascita: state.arbitro.luogoNascita,
  indirizzoResidenza: state.arbitro.indirizzoResidenza,
  cittaResidenza: state.arbitro.cittaResidenza,
  capResidenza: state.arbitro.capResidenza,
  indirizzoDomicilio: state.arbitro.indirizzoDomicilio,
  cittaDomicilio: state.arbitro.cittaDomicilio,
  capDomicilio: state.arbitro.capDomicilio,
  telefono: state.arbitro.telefono,
  mail: state.arbitro.mail,
  codiceIBAN: state.arbitro.codiceIBAN,
  nomeBanca: state.arbitro.nomeBanca,
  costoKm: state.arbitro.costoKm,
  codiciSocietaIncompatibili: state.arbitro.codiciSocietaIncompatibili,
  disponibilitaSettimanali: state.arbitro.disponibilitaSettimanali,
  indisponibilitaPuntuali: state.arbitro.indisponibilitaPuntuali,
  note: state.arbitro.note,
  isActive: state.arbitro.isActive,
})

const ConnectedArbitro = connect(mapStateToProps)(Arbitro)

export default ConnectedArbitro
