import {
  SET_SPOSTAMENTI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_SELECTED_STATUS,
  SET_SELECTED_GROUP,
  RESET_SPOSTAMENTO,
  SET_SPOSTAMENTO,
  SET_MODE,
  SET_SELECTED_GARA,
  SET_SELECTED_FUNCTION,
  SET_NEW_DATA,
  SET_NEW_ORA,
  SET_NOTE,
  SET_CHECK_PAYMENT,
  SET_CHECK_ACCEPT,
  SET_CHECK_REJECT,
  SET_CODICE_SOCIETA_DOMANDA,
} from './types'

const INIT_STATE = {
  sortingAscending: true,
  mode: 'view',
}

export const spostamentiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SPOSTAMENTI:
      return { ...state, spostamenti: action.payload }
    case SET_SORTING_COLUMN:
      return { ...state, sortingColumn: action.payload }
    case SET_SORTING_ASCENDING:
      return { ...state, sortingAscending: action.payload }
    case SET_SELECTED_STATUS:
      return { ...state, selectedStatus: action.payload }
    case SET_SELECTED_GROUP:
      return { ...state, selectedGroup: action.payload }
    case RESET_SPOSTAMENTO:
      return {
        ...INIT_STATE,
        spostamenti: state.spostamenti,
        sortingColumn: state.sortingColumn,
        sortingAscending: state.sortingAscending,
        selectedStatus: state.selectedStatus,
        selectedGroup: state.selectedGroup,
      }
    case SET_SPOSTAMENTO:
      return { ...state, spostamento: action.payload }
    case SET_MODE:
      return { ...state, mode: action.payload }
    case SET_SELECTED_GARA:
      return { ...state, selectedGara: action.payload }
    case SET_SELECTED_FUNCTION:
      return { ...state, selectedFunction: action.payload }
    case SET_NEW_DATA:
      return { ...state, newData: action.payload }
    case SET_NEW_ORA:
      return { ...state, newOra: action.payload }
    case SET_NOTE:
      return { ...state, note: action.payload }
    case SET_CHECK_PAYMENT:
      return { ...state, checkPayment: action.payload }
    case SET_CHECK_ACCEPT:
      return { ...state, checkAccept: action.payload }
    case SET_CHECK_REJECT:
      return { ...state, checkReject: action.payload }
    case SET_CODICE_SOCIETA_DOMANDA:
      return { ...state, codiceSocietaDomanda: action.payload }
    default:
      return state
  }
}
