import {
  RESET_RISULTATO,
  SET_AVAILABLE_GARE,
  SET_SELECTED_GARA,
  SET_PARZIALI_CASA,
  SET_PARZIALI_TRASFERTA,
  SET_PENALITA_CASA,
  SET_PENALITA_TRASFERTA,
  SET_OMOLOGA,
  SET_SET_VINTI_CASA_MINI,
  SET_SET_VINTI_TRASFERTA_MINI,
  SET_PARZIALE_GOLDEN_CASA,
  SET_PARZIALE_GOLDEN_TRASFERTA,
} from './types'

const INIT_STATE = {}

export const risultatoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_RISULTATO:
      return { ...INIT_STATE, availableGare: state.availableGare }
    case SET_AVAILABLE_GARE:
      return { ...state, availableGare: action.payload }
    case SET_SELECTED_GARA:
      return { ...state, selectedGara: action.payload }
    case SET_PARZIALI_CASA:
      return { ...state, parzialiCasa: action.payload }
    case SET_PARZIALI_TRASFERTA:
      return { ...state, parzialiTrasferta: action.payload }
    case SET_PENALITA_CASA:
      return { ...state, penalitaCasa: action.payload }
    case SET_PENALITA_TRASFERTA:
      return { ...state, penalitaTrasferta: action.payload }
    case SET_OMOLOGA:
      return { ...state, omologa: action.payload }
    case SET_SET_VINTI_CASA_MINI:
      return { ...state, setVintiCasaMini: action.payload }
    case SET_SET_VINTI_TRASFERTA_MINI:
      return { ...state, setVintiTrasfertaMini: action.payload }
    case SET_PARZIALE_GOLDEN_CASA:
      return { ...state, parzialeGoldenCasa: action.payload }
    case SET_PARZIALE_GOLDEN_TRASFERTA:
      return { ...state, parzialeGoldenTrasferta: action.payload }
    default:
      return state
  }
}
