import {
  SET_ARBITRAGGI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_SELECTED_GARE,
  SET_CHECK_PAYMENT,
} from './types'

const INIT_STATE = {
  sortingAscending: true,
}

export const arbitraggiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ARBITRAGGI:
      return { ...state, arbitraggi: action.payload }
    case SET_SORTING_COLUMN:
      return { ...state, sortingColumn: action.payload }
    case SET_SORTING_ASCENDING:
      return { ...state, sortingAscending: action.payload }
    case SET_SELECTED_GARE:
      return { ...state, selectedGare: action.payload }
    case SET_CHECK_PAYMENT:
      return { ...state, checkPayment: action.payload }
    default:
      return state
  }
}
