import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'

import InputField from './InputField'
import ButtonGroup from './ButtonGroup'
import SortableTableHead from './SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { errorFunctions } from '../hooks/error-functions'
import { sortFunctions } from '../hooks/sort-functions'
import { utilsFunctions } from '../hooks/utils-functions'
import { SET_ERROR } from '../container/home/types'
import {
  RESET_INDIRIZZO,
  SET_SELECTED_GARE,
  SET_PRESSO,
  SET_INDIRIZZO,
  SET_CITTA,
} from '../container/calendari/types'

let columnsGareTeam = [
  { label: 'Selezione', sortable: false },
  { label: 'Gara', sortable: false },
  { label: 'Data e Ora', sortable: false },
  { label: 'Indirizzo attuale', sortable: false },
]

const DialogCambioIndirizzo = ({
  codiceUtente,
  iscrizioniLight,
  selectedCampionato,
  iscrizioni,
  selectedGare,
  presso,
  indirizzo,
  citta,
}) => {
  const dispatch = useDispatch()

  const [availableCategorie, setAvailableCategorie] = useState()
  const [availableSquadre, setAvailableSquadre] = useState()
  const [availableGare, setAvailableGare] = useState()
  const [availableGareTeam, setAvailableGareTeam] = useState()
  const [selectedCategoria, setSelectedCategoria] = useState()
  const [selectedSquadra, setSelectedSquadra] = useState()

  // error functions
  const { errorMinLength } = errorFunctions()
  const errorIndirizzo = useCallback(
    x => errorMinLength(x, 6),
    [errorMinLength]
  )
  const errorCitta = useCallback(x => errorMinLength(x, 3), [errorMinLength])

  // sort functions
  const { sortNumeriGara } = sortFunctions()
  const sortNumeriGaraM = useCallback(sortNumeriGara, [])

  // utils functions
  const { formatIndirizzo } = utilsFunctions()

  // Set categorie
  useEffect(() => {
    setAvailableCategorie(
      availableGare
        ?.map(i => i.categoria)
        ?.filter((v, i, s) => s.indexOf(v) === i)
        ?.sort()
    )
  }, [availableGare])

  // Set categoria
  useEffect(() => {
    setSelectedCategoria(
      availableCategorie?.length === 1 ? availableCategorie[0] : undefined
    )
  }, [availableCategorie])

  // Set squadre
  useEffect(() => {
    setAvailableSquadre(
      iscrizioniLight
        ?.filter(
          i =>
            availableGare?.map(a => a.squadraCasaID)?.includes(i.id) &&
            i.categoria === selectedCategoria
        )
        ?.map(i => i.id)
    )
  }, [availableGare, iscrizioniLight, selectedCategoria])

  // Set squadra
  useEffect(() => {
    setSelectedSquadra(
      availableSquadre?.length === 1 ? availableSquadre[0] : undefined
    )
  }, [availableSquadre])

  // Set gare
  useEffect(() => {
    setAvailableGareTeam(
      availableGare
        ?.filter(
          a =>
            a.squadraCasaID === selectedSquadra ||
            a.squadraTrasfertaID === selectedSquadra
        )
        ?.sort((a, b) => sortNumeriGaraM(a.numeroGara, b.numeroGara))
    )
  }, [availableGare, selectedSquadra, sortNumeriGaraM])

  // Reset inputs
  useEffect(() => {
    dispatch({ type: RESET_INDIRIZZO })
  }, [dispatch, selectedSquadra])

  // fetch available gare indirizzo
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(
          `calendari/campionato/${selectedCampionato}/societa/${codiceUtente}/indirizzo`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data || !data.data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le partite.',
          })
        } else {
          setAvailableGare(data.data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (selectedCampionato && codiceUtente) {
      fetch()
    } else {
      setAvailableGare()
    }
  }, [codiceUtente, dispatch, selectedCampionato])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => {
      dispatch({ type: RESET_INDIRIZZO })
    }
  }, [dispatch])

  return (
    <>
      &nbsp;
      {((!iscrizioni || iscrizioni.length === 0) && (
        <Grid item xs={12}>
          Nessuna squadra iscritta a questo campionato.
        </Grid>
      )) || (
        <Grid container spacing={3} direction='column'>
          <Grid item xs={12}>
            Il presente modulo permette di comunicare il cambio di indirizzo per
            una o più gare casalinghe.
            <br />
            Dopo aver scelto una categoria e una squadra, inserire il nuovo
            indirizzo di gioco e selezionare le gare a cui si desidera
            applicarlo.
            <br />
            <br />
            <b>
              La richiesta va inoltrata almeno 3 giorni prima dell'orario di
              inizio gara e può essere effettuata solo dalla squadra di casa.
            </b>
            <br />
            <br />
            Per domande o chiarimenti scrivere a{' '}
            <a href='mailto:pgsmilano@libero.it'>pgsmilano@libero.it</a>.
          </Grid>
          {!availableGare?.length ? (
            <Grid item xs={12}>
              <center>
                <b>
                  <em>Nessuna gara idonea.</em>
                </b>
              </center>
            </Grid>
          ) : (
            <>
              <Grid container item alignItems='center'>
                <Grid item xs={5} md={3}>
                  <center>
                    <b>Categoria</b>
                  </center>
                </Grid>
                <Grid item xs={7} md={9}>
                  {availableCategorie?.length > 0 ? (
                    <ButtonGroup
                      list={availableCategorie}
                      value={selectedCategoria}
                      func={setSelectedCategoria}
                    />
                  ) : (
                    'Nessuna categoria trovata.'
                  )}
                </Grid>
              </Grid>
              {!selectedCategoria ? (
                <Grid item>
                  <center>
                    <b>
                      <em>Selezionare una categoria.</em>
                    </b>
                  </center>
                </Grid>
              ) : (
                <Grid container item alignItems='center'>
                  <Grid item xs={5} md={3}>
                    <center>
                      <b>Squadra</b>
                    </center>
                  </Grid>
                  <Grid item xs={7} md={9}>
                    {availableSquadre?.length > 0 ? (
                      <ButtonGroup
                        list={availableSquadre}
                        labels={index =>
                          iscrizioniLight?.find(
                            i => i.id === availableSquadre[index]
                          )?.nomeSquadra
                        }
                        value={selectedSquadra}
                        func={setSelectedSquadra}
                      />
                    ) : (
                      'Nessuna squadra trovata.'
                    )}
                  </Grid>
                  <Grid item xs={5} md={3} padding={4}>
                    <center>
                      <b>Nuovo indirizzo</b>
                    </center>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    columnSpacing={3}
                    alignItems='center'
                  >
                    <Grid item xs={12} md>
                      <InputField
                        id='presso'
                        value={presso}
                        onChange={i =>
                          dispatch({
                            type: SET_PRESSO,
                            payload: i.toUpperCase() || '',
                          })
                        }
                        label='Istituto o centro sportivo'
                        adornment='place'
                      />
                    </Grid>
                    <Grid item xs={12} md>
                      <InputField
                        id='indirizzo'
                        value={indirizzo}
                        onChange={i =>
                          dispatch({
                            type: SET_INDIRIZZO,
                            payload: i.toUpperCase() || '',
                          })
                        }
                        label='Indirizzo'
                        errorText='Indirizzo troppo corto!'
                        errorFunc={errorIndirizzo}
                        adornment='zone'
                      />
                    </Grid>
                    <Grid item xs={12} md>
                      <InputField
                        id='città'
                        value={citta}
                        onChange={i =>
                          dispatch({
                            type: SET_CITTA,
                            payload: i.toUpperCase() || '',
                          })
                        }
                        label='Città'
                        errorText='Città troppo corta!'
                        errorFunc={errorCitta}
                        adornment='city'
                      />
                    </Grid>
                  </Grid>
                  &nbsp;
                  {availableGareTeam?.length > 0 && (
                    <TableContainer component={Paper}>
                      <Table size='small'>
                        <SortableTableHead
                          table={availableGareTeam}
                          columns={columnsGareTeam}
                        />
                        <TableBody>
                          {availableGareTeam?.map((a, index) => {
                            const isSelected = selectedGare?.includes(a._id)

                            const aIscrizione = iscrizioni?.find(
                              i => i.id === selectedSquadra
                            )

                            const cellProps = { align: 'center' }

                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  backgroundColor: isSelected
                                    ? colours.highlightGreen
                                    : colours.white,
                                }}
                              >
                                <TableCell {...cellProps}>
                                  <Checkbox
                                    id={`${a._id}-checkbox`}
                                    color='success'
                                    checked={!!isSelected}
                                    onChange={() =>
                                      dispatch({
                                        type: SET_SELECTED_GARE,
                                        payload: isSelected
                                          ? selectedGare?.filter(
                                              g => g !== a._id
                                            )
                                          : !selectedGare
                                          ? [a._id]
                                          : [...selectedGare, a._id],
                                      })
                                    }
                                  />
                                </TableCell>
                                <TableCell {...cellProps}>
                                  {a?.numeroGara}
                                </TableCell>
                                <TableCell {...cellProps}>
                                  {a?.data}&nbsp;{a?.ora}
                                </TableCell>
                                <TableCell {...cellProps}>
                                  {formatIndirizzo(aIscrizione, a) || (
                                    <i>Indirizzo non trovato</i>
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  iscrizioniLight: state.home.iscrizioniLight,
  selectedCampionato: state.home.selectedCampionato,
  iscrizioni: state.iscrizioni.iscrizioni,
  selectedGare: state.calendari.selectedGare,
  presso: state.calendari.presso,
  indirizzo: state.calendari.indirizzo,
  citta: state.calendari.citta,
})

const ConnectedDialogCambioIndirizzo = connect(mapStateToProps)(
  DialogCambioIndirizzo
)

export default ConnectedDialogCambioIndirizzo
