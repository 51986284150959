import React from 'react'
import { Button, ButtonGroup } from '@mui/material'

import { colours } from '../settings/settings'

const MyButtonGroup = ({ list, labels, value, func }) => {
  return (
    <ButtonGroup
      size='large'
      orientation={window.innerWidth > 900 ? 'horizontal' : 'vertical'}
    >
      {list.map((element, index) => {
        const isSelected = list.indexOf(value) === index
        return (
          <Button
            key={index}
            style={{ borderRadius: 5, fontWeight: isSelected && 'bold' }}
            variant={isSelected ? 'contained' : 'text'}
            sx={{
              color: isSelected ? colours.white : colours.black,
              background: isSelected ? colours.blue : colours.white,
              ':hover': {
                color: isSelected
                  ? colours.greyVeryLight
                  : colours.greyVeryDark,
                background: isSelected
                  ? colours.blueDark
                  : colours.greyVeryLight,
              },
            }}
            onClick={() => func(list[index])}
          >
            {!!labels ? labels(index) : element}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}

export default MyButtonGroup
