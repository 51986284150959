export const SET_SPOSTAMENTI = '@@spostamenti/SET_SPOSTAMENTI'
export const SET_SORTING_COLUMN = '@@spostamenti/SET_SORTING_COLUMN'
export const SET_SORTING_ASCENDING = '@@spostamenti/SET_SORTING_ASCENDING'
export const SET_SELECTED_STATUS = '@@spostamenti/SET_SELECTED_STATUS'
export const SET_SELECTED_GROUP = '@@spostamenti/SET_SELECTED_GROUP'

export const RESET_SPOSTAMENTO = '@@spostamenti/RESET_SPOSTAMENTO'
export const SET_SPOSTAMENTO = '@@spostamenti/SET_SPOSTAMENTO'
export const SET_MODE = '@@spostamenti/SET_MODE'

export const SET_SELECTED_GARA = '@@spostamenti/SET_SELECTED_GARA'
export const SET_SELECTED_FUNCTION = '@@spostamenti/SET_SELECTED_FUNCTION'

export const SET_NEW_DATA = '@@spostamenti/SET_NEW_DATA'
export const SET_NEW_ORA = '@@spostamenti/SET_NEW_ORA'
export const SET_NOTE = '@@spostamenti/SET_NOTE'

export const SET_CHECK_PAYMENT = '@@spostamenti/SET_CHECK_PAYMENT'
export const SET_CHECK_ACCEPT = '@@spostamenti/SET_CHECK_ACCEPT'
export const SET_CHECK_REJECT = '@@spostamenti/SET_CHECK_REJECT'

export const SET_CODICE_SOCIETA_DOMANDA =
  '@@spostamenti/SET_CODICE_SOCIETA_DOMANDA'
