import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import fileDownload from 'js-file-download'

import DialogMini from './DialogMini'
import { ColouredButton } from './Buttons'
import { sendRequest } from '../hooks/http-hook'
import { sendRequestBlob } from '../hooks/http-hook-blob'
import { colours } from '../settings/settings'
import { SET_ERROR } from '../container/home/types'

const ButtonRunDownload = ({
  buttonText,
  algorithm,
  type,
  admin,
  codiceUtente,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
  availableGironi,
  selectedGirone,
  selectedTeam,
  selectedNumeroGara,
  selectedGroup,
  selectedNumeriGara,
}) => {
  const dispatch = useDispatch()

  const isTxt = type === 'TXT'
  const isPdf = type === 'PDF'
  const isExcel = type === 'XLSX'

  // state to manage feedback dialog
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
  const [feedbackDialogTitle, setFeedbackDialogTitle] = useState()
  const [feedbackDialogPhrase, setFeedbackDialogPhrase] = useState()
  const [feedbackDialogSuccess, setFeedbackDialogSuccess] = useState()

  const handleClickOpenFeedbackDialog = (success, title, phrase) => {
    setFeedbackDialogSuccess(!!success)
    setFeedbackDialogTitle(title)
    setFeedbackDialogPhrase(phrase)
    setOpenFeedbackDialog(true)
  }

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false)
    setFeedbackDialogTitle()
    setFeedbackDialogPhrase()
    setFeedbackDialogSuccess()
  }

  const runAlgorithm = async (algorithm, type) => {
    if (!algorithm) {
      dispatch({ type: SET_ERROR, payload: 'Algoritmo non definito!' })
    } else if (algorithm === '01_GenerateTable' && !selectedCampionato) {
      dispatch({ type: SET_ERROR, payload: 'Campionato non definito!' })
    } else if (algorithm === '07_GenerateExcelGironi' && !selectedCampionato) {
      dispatch({ type: SET_ERROR, payload: 'Campionato non definito!' })
    } else if (algorithm === '07_GenerateExcelGironi' && !selectedCategoria) {
      dispatch({ type: SET_ERROR, payload: 'Categoria non definita!' })
    } else if (
      algorithm === '08_GenerateExcelCalendari' &&
      !selectedCampionato
    ) {
      dispatch({ type: SET_ERROR, payload: 'Campionato non definito!' })
    } else if (
      algorithm === '08_GenerateExcelCalendari' &&
      !selectedCategoria
    ) {
      dispatch({ type: SET_ERROR, payload: 'Categoria non definita!' })
    } else if (algorithm === '08_GenerateExcelCalendari' && !selectedGirone) {
      dispatch({ type: SET_ERROR, payload: 'Girone non definito!' })
    } else if (
      algorithm === '09_GenerateExcelProvvedimenti' &&
      !selectedCampionato
    ) {
      dispatch({ type: SET_ERROR, payload: 'Campionato non definito!' })
    } else if (
      algorithm === '09_GenerateExcelProvvedimenti' &&
      !selectedGroup
    ) {
      dispatch({ type: SET_ERROR, payload: 'Tipologia non definita!' })
    } else if (
      algorithm === '12_GenerateExcelDesignazioni' &&
      !selectedCampionato
    ) {
      dispatch({ type: SET_ERROR, payload: 'Campionato non definito!' })
    } else {
      try {
        if (algorithm === '01_GenerateTable') {
          await sendRequest(
            `algoritmi/run/${algorithm}/${selectedCampionato}${
              selectedCategoria && selectedCategoria !== availableCategorie[0]
                ? `/${selectedCategoria}`
                : ''
            }`,
            'GET',
            null,
            { Authorization: codiceUtente }
          )
        } else if (algorithm === '09_GenerateExcelProvvedimenti') {
          await sendRequest(
            `algoritmi/excel/provvedimenti/${selectedCampionato}/${selectedGroup}`,
            'POST',
            JSON.stringify({ numeriGara: selectedNumeriGara }),
            { 'Content-Type': 'application/json', Authorization: codiceUtente }
          )
        } else if (algorithm === '12_GenerateExcelDesignazioni') {
          await sendRequest(
            `algoritmi/excel/designazioni/${selectedCampionato}`,
            'POST',
            JSON.stringify({ numeriGara: selectedNumeriGara }),
            { 'Content-Type': 'application/json', Authorization: codiceUtente }
          )
        } else {
          await sendRequest(
            `algoritmi/excel/${
              algorithm === '07_GenerateExcelGironi'
                ? `gironi/${selectedCampionato}/${selectedCategoria}`
                : algorithm === '08_GenerateExcelCalendari'
                ? `calendari/${selectedCampionato}/${selectedCategoria}${
                    selectedGirone === availableGironi[0]
                      ? '/all'
                      : `/${selectedGirone}`
                  }${selectedTeam ? `/${selectedTeam}` : ''}`
                : algorithm === '10_GenerateExcelArbitri'
                ? `arbitri`
                : algorithm === '14_GenerateExcelAffiliazioni'
                ? `affiliazioni`
                : ''
            }`,
            'GET',
            null,
            { Authorization: codiceUtente }
          )
        }
        downloadFile(algorithm, type)
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    }
  }

  const downloadFile = async (algorithm, type) => {
    if (!algorithm) {
      dispatch({ type: SET_ERROR, payload: 'Algoritmo non definito!' })
    } else if (!type) {
      dispatch({ type: SET_ERROR, payload: 'Tipo di file non definito!' })
    } else {
      try {
        const fileName = 'all'
        const checkFileData = await sendRequest(
          `algoritmi/checkfile/${algorithm}/${type}/${fileName}`
        )
        const fileNames = checkFileData.fileNames
        if (fileNames.length > 0) {
          let i = 0
          // add some delay because some browsers do not download more than 10 files simultaneously
          const delayedDownload = () => {
            setTimeout(async () => {
              let fileName = fileNames[i]
              const fileData = await sendRequestBlob(
                `algoritmi/download/${algorithm}/${fileName}`
              )
              fileDownload(fileData, fileName)
              i++
              if (i < fileNames.length) delayedDownload()
            }, 500)
          }
          delayedDownload()
          handleClickOpenFeedbackDialog(
            true,
            'Download completato',
            'File scaricati correttamente.'
          )
        } else {
          handleClickOpenFeedbackDialog(false, 'Errore', 'Nessun file trovato.')
        }
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    }
  }

  return (
    <center>
      <ColouredButton
        disabled={
          (algorithm === '07_GenerateExcelGironi' &&
            (!selectedCampionato ||
              !selectedCategoria ||
              selectedCategoria === availableCategorie[0])) ||
          (algorithm === '08_GenerateExcelCalendari' &&
            (!selectedCampionato ||
              !selectedCategoria ||
              !selectedGirone ||
              selectedCategoria === availableCategorie[0] ||
              (!admin && selectedGirone === availableGironi[0]) ||
              !!selectedNumeroGara)) ||
          (algorithm === '09_GenerateExcelProvvedimenti' &&
            (!selectedCampionato ||
              !selectedGroup ||
              !selectedNumeriGara?.length)) ||
          (algorithm === '12_GenerateExcelDesignazioni' &&
            (!selectedCampionato || !selectedNumeriGara?.length))
        }
        textbold='bold'
        textcolour={colours.white}
        backgroundcolour={
          isTxt
            ? colours.greyLight
            : isPdf
            ? colours.red
            : isExcel
            ? colours.greenExcel
            : colours.blueDark
        }
        hovercolour={
          isTxt
            ? colours.grey
            : isPdf
            ? colours.redDark
            : isExcel
            ? colours.greenExcelHover
            : colours.blueVeryDark
        }
        onClick={() => runAlgorithm(algorithm, type)}
      >
        {buttonText}
      </ColouredButton>
      <DialogMini
        open={openFeedbackDialog}
        handleClose={handleCloseFeedbackDialog}
        title={feedbackDialogTitle}
        textContent={feedbackDialogPhrase}
        textConfirm='Ok'
        triggerFunction={handleCloseFeedbackDialog}
        colourBackground={!!feedbackDialogSuccess ? undefined : colours.red}
        colourHover={!!feedbackDialogSuccess ? undefined : colours.redDark}
      />
    </center>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  availableGironi: state.home.availableGironi,
  selectedGirone: state.home.selectedGirone,
  selectedTeam: state.home.selectedTeam,
  selectedNumeroGara: state.home.selectedNumeroGara,
  selectedGroup: state.provvedimenti.selectedGroup,
  selectedNumeriGara: state.home.selectedNumeriGara,
})

const ConnectedButtonRunDownload = connect(mapStateToProps)(ButtonRunDownload)

export default ConnectedButtonRunDownload
