import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'

import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR } from '../container/home/types'
import { SET_DELETE_ALSO_GARE } from '../container/iscrizioni/types'

const DialogDeleteIscrizione = ({ team, admin, deleteAlsoGare }) => {
  const dispatch = useDispatch()

  const [numeroGare, setNumeroGare] = useState()

  // fetch numero gare
  useEffect(() => {
    const fetchNumeroGare = async () => {
      if (team?.id) {
        try {
          const gareData = await sendRequest(`calendari/team/${team.id}`)

          if (!gareData) {
            dispatch({
              type: SET_ERROR,
              payload: 'Impossibile trovare le gare.',
            })
          } else {
            setNumeroGare(gareData?.length)
          }
        } catch (err) {
          console.log(err)
          dispatch({ type: SET_ERROR, payload: err.message })
        }
      } else {
        dispatch({
          type: SET_ERROR,
          payload: 'Impossibile procedere: ID della squadra non trovato!',
        })
      }
    }
    if (admin && team) {
      fetchNumeroGare()
    } else {
      setNumeroGare()
    }
  }, [admin, dispatch, team])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_DELETE_ALSO_GARE })
  }, [dispatch])

  return !admin ? null : (
    <>
      &nbsp;
      {((!team || team.length === 0) && (
        <Grid item xs={12}>
          Nessuna squadra selezionata.
        </Grid>
      )) ||
        (numeroGare === 0 && (
          <Grid item xs={12}>
            La squadra selezionata non era ancora coinvolta in nessuna partita.
          </Grid>
        )) || (
          <Grid container spacing={3} direction='column'>
            <Grid item xs={12}>
              {numeroGare === 1
                ? `È stata trovata ${numeroGare} partita`
                : `Sono state trovate ${numeroGare} partite`}{' '}
              in cui questa squadra è coinvolta. Selezionare la seguente casella
              se si intendono eliminare dalla piattaforma anche le partite;
              lasciarla vuota se invece si intendono mantenere le partite.
            </Grid>
            &nbsp;
            {!!team && (
              <Grid
                container
                item
                xs={12}
                spacing={3}
                alignItems='center'
                justifyContent='center'
              >
                <Grid item>
                  <center>Eliminare anche le partite?</center>
                </Grid>
                <Grid item>
                  <center>
                    <Checkbox
                      id='deleteAlsoGare'
                      checked={!!deleteAlsoGare}
                      onChange={event =>
                        dispatch({
                          type: SET_DELETE_ALSO_GARE,
                          payload: event.target.checked,
                        })
                      }
                    />
                  </center>
                </Grid>
              </Grid>
            )}
            &nbsp;
          </Grid>
        )}
    </>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  deleteAlsoGare: state.iscrizioni.deleteAlsoGare,
})

const ConnectedDialogDeleteIscrizione = connect(mapStateToProps)(
  DialogDeleteIscrizione
)

export default ConnectedDialogDeleteIscrizione
