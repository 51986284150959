import {
  RESET_ARBITRO,
  SET_ARBITRO,
  SET_MODE,
  SET_ID_ARBITRO,
  SET_NOME,
  SET_COGNOME,
  SET_CODICE_FISCALE,
  SET_PASSWORD,
  SET_DATA_NASCITA,
  SET_LUOGO_NASCITA,
  SET_INDIRIZZO_RESIDENZA,
  SET_CITTA_RESIDENZA,
  SET_CAP_RESIDENZA,
  SET_INDIRIZZO_DOMICILIO,
  SET_CITTA_DOMICILIO,
  SET_CAP_DOMICILIO,
  SET_TELEFONO,
  SET_MAIL,
  SET_CODICE_IBAN,
  SET_NOME_BANCA,
  SET_COSTO_KM,
  SET_CODICI_SOCIETA_INCOMPATIBILI,
  SET_DISPONIBILITA_SETTIMANALI,
  SET_INDISPONIBILITA_PUNTUALI,
  SET_NOTE,
  SET_IS_ACTIVE,
} from './types'

const INIT_STATE = {
  mode: 'add',
  idArbitro: undefined,
  nome: '',
  cognome: '',
  codiceFiscale: '',
  password: '',
  dataNascita: '',
  luogoNascita: '',
  indirizzoResidenza: '',
  cittaResidenza: '',
  capResidenza: '',
  indirizzoDomicilio: '',
  cittaDomicilio: '',
  capDomicilio: '',
  telefono: '',
  mail: '',
  codiceIBAN: '',
  nomeBanca: '',
  costoKm: undefined,
  codiciSocietaIncompatibili: [],
  disponibilitaSettimanali: [],
  indisponibilitaPuntuali: [],
  note: '',
  isActive: true,
}

export const arbitroReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ARBITRO:
      return INIT_STATE
    case SET_ARBITRO:
      return { ...state, ...action.payload, idArbitro: action.payload._id }
    case SET_MODE:
      return { ...state, mode: action.payload }
    case SET_ID_ARBITRO:
      return { ...state, idArbitro: action.payload }
    case SET_NOME:
      return { ...state, nome: action.payload }
    case SET_COGNOME:
      return { ...state, cognome: action.payload }
    case SET_CODICE_FISCALE:
      return { ...state, codiceFiscale: action.payload }
    case SET_PASSWORD:
      return { ...state, password: action.payload }
    case SET_DATA_NASCITA:
      return { ...state, dataNascita: action.payload }
    case SET_LUOGO_NASCITA:
      return { ...state, luogoNascita: action.payload }
    case SET_INDIRIZZO_RESIDENZA:
      return { ...state, indirizzoResidenza: action.payload }
    case SET_CITTA_RESIDENZA:
      return { ...state, cittaResidenza: action.payload }
    case SET_CAP_RESIDENZA:
      return { ...state, capResidenza: action.payload }
    case SET_INDIRIZZO_DOMICILIO:
      return { ...state, indirizzoDomicilio: action.payload }
    case SET_CITTA_DOMICILIO:
      return { ...state, cittaDomicilio: action.payload }
    case SET_CAP_DOMICILIO:
      return { ...state, capDomicilio: action.payload }
    case SET_TELEFONO:
      return { ...state, telefono: action.payload }
    case SET_MAIL:
      return { ...state, mail: action.payload }
    case SET_CODICE_IBAN:
      return { ...state, codiceIBAN: action.payload }
    case SET_NOME_BANCA:
      return { ...state, nomeBanca: action.payload }
    case SET_COSTO_KM:
      return { ...state, costoKm: action.payload }
    case SET_CODICI_SOCIETA_INCOMPATIBILI:
      return { ...state, codiciSocietaIncompatibili: action.payload }
    case SET_DISPONIBILITA_SETTIMANALI:
      return { ...state, disponibilitaSettimanali: action.payload }
    case SET_INDISPONIBILITA_PUNTUALI:
      return { ...state, indisponibilitaPuntuali: action.payload }
    case SET_NOTE:
      return { ...state, note: action.payload }
    case SET_IS_ACTIVE:
      return { ...state, isActive: action.payload }
    default:
      return state
  }
}
