export const RESET_ARBITRO = '@@arbitro/RESET_ARBITRO'
export const SET_ARBITRO = '@@arbitro/SET_ARBITRO'
export const SET_MODE = '@@arbitro/SET_MODE'

export const SET_ID_ARBITRO = '@@arbitro/SET_ID_ARBITRO'
export const SET_NOME = '@@arbitro/SET_NOME'
export const SET_COGNOME = '@@arbitro/SET_COGNOME'
export const SET_CODICE_FISCALE = '@@arbitro/SET_CODICE_FISCALE'
export const SET_PASSWORD = '@@arbitro/SET_PASSWORD'
export const SET_DATA_NASCITA = '@@arbitro/SET_DATA_NASCITA'
export const SET_LUOGO_NASCITA = '@@arbitro/SET_LUOGO_NASCITA'
export const SET_INDIRIZZO_RESIDENZA = '@@arbitro/SET_INDIRIZZO_RESIDENZA'
export const SET_CITTA_RESIDENZA = '@@arbitro/SET_CITTA_RESIDENZA'
export const SET_CAP_RESIDENZA = '@@arbitro/SET_CAP_RESIDENZA'
export const SET_INDIRIZZO_DOMICILIO = '@@arbitro/SET_INDIRIZZO_DOMICILIO'
export const SET_CITTA_DOMICILIO = '@@arbitro/SET_CITTA_DOMICILIO'
export const SET_CAP_DOMICILIO = '@@arbitro/SET_CAP_DOMICILIO'
export const SET_TELEFONO = '@@arbitro/SET_TELEFONO'
export const SET_MAIL = '@@arbitro/SET_MAIL'
export const SET_CODICE_IBAN = '@@arbitro/SET_CODICE_IBAN'
export const SET_NOME_BANCA = '@@arbitro/SET_NOME_BANCA'
export const SET_COSTO_KM = '@@arbitro/SET_COSTO_KM'
export const SET_CODICI_SOCIETA_INCOMPATIBILI =
  '@@arbitro/SET_CODICI_SOCIETA_INCOMPATIBILI'
export const SET_DISPONIBILITA_SETTIMANALI =
  '@@arbitro/SET_DISPONIBILITA_SETTIMANALI'
export const SET_INDISPONIBILITA_PUNTUALI =
  '@@arbitro/SET_INDISPONIBILITA_PUNTUALI'
export const SET_NOTE = '@@arbitro/SET_NOTE'
export const SET_IS_ACTIVE = '@@arbitro/SET_IS_ACTIVE'
