import {
  SET_RENDICONTI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_RENDICONTO_MODE,
  SET_RENDICONTO,
  SET_CODICE_SOCIETA_ADD,
  SET_ID_GARE_ARBITRO,
  SET_ID_GARE_RITARDO,
} from './types'

const INIT_STATE = {
  sortingAscending: true,
}

export const rendicontiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_RENDICONTI:
      return { ...state, rendiconti: action.payload }
    case SET_SORTING_COLUMN:
      return { ...state, sortingColumn: action.payload }
    case SET_SORTING_ASCENDING:
      return { ...state, sortingAscending: action.payload }
    case SET_RENDICONTO_MODE:
      return { ...state, rendicontoMode: action.payload }
    case SET_RENDICONTO:
      return { ...state, rendiconto: action.payload }
    case SET_CODICE_SOCIETA_ADD:
      return { ...state, codiceSocietaAdd: action.payload }
    case SET_ID_GARE_ARBITRO:
      return { ...state, idGareArbitro: action.payload }
    case SET_ID_GARE_RITARDO:
      return { ...state, idGareRitardo: action.payload }
    default:
      return state
  }
}
