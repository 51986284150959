export const RESET_AFFILIAZIONE = '@@affiliazione/RESET_AFFILIAZIONE'
export const SET_AFFILIAZIONE = '@@affiliazione/SET_AFFILIAZIONE'
export const SET_MODE = '@@affiliazione/SET_MODE'

export const SET_ID_SOCIETA = '@@affiliazione/SET_ID_SOCIETA'
export const SET_CODICE_SOCIETA = '@@affiliazione/SET_CODICE_SOCIETA'
export const SET_DENOMINAZIONE_SOCIETA =
  '@@affiliazione/SET_DENOMINAZIONE_SOCIETA'
export const SET_NOME_SOCIETA = '@@affiliazione/SET_NOME_SOCIETA'
export const SET_PASSWORD = '@@affiliazione/SET_PASSWORD'
export const SET_REFERENTE = '@@affiliazione/SET_REFERENTE'
export const SET_RUOLO = '@@affiliazione/SET_RUOLO'
export const SET_TELEFONO = '@@affiliazione/SET_TELEFONO'
export const SET_MAIL = '@@affiliazione/SET_MAIL'
export const SET_ZONA = '@@affiliazione/SET_ZONA'
export const SET_IS_ACTIVE = '@@affiliazione/SET_IS_ACTIVE'
