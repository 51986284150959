import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'

import { SET_RIMBORSO_IS_PAID } from '../container/rimborsi/types'

const DialogRimborsoPagato = ({ rimborso, rimborsoIsPaid }) => {
  const dispatch = useDispatch()

  // Set isPaid
  useEffect(() => {
    dispatch({ type: SET_RIMBORSO_IS_PAID, payload: rimborso?.isPaid })
  }, [dispatch, rimborso])

  return (
    <>
      &nbsp;
      {(!rimborso && (
        <Grid item xs={12}>
          Nessun rimborso selezionato.
        </Grid>
      )) || (
        <Grid container spacing={3} direction='column'>
          <Grid item xs={12}>
            Il presente modulo permette di aggiornare lo stato del pagamento del
            rimborso. Una volta pagato, questo rimborso non potrà più essere
            modificato nè eliminato.
          </Grid>
          &nbsp;
          <Grid
            container
            item
            xs={12}
            spacing={3}
            alignItems='center'
            justifyContent='center'
          >
            <Grid item>
              <center>Rimborso pagato:</center>
            </Grid>
            <Grid item>
              <center>
                <Checkbox
                  id='isPaid'
                  checked={!!rimborsoIsPaid}
                  onChange={event =>
                    dispatch({
                      type: SET_RIMBORSO_IS_PAID,
                      payload: event.target.checked,
                    })
                  }
                />
              </center>
            </Grid>
          </Grid>
          &nbsp;
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  rimborso: state.rimborsi.rimborso,
  rimborsoIsPaid: state.rimborsi.rimborsoIsPaid,
})

const ConnectedDialogRimborsoPagato =
  connect(mapStateToProps)(DialogRimborsoPagato)

export default ConnectedDialogRimborsoPagato
