import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'

import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR } from '../container/home/types'
import { SET_DELETE_ALSO_ISCRIZIONI } from '../container/affiliazioni/types'

const DialogDeleteAffiliazione = ({
  affiliazione,
  admin,
  codiceUtente,
  deleteAlsoIscrizioni,
}) => {
  const dispatch = useDispatch()

  const [numeroIscrizioni, setNumeroIscrizioni] = useState()

  // fetch numero iscrizioni
  useEffect(() => {
    const fetchNumeroIscrizioni = async () => {
      try {
        const iscrizioniData = await sendRequest(
          `iscrizioni/societa/${affiliazione.codiceSocieta}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!iscrizioniData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le iscrizioni.',
          })
        } else {
          setNumeroIscrizioni(iscrizioniData?.length)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (admin && affiliazione?.codiceSocieta) {
      fetchNumeroIscrizioni()
    } else {
      setNumeroIscrizioni()
    }
  }, [admin, affiliazione, codiceUtente, dispatch])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_DELETE_ALSO_ISCRIZIONI })
  }, [dispatch])

  return !admin ? null : (
    <>
      &nbsp;
      {(!affiliazione && (
        <Grid item xs={12}>
          Nessuna società selezionata.
        </Grid>
      )) ||
        (numeroIscrizioni === 0 && (
          <Grid item xs={12}>
            La società selezionata non ha ancora iscritto nessuna squadra.
          </Grid>
        )) || (
          <Grid container spacing={3} direction='column'>
            <Grid item xs={12}>
              {numeroIscrizioni === 1
                ? `C'è ${numeroIscrizioni} iscrizione effettuata`
                : `Ci sono ${numeroIscrizioni} iscrizioni effettuate`}{' '}
              da questa società. Selezionare la seguente casella se si intendono
              eliminare dalla piattaforma anche tutte le iscrizioni e le
              relative partite e spostamenti; lasciarla vuota se invece si
              intendono mantenere.
            </Grid>
            &nbsp;
            {!!affiliazione && (
              <Grid
                container
                item
                xs={12}
                spacing={3}
                alignItems='center'
                justifyContent='center'
              >
                <Grid item>
                  <center>Eliminare anche le iscrizioni?</center>
                </Grid>
                <Grid item>
                  <center>
                    <Checkbox
                      id='deleteAlsoIscrizioni'
                      checked={!!deleteAlsoIscrizioni}
                      onChange={event =>
                        dispatch({
                          type: SET_DELETE_ALSO_ISCRIZIONI,
                          payload: event.target.checked,
                        })
                      }
                    />
                  </center>
                </Grid>
              </Grid>
            )}
            &nbsp;
          </Grid>
        )}
    </>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  deleteAlsoIscrizioni: state.affiliazioni.deleteAlsoIscrizioni,
})

const ConnectedDialogDeleteAffiliazione = connect(mapStateToProps)(
  DialogDeleteAffiliazione
)

export default ConnectedDialogDeleteAffiliazione
