export const RESET_ISCRIZIONE = '@@iscrizione/RESET_ISCRIZIONE'
export const SET_ISCRIZIONE = '@@iscrizione/SET_ISCRIZIONE'
export const SET_MODE = '@@iscrizione/SET_MODE'

export const SET_ID_SQUADRA = '@@iscrizione/SET_ID_SQUADRA'
export const SET_NOME_SQUADRA = '@@iscrizione/SET_NOME_SQUADRA'

export const SET_CODICE_CAMPIONATO = '@@iscrizione/SET_CODICE_CAMPIONATO'
export const SET_CODICE_SOCIETA = '@@iscrizione/SET_CODICE_SOCIETA'
export const SET_CATEGORIA = '@@iscrizione/SET_CATEGORIA'
export const SET_ABBINAMENTI = '@@iscrizione/SET_ABBINAMENTI'
export const SET_ALTERNANZE = '@@iscrizione/SET_ALTERNANZE'
export const SET_NOTE = '@@iscrizione/SET_NOTE'
export const SET_SENZA_PALESTRA = '@@iscrizione/SET_SENZA_PALESTRA'
export const SET_GIORNO = '@@iscrizione/SET_GIORNO'
export const SET_ORARIO = '@@iscrizione/SET_ORARIO'
export const SET_CITTA = '@@iscrizione/SET_CITTA'
export const SET_INDIRIZZO = '@@iscrizione/SET_INDIRIZZO'
export const SET_PRESSO = '@@iscrizione/SET_PRESSO'
export const SET_NOME_REFERENTE = '@@iscrizione/SET_NOME_REFERENTE'
export const SET_TELEFONO_REFERENTE = '@@iscrizione/SET_TELEFONO_REFERENTE'
export const SET_MAIL_REFERENTE = '@@iscrizione/SET_MAIL_REFERENTE'
export const SET_NOME_REFERENTE_2 = '@@iscrizione/SET_NOME_REFERENTE_2'
export const SET_TELEFONO_REFERENTE_2 = '@@iscrizione/SET_TELEFONO_REFERENTE_2'
export const SET_MAIL_REFERENTE_2 = '@@iscrizione/SET_MAIL_REFERENTE_2'
export const SET_CHECK_PRIVACY = '@@iscrizione/SET_CHECK_PRIVACY'

export const SET_HAS_DEROGA = '@@iscrizione/SET_HAS_DEROGA'
export const SET_MOTIVO_DEROGA = '@@iscrizione/SET_MOTIVO_DEROGA'
