import React, { useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import DialogMini from './DialogMini'
import InputField from './InputField'
import { ColouredButton } from './Buttons'
import {
  colours,
  titlesIscrizioni as titles,
  phrasesIscrizioni as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { errorFunctions } from '../hooks/error-functions'
import { utilsFunctions } from '../hooks/utils-functions'
import { SET_DUMMY_UPDATE } from '../container/home/types'

const DialogIndirizzo = ({
  admin,
  codiceUtente,
  dummyUpdate,
  garaIndirizzo,
  iscrizioneIndirizzo,
}) => {
  const dispatch = useDispatch()

  const [presso, setPresso] = useState('')
  const [indirizzo, setIndirizzo] = useState('')
  const [citta, setCitta] = useState('')

  // state to manage feedback dialog
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
  const [feedbackDialogTitle, setFeedbackDialogTitle] = useState()
  const [feedbackDialogPhrase, setFeedbackDialogPhrase] = useState()
  const [feedbackDialogSuccess, setFeedbackDialogSuccess] = useState()

  const handleClickOpenFeedbackDialog = (success, title, phrase) => {
    setFeedbackDialogSuccess(!!success)
    setFeedbackDialogTitle(title)
    setFeedbackDialogPhrase(phrase)
    setOpenFeedbackDialog(true)
  }

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false)
    setFeedbackDialogTitle()
    setFeedbackDialogPhrase()
    setFeedbackDialogSuccess()
  }

  // error functions
  const { errorMinLength } = errorFunctions()
  const errorIndirizzo = useCallback(
    x => errorMinLength(x, 6),
    [errorMinLength]
  )
  const errorCitta = useCallback(x => errorMinLength(x, 3), [errorMinLength])

  // utils functions
  const { formatIndirizzo } = utilsFunctions()
  const fIndirizzo = formatIndirizzo(iscrizioneIndirizzo, garaIndirizzo)

  const eDispatch = message =>
    handleClickOpenFeedbackDialog(false, 'Impossibile procedere', message)

  // update address
  const updateIndirizzo = async () => {
    if (!codiceUtente) {
      eDispatch('Utente non trovato!')
    } else if (!garaIndirizzo?._id) {
      eDispatch('Nessuna gara selezionata!')
    } else if (!indirizzo) {
      eDispatch('Indirizzo non trovato!')
    } else if (!citta) {
      eDispatch('Città non trovata!')
    } else {
      try {
        await sendRequest(
          `calendari/${garaIndirizzo._id}/indirizzo`,
          'PATCH',
          JSON.stringify({ presso, indirizzo, citta }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
        handleClickOpenFeedbackDialog(
          true,
          titles.titleIndirizzo,
          phrases.phrasesIndirizzoSuccess
        )
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    }
  }

  return (
    <>
      {(!garaIndirizzo && 'Nessuna partita selezionata.') || (
        <>
          {(!fIndirizzo && <i>Indirizzo non trovato.</i>) || (
            <>
              <b>{fIndirizzo}</b>
              <br />
              <br />
              <a
                target='_blank'
                rel='noreferrer noopener'
                style={{ textDecoration: 'none', margin: 5 }}
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  fIndirizzo
                )}`}
              >
                Visualizza su Google Maps
              </a>
            </>
          )}
          {admin && (
            <>
              <Grid item xs={12} marginTop={5} marginBottom={2}>
                <b>Aggiorna indirizzo</b>
              </Grid>
              <Grid container item xs={12} alignItems='center'>
                <Grid item xs={12}>
                  <InputField
                    id='presso'
                    value={presso}
                    onChange={i => setPresso(i.toUpperCase() || '')}
                    label='Istituto o centro sportivo'
                    adornment='place'
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    id='indirizzo'
                    value={indirizzo}
                    onChange={i => setIndirizzo(i.toUpperCase() || '')}
                    label='Indirizzo'
                    errorText='Indirizzo troppo corto!'
                    errorFunc={errorIndirizzo}
                    adornment='zone'
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    id='città'
                    value={citta}
                    onChange={i => setCitta(i.toUpperCase() || '')}
                    label='Città'
                    errorText='Città troppo corta!'
                    errorFunc={errorCitta}
                    adornment='city'
                  />
                </Grid>
              </Grid>
              <Grid item xs>
                <center>
                  <ColouredButton
                    textcolour={colours.white}
                    textbold='bold'
                    backgroundcolour={colours.blueDark}
                    hovercolour={colours.blueVeryDark}
                    onClick={updateIndirizzo}
                  >
                    Aggiorna
                  </ColouredButton>
                </center>
              </Grid>
            </>
          )}
        </>
      )}
      <DialogMini
        open={openFeedbackDialog}
        handleClose={handleCloseFeedbackDialog}
        title={feedbackDialogTitle}
        textContent={feedbackDialogPhrase}
        textConfirm='Ok'
        triggerFunction={handleCloseFeedbackDialog}
        colourBackground={!!feedbackDialogSuccess ? undefined : colours.red}
        colourHover={!!feedbackDialogSuccess ? undefined : colours.redDark}
      />
    </>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  dummyUpdate: state.home.dummyUpdate,
  garaIndirizzo: state.calendari.garaIndirizzo,
  iscrizioneIndirizzo: state.calendari.iscrizioneIndirizzo,
})

const ConnectedDialogIndirizzo = connect(mapStateToProps)(DialogIndirizzo)

export default ConnectedDialogIndirizzo
