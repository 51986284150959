import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  CircularProgress,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { sortFunctions } from '../hooks/sort-functions'
import { SET_CLASSIFICHE, SET_ERROR } from '../container/home/types'

const Classifiche = ({
  admin,
  iscrizioniLight,
  classifiche,
  selectedCampionatoCalendariPubblicati,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
  availableGironi,
  selectedGirone,
  selectedTeam,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Classifiche'

  const avulsa = 'avulsa'
  const isAvulsa = selectedGirone === avulsa

  let columnsClassifiche
  if (isAvulsa) {
    columnsClassifiche = [
      { label: 'Squadra', sortable: false },
      { label: 'Classificata', sortable: false },
      { label: 'Punti/Partita', sortable: false },
      { label: 'Vittorie', sortable: false },
      { label: 'Quoziente set', sortable: false },
      { label: 'Quoziente punti', sortable: false },
    ]
  } else {
    columnsClassifiche = [
      { label: 'Squadra', sortable: false },
      { label: 'Punti', sortable: false },
      { label: 'Partite giocate', sortable: false },
      { label: 'Partite vinte', sortable: false },
      { label: 'Set vinti', sortable: false },
      { label: 'Set persi', sortable: false },
      { label: 'Quoziente set', sortable: false },
      { label: 'Quoziente punti', sortable: false },
      { label: 'Penalità', sortable: false },
    ]
  }

  const [isLoading, setIsLoading] = useState()

  // sort functions
  const { sortNumerically, sortCategorie, sortGironi } = sortFunctions()
  const sortNumericallyM = useCallback(sortNumerically, [])
  const sortCategorieM = useCallback(sortCategorie, [])
  const sortGironiM = useCallback(sortGironi, [])

  // function to round n with d decimals
  const roundN = (n, d = 2) =>
    (Math.round(n * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d)

  // function to compute the ratio a and b
  const computeQuoziente = (a, b) =>
    a === 0 ? roundN(0) : b === 0 ? 'Max' : roundN(a / b)

  // fetch classifiche
  useEffect(() => {
    const fetchClassifiche = async () => {
      setIsLoading(true)
      try {
        const classificheData = await sendRequest(
          `classifiche/campionato/${selectedCampionato}`
        )

        if (!classificheData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le classifiche.',
          })
        } else {
          dispatch({
            type: SET_CLASSIFICHE,
            payload: classificheData.sort(
              (a, b) =>
                sortCategorieM(a.categoria, b.categoria) ||
                sortGironiM(a.girone, b.girone) ||
                sortNumericallyM(a.ranking, b.ranking)
            ),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setIsLoading(false)
    }
    if (
      selectedCampionato &&
      (admin || selectedCampionatoCalendariPubblicati)
    ) {
      fetchClassifiche()
    } else {
      dispatch({ type: SET_CLASSIFICHE })
    }
  }, [
    admin,
    dispatch,
    selectedCampionato,
    selectedCampionatoCalendariPubblicati,
    sortCategorieM,
    sortGironiM,
    sortNumericallyM,
  ])

  const ClassificheTable = ({
    campionato = selectedCampionato,
    categoria,
    girone,
  }) => {
    const classificaTable = classifiche.find(
      i =>
        i.codiceCampionato === campionato &&
        i.categoria === categoria &&
        i.girone === girone
    )?.squadre

    return (
      <>
        &nbsp;
        <h4 style={{ paddingLeft: 20 }}>
          Categoria {categoria} -{' '}
          {girone === avulsa ? 'Classifica avulsa' : `Girone ${girone}`}
        </h4>
        <TableContainer component={Paper}>
          <Table size='small'>
            <SortableTableHead
              table={classificaTable}
              columns={columnsClassifiche}
            />
            <TableBody>
              {classificaTable?.map((s, index) => {
                const sInfo = iscrizioniLight?.find(n => n.id === s.squadraID)

                const sNome =
                  s.squadraID === selectedTeam ? (
                    <b>{sInfo?.nomeSquadra}</b>
                  ) : (
                    sInfo?.nomeSquadra
                  )

                const sNomeDeroga = sNome ? (
                  sInfo?.hasDeroga ? (
                    <Grid
                      container
                      item
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Grid item>{sNome}</Grid>
                      <Grid item>
                        <Tooltip title='Fuori classifica'>
                          <PriorityHighIcon color='error' />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    sNome
                  )
                ) : (
                  <i>Squadra non trovata</i>
                )

                const sRanking = classifiche
                  ?.find(
                    i =>
                      i.codiceCampionato === campionato &&
                      i.categoria === categoria &&
                      i.girone === avulsa
                  )
                  ?.squadre?.find(i => i.squadraID === s.squadraID)?.ranking

                const sPunti = (s.punteggio || 0) - (s.penalita || 0)

                const sPartite =
                  (s.partiteVinte || 0) +
                  (s.partitePerse || 0) +
                  (s.partitePareggiate || 0)

                return (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor:
                        (sRanking === 1 && colours.highlightGreen) ||
                        (sRanking === 2 && colours.highlightBlue) ||
                        colours.white,
                    }}
                  >
                    {isAvulsa ? (
                      <>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {sNomeDeroga}
                        </TableCell>
                        <TableCell align='center'>
                          {sInfo?.hasDeroga ? '-' : `${sRanking}°`}
                        </TableCell>
                        <TableCell align='center'>
                          <b>{computeQuoziente(sPunti, sPartite)}</b>
                        </TableCell>
                        <TableCell align='center'>
                          {Math.round(
                            Number(computeQuoziente(s.partiteVinte, sPartite)) *
                              100
                          )}
                          %
                        </TableCell>
                        <TableCell align='center'>
                          {computeQuoziente(s.setVinti, s.setPersi)}
                        </TableCell>
                        <TableCell align='center'>
                          {computeQuoziente(s.puntiVinti, s.puntiPersi)}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {sNomeDeroga}
                        </TableCell>
                        <TableCell align='center'>
                          <b>{sPunti}</b>
                        </TableCell>
                        <TableCell align='center'>{sPartite}</TableCell>
                        <TableCell align='center'>
                          {s.partiteVinte || 0}
                        </TableCell>
                        <TableCell align='center'>{s.setVinti || 0}</TableCell>
                        <TableCell align='center'>{s.setPersi || 0}</TableCell>
                        <TableCell align='center'>
                          {computeQuoziente(s.setVinti, s.setPersi)}
                        </TableCell>
                        <TableCell align='center'>
                          {computeQuoziente(s.puntiVinti, s.puntiPersi)}
                        </TableCell>
                        <TableCell align='center'>{s.penalita || 0}</TableCell>
                      </>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Classifiche</h2>
        </center>
      </Grid>
      &nbsp;
      <SelectionBar />
      {(!admin &&
        selectedCampionato &&
        !selectedCampionatoCalendariPubblicati && (
          <Grid item xs={12}>
            <center>
              <h4>Classifiche non ancora pubblicate.</h4>
            </center>
          </Grid>
        )) || (
        <>
          {(!!isLoading && (
            <Grid item xs={12} padding={5}>
              <center>
                <CircularProgress disableShrink={true} />
              </center>
            </Grid>
          )) ||
            (selectedCategoria &&
              selectedGirone &&
              !classifiche?.filter(
                i =>
                  i.codiceCampionato === selectedCampionato &&
                  (selectedCategoria === availableCategorie[0]
                    ? true
                    : i.categoria === selectedCategoria) &&
                  (selectedGirone === availableGironi[0]
                    ? true
                    : i.girone === selectedGirone)
              )?.length && (
                <Grid item xs={12}>
                  <center>
                    <h4>Nessuna classifica da visualizzare.</h4>
                  </center>
                </Grid>
              )) ||
            (classifiche?.length > 0 && !!selectedCampionato && (
              <>
                {classifiche
                  .filter(
                    i =>
                      i.codiceCampionato === selectedCampionato &&
                      (selectedCategoria === availableCategorie[0]
                        ? true
                        : i.categoria === selectedCategoria)
                  )
                  .map(i => i.categoria)
                  .filter((v, i, s) => s.indexOf(v) === i)
                  .filter(categoria => !!categoria)
                  .some(
                    categoria =>
                      classifiche
                        .filter(
                          i =>
                            i.codiceCampionato === selectedCampionato &&
                            i.categoria === categoria &&
                            (selectedGirone === availableGironi[0]
                              ? i.girone !== avulsa
                              : i.girone === selectedGirone)
                        )
                        .map(i => i.girone)
                        .filter((v, i, s) => s.indexOf(v) === i)?.length > 0
                  ) && (
                  <Grid item xs={12} paddingX={5}>
                    {selectedGirone === 'avulsa' ? (
                      <>
                        Le gare giocate contro squadre fuori classifica non
                        contano nella classifica avulsa.
                      </>
                    ) : (
                      <>
                        Le squadre con il punto esclamativo rosso sono fuori
                        classifica e non passano alle fasi finali.
                        <br />
                        Le gare giocate contro tali squadre non contano nella
                        classifica avulsa.
                      </>
                    )}
                  </Grid>
                )}
                <Grid container item xs={12}>
                  {classifiche
                    .filter(
                      i =>
                        i.codiceCampionato === selectedCampionato &&
                        (selectedCategoria === availableCategorie[0]
                          ? true
                          : i.categoria === selectedCategoria)
                    )
                    .map(i => i.categoria)
                    .filter((v, i, s) => s.indexOf(v) === i)
                    .filter(categoria => !!categoria)
                    .sort()
                    .map(categoria =>
                      classifiche
                        .filter(
                          i =>
                            i.codiceCampionato === selectedCampionato &&
                            i.categoria === categoria &&
                            (selectedGirone === availableGironi[0]
                              ? i.girone !== avulsa
                              : i.girone === selectedGirone)
                        )
                        .map(i => i.girone)
                        .filter((v, i, s) => s.indexOf(v) === i)
                        .sort((a, b) => sortGironi(a, b))
                        .map(girone => (
                          <Grid
                            item
                            xs={12}
                            paddingX={3}
                            key={`${selectedCampionato}-${categoria}-${girone}`}
                          >
                            <ClassificheTable
                              campionato={selectedCampionato}
                              categoria={categoria}
                              girone={girone}
                            />
                          </Grid>
                        ))
                    )}
                </Grid>
              </>
            ))}
        </>
      )}
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  iscrizioniLight: state.home.iscrizioniLight,
  classifiche: state.home.classifiche,
  selectedCampionatoCalendariPubblicati:
    state.home.selectedCampionatoCalendariPubblicati,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  availableGironi: state.home.availableGironi,
  selectedGirone: state.home.selectedGirone,
  selectedTeam: state.home.selectedTeam,
})

const ConnectedClassifiche = connect(mapStateToProps)(Classifiche)

export default ConnectedClassifiche
