import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import AutoComplete from './AutoComplete'
import { SET_CODICE_SOCIETA_ADD } from '../container/rendiconti/types'

const DialogRendicontoAdd = ({ affiliazioniLight, codiceSocietaAdd }) => {
  const dispatch = useDispatch()

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_CODICE_SOCIETA_ADD })
  }, [dispatch])

  return (
    <>
      &nbsp;
      <Grid container item spacing={3} direction='column'>
        <Grid item xs={12}>
          Selezionare una società e cliccare su CONFERMA per creare il
          rendiconto della stagione corrente.
        </Grid>
        &nbsp;
        <Grid item xs={12}>
          <center>
            <AutoComplete
              id='codice-societa-add'
              value={codiceSocietaAdd}
              options={affiliazioniLight}
              getOptionLabel={op => {
                const a = affiliazioniLight?.find(
                  a => a?.codiceSocieta === (op?.codiceSocieta || op)
                )
                return `${a?.codiceSocieta} - ${a?.nomeSocieta}`
              }}
              isOptionEqualToValue={(op, value) => op?.codiceSocieta === value}
              label='Società'
              disabled={!affiliazioniLight}
              onChange={i =>
                dispatch({
                  type: SET_CODICE_SOCIETA_ADD,
                  payload: i?.codiceSocieta,
                })
              }
            />
          </center>
        </Grid>
        &nbsp;
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  affiliazioniLight: state.home.affiliazioniLight,
  codiceSocietaAdd: state.rendiconti.codiceSocietaAdd,
})

const ConnectedDialogRendicontoAdd =
  connect(mapStateToProps)(DialogRendicontoAdd)

export default ConnectedDialogRendicontoAdd
