import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import DialogMini from '../components/DialogMini'
import SortableTableHead from '../components/SortableTableHead'
import ButtonRunDownload from '../components/ButtonRunDownload'
import { colours, titlesArbitro as titles } from '../settings/settings'
import { ColouredButton } from '../components/Buttons'
import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import {
  SET_ARBITRI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
} from '../container/arbitri/types'
import {
  RESET_ARBITRO,
  SET_ARBITRO,
  SET_MODE,
} from '../container/arbitro/types'

const Arbitri = ({
  admin,
  codiceUtente,
  dummyUpdate,
  loadingArbitri,
  arbitri,
  sortingColumn,
  sortingAscending,
  deleteAlsoDesignazioni,
}) => {
  const dispatch = useDispatch()

  const history = useHistory()

  document.title = 'PGS Milano - Arbitri'

  let columnsArbitri = [
    { label: 'Codice fiscale', sortable: true, sortingField: 'codiceFiscale' },
    { label: 'Cognome', sortable: true, sortingField: 'cognome' },
    { label: 'Nome', sortable: true, sortingField: 'nome' },
    { label: 'Domicilio', sortable: true, sortingField: 'cittaDomicilio' },
    { label: 'Stato', sortable: true, sortingField: 'status' },
  ]

  if (admin)
    columnsArbitri = [...columnsArbitri, { label: '', sortable: false }]

  // state to manage the delete dialog
  const [openDeleteArbitro, setOpenDeleteArbitro] = useState(false)
  const [deletingArbitro, setDeletingArbitro] = useState()

  const handleClickOpenDeleteArbitro = a => {
    setDeletingArbitro(a)
    setOpenDeleteArbitro(true)
  }

  const handleCloseDeleteArbitro = () => {
    setOpenDeleteArbitro(false)
  }

  const confirmDeleteArbitro = () => {
    deleteArbitro()
    handleCloseDeleteArbitro()
  }

  const editArbitro = async id => {
    if (!!id) {
      try {
        const arbitroData = await sendRequest(`arbitri/${id}`, 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!arbitroData || !arbitroData.data) {
          dispatch({
            type: SET_ERROR,
            payload: "Impossibile trovare l'arbitro.",
          })
        } else {
          dispatch({ type: RESET_ARBITRO })
          dispatch({ type: SET_MODE, payload: 'update' })
          dispatch({ type: SET_ARBITRO, payload: arbitroData.data })
          history.push('/arbitro')
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
  }

  const deleteArbitro = async () => {
    if (admin && deletingArbitro?.id) {
      if (deleteAlsoDesignazioni) {
        try {
          await sendRequest(
            `designazioni/arbitro/${deletingArbitro.id}`,
            'DELETE',
            null,
            { Authorization: codiceUtente }
          )
        } catch (err) {
          console.log(err)
          dispatch({ type: SET_ERROR, payload: err.message })
        }
      }

      try {
        await sendRequest(`arbitri/${deletingArbitro.id}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setDeletingArbitro()
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    }
  }

  return (
    <Grid container item direction='column' paddingY={2}>
      <Grid item>
        <center>
          <h2>Elenco arbitri</h2>
        </center>
      </Grid>
      &nbsp;
      <Grid container item>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={6}>
          {admin && (
            <center>
              <Link
                to='/arbitro'
                style={{ textDecoration: 'none' }}
                onClick={() => dispatch({ type: RESET_ARBITRO })}
              >
                <ColouredButton
                  textcolour={colours.white}
                  textbold='bold'
                  backgroundcolour={colours.blue}
                  hovercolour={colours.blueDark}
                >
                  Nuovo arbitro
                </ColouredButton>
              </Link>
            </center>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonRunDownload
            buttonText='Scarica Excel'
            algorithm='10_GenerateExcelArbitri'
            type='XLSX'
          />
        </Grid>
      </Grid>
      &nbsp;
      {(!!loadingArbitri && (
        <center>
          <CircularProgress disableShrink={true} />
        </center>
      )) ||
        (!arbitri && (
          <Grid item>
            <center>
              <h4>Elenco degli arbitri non trovato.</h4>
            </center>
          </Grid>
        )) ||
        (arbitri.length === 0 && (
          <Grid item>
            <center>
              <h4>Non è ancora stato creato nessun arbitro.</h4>
            </center>
          </Grid>
        )) || (
          <Grid container padding={3}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={arbitri.map(a => {
                    return { ...a, status: a.isActive ? a.status : 'inactive' }
                  })}
                  setTable={t => dispatch({ type: SET_ARBITRI, payload: t })}
                  columns={columnsArbitri}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {arbitri.map((a, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: colours.white,
                          ':hover': { backgroundColor: colours.greyVeryLight },
                        }}
                        onClick={() => editArbitro(a.id)}
                      >
                        <TableCell align='center'>{a.codiceFiscale}</TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {a.cognome}
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {a.nome}
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {a.cittaDomicilio}
                        </TableCell>
                        <TableCell align='center'>
                          <Chip
                            variant={
                              a.status === 'complete' || !a.isActive
                                ? 'outlined'
                                : 'filled'
                            }
                            label={
                              !a.isActive
                                ? 'inattivo'
                                : a.status === 'complete'
                                ? 'completo'
                                : a.status === 'incomplete'
                                ? 'da completare'
                                : 'sconosciuto'
                            }
                            color={
                              !a.isActive
                                ? 'error'
                                : a.status === 'complete'
                                ? 'success'
                                : a.status === 'incomplete'
                                ? 'warning'
                                : 'default'
                            }
                          />
                        </TableCell>
                        {admin && (
                          <TableCell align='center'>
                            <Grid container item>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.red }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    handleClickOpenDeleteArbitro(a)
                                  }}
                                >
                                  <Tooltip title='Elimina'>
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      <DialogMini
        open={openDeleteArbitro}
        handleClose={handleCloseDeleteArbitro}
        title={titles.titleDelete}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={confirmDeleteArbitro}
        colourBackground={colours.red}
        colourHover={colours.redDark}
        dialogDeleteArbitro={true}
        arbitro={deletingArbitro}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  dummyUpdate: state.home.dummyUpdate,
  loadingArbitri: state.home.loadingArbitri,
  arbitri: state.arbitri.arbitri,
  sortingColumn: state.arbitri.sortingColumn,
  sortingAscending: state.arbitri.sortingAscending,
  deleteAlsoDesignazioni: state.arbitri.deleteAlsoDesignazioni,
})

const ConnectedArbitri = connect(mapStateToProps)(Arbitri)

export default ConnectedArbitri
