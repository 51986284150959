export const RESET_RISULTATO = '@@risultato/RESET_RISULTATO'

export const SET_AVAILABLE_GARE = '@@risultato/SET_AVAILABLE_GARE'
export const SET_SELECTED_GARA = '@@risultato/SET_SELECTED_GARA'

export const SET_PARZIALI_CASA = '@@risultato/SET_PARZIALI_CASA'
export const SET_PARZIALI_TRASFERTA = '@@risultato/SET_PARZIALI_TRASFERTA'

export const SET_PENALITA_CASA = '@@risultato/SET_PENALITA_CASA'
export const SET_PENALITA_TRASFERTA = '@@risultato/SET_PENALITA_TRASFERTA'

export const SET_OMOLOGA = '@@risultato/SET_OMOLOGA'

export const SET_SET_VINTI_CASA_MINI = '@@risultato/SET_SET_VINTI_CASA_MINI'
export const SET_SET_VINTI_TRASFERTA_MINI =
  '@@risultato/SET_SET_VINTI_TRASFERTA_MINI'

export const SET_PARZIALE_GOLDEN_CASA = '@@risultato/SET_PARZIALE_GOLDEN_CASA'
export const SET_PARZIALE_GOLDEN_TRASFERTA =
  '@@risultato/SET_PARZIALE_GOLDEN_TRASFERTA'
