import { utilsFunctions } from './utils-functions'

const { fromStringToDate, setWinningScore } = utilsFunctions()

export const errorFunctions = () => {
  const errorMinLength = (x, L) => x.trim().split(' ').join('').length < L
  const errorMaxLength = (x, L) => x.trim().split(' ').join('').length > L

  const errorNumber = x => typeof x !== 'number'
  const errorNumber0 = x => errorNumber(x) || x < 0
  const errorIntero = x => errorNumber(x) || x !== Math.round(x)
  const errorNaturale = x => errorIntero(x) || x < 1
  const errorNaturale0 = x => errorIntero(x) || x < 0

  const errorTelefono = x => {
    x = x.trim().split(' ').join('')
    return x.length < 8 || ![...x].every(n => '+0123456789'.includes(n))
  }

  const errorMail = x => !/[A-z0-9.+_-]+@[A-z0-9._-]+\.[A-z]{2,6}/.test(x)

  const errorCodice = x =>
    !x ||
    ![...x].every(n => '0123456789'.includes(n)) ||
    x.trim().split(' ').join('').length < 2

  const errorCodiceFiscale = x =>
    !/^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i.test(
      x
    )

  const errorData = x =>
    errorMinLength(x, 10) ||
    errorMaxLength(x, 10) ||
    x?.split('/')?.length !== 3 ||
    !/^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
      x
    )

  const errorDataRange = (x, min = new Date(1900, 0, 1), max = new Date()) =>
    errorData(x) || fromStringToDate(x) < min || fromStringToDate(x) > max

  const errorCAP = x =>
    errorMinLength(x, 5) ||
    errorMaxLength(x, 5) ||
    errorNaturale0(+x) ||
    +x > 99999

  const errorPunteggioSet = (a, b, win = 25, diff = 2) =>
    errorNaturale0(a) ||
    errorNaturale0(b) ||
    !(
      (a === win && b <= win - diff) ||
      (b === win && a <= win - diff) ||
      (a > win && b === a - diff) ||
      (b > win && a === b - diff)
    )

  const errorPunteggioSetN = (
    a,
    b,
    n,
    setVintiCasa,
    setVintiTrasferta,
    isPropaganda,
    isPriFin2223
  ) =>
    !['', null, undefined].includes(a?.[n - 1]) &&
    !['', null, undefined].includes(b?.[n - 1]) &&
    (errorPunteggioSet(
      a?.[n - 1],
      b?.[n - 1],
      setWinningScore(n, isPropaganda, isPriFin2223)
    ) ||
      errorLastSet(
        a,
        b,
        n,
        setVintiCasa,
        setVintiTrasferta,
        isPropaganda,
        isPriFin2223
      ))

  const errorPunteggioGoldenSet = (a, b, isPropaganda, isPriFin2223) =>
    !['', null, undefined].includes(a) &&
    !['', null, undefined].includes(b) &&
    errorPunteggioSet(a, b, isPropaganda ? (isPriFin2223 ? 20 : 17) : 15)

  const errorPunteggioMini = (a, b, n = 1) =>
    !['', null, undefined].includes(a?.[n - 1]) &&
    !['', null, undefined].includes(b?.[n - 1]) &&
    (errorNaturale0(a?.[n - 1]) || errorNaturale0(b?.[n - 1]))

  const errorSetMini = (a, b) =>
    !['', null, undefined].includes(a) &&
    !['', null, undefined].includes(b) &&
    (errorNaturale0(a) || errorNaturale0(b))

  const errorLastSet = (
    a,
    b,
    n,
    setVintiCasa,
    setVintiTrasferta,
    isPropaganda,
    isPriFin2223
  ) => {
    if (!a || !b || a.length !== b.length || n !== a.length) return false

    if (isPropaganda && !isPriFin2223) return false

    const N = a.length - 1
    if (
      (setVintiCasa > setVintiTrasferta && a[N] <= b[N]) ||
      (setVintiCasa < setVintiTrasferta && a[N] >= b[N])
    )
      return true
    return false
  }

  return {
    errorMinLength,
    errorMaxLength,
    errorNumber,
    errorNumber0,
    errorIntero,
    errorNaturale,
    errorNaturale0,
    errorTelefono,
    errorMail,
    errorCodice,
    errorCodiceFiscale,
    errorData,
    errorDataRange,
    errorCAP,
    errorPunteggioSet,
    errorPunteggioSetN,
    errorPunteggioGoldenSet,
    errorPunteggioMini,
    errorSetMini,
    errorLastSet,
  }
}
