import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'

import {
  SET_SANZIONE_REFERTO,
  SET_SANZIONE_MAIL,
} from '../container/calendari/types'

const DialogSanzioneReferto = ({
  gara,
  iscrizioniLight,
  sanzioneReferto,
  sanzioneMail,
}) => {
  const dispatch = useDispatch()

  const [squadraCasaName, setSquadraCasaName] = useState()

  // Set team names
  useEffect(() => {
    if (gara?.squadraCasaID)
      setSquadraCasaName(
        iscrizioniLight?.filter(i => i.id === gara.squadraCasaID)?.[0]
          ?.nomeSquadra || gara.squadraCasaID
      )
  }, [gara, iscrizioniLight])

  // Set sanzione referto
  useEffect(() => {
    dispatch({
      type: SET_SANZIONE_REFERTO,
      payload: gara?.squadraCasaSanzionataPerReferto,
    })
  }, [dispatch, gara])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => {
      dispatch({ type: SET_SANZIONE_REFERTO, payload: undefined })
      dispatch({ type: SET_SANZIONE_MAIL, payload: undefined })
    }
  }, [dispatch])

  return (
    <>
      &nbsp;
      {((!gara || gara.length === 0) && (
        <Grid item xs={12}>
          Nessuna partita selezionata.
        </Grid>
      )) || (
        <Grid container spacing={3} direction='column'>
          <Grid item xs={12}>
            Il presente modulo permette di sanzionare la squadra di casa per non
            aver inviato il referto della gara nei tempi consentiti (20 giorni).
            <br />
            La casella "sanziona squadra di casa" fa colorare di verde la "€"
            presente nell'elenco dei referti inviati in ritardo, a segnalare che
            la squadra è stata sanzionata.
            <br />
            La casella "invia mail con sanzione" fa partire una mail che avvisa
            la società del provvedimento disciplinare.
          </Grid>
          &nbsp;
          {!!gara && (
            <Grid container item xs={12} spacing={3} alignItems='center'>
              <Grid item xs={12} md={4}>
                <center>
                  Gara numero: <b>{gara.numeroGara}</b>
                </center>
              </Grid>
              <Grid item xs={12} md={8}>
                <center>
                  Squadra di casa: <b>{squadraCasaName}</b>
                </center>
              </Grid>
            </Grid>
          )}
          &nbsp;
          {!!gara && (
            <Grid
              container
              item
              xs={12}
              spacing={3}
              alignItems='center'
              justifyContent='center'
            >
              <Grid item xs={4}>
                <center>Sanziona squadra di casa:</center>
              </Grid>
              <Grid item xs={2}>
                <Checkbox
                  id='sanzione-referto'
                  checked={!!sanzioneReferto}
                  onChange={event =>
                    dispatch({
                      type: SET_SANZIONE_REFERTO,
                      payload: event.target.checked,
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <center>Invia mail con sanzione:</center>
              </Grid>
              <Grid item xs={2}>
                <Checkbox
                  id='sanzione-mail'
                  checked={!!sanzioneMail}
                  onChange={event =>
                    dispatch({
                      type: SET_SANZIONE_MAIL,
                      payload: event.target.checked,
                    })
                  }
                />
              </Grid>
            </Grid>
          )}
          &nbsp;
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  iscrizioniLight: state.home.iscrizioniLight,
  sanzioneReferto: state.calendari.sanzioneReferto,
  sanzioneMail: state.calendari.sanzioneMail,
})

const ConnectedDialogSanzioneReferto = connect(mapStateToProps)(
  DialogSanzioneReferto
)

export default ConnectedDialogSanzioneReferto
