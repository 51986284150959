import {
  RESET_GIRONI,
  SET_NEW_TEAM,
  SET_NEW_GIRONE,
  SET_NEW_POSIZIONE,
  SET_NEW_GIRONE_ADD,
  SET_NEW_POSIZIONE_ADD,
  SET_SELECTED_FUNCTION,
  SET_ISCRIZIONI_GIRONI,
} from './types'

const INIT_STATE = {
  availableFunctions: ['sposta', 'scambia', 'metti', 'togli'],
}

export const gironiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_GIRONI:
      return {
        ...INIT_STATE,
        selectedFunction: state.selectedFunction,
        iscrizioniGironi: state.iscrizioniGironi,
      }
    case SET_NEW_TEAM:
      return { ...state, newTeam: action.payload }
    case SET_NEW_GIRONE:
      return { ...state, newGirone: action.payload }
    case SET_NEW_POSIZIONE:
      return { ...state, newPosizione: action.payload }
    case SET_NEW_GIRONE_ADD:
      return { ...state, newGironeAdd: action.payload }
    case SET_NEW_POSIZIONE_ADD:
      return { ...state, newPosizioneAdd: action.payload }
    case SET_SELECTED_FUNCTION:
      return { ...state, selectedFunction: action.payload }
    case SET_ISCRIZIONI_GIRONI:
      return { ...state, iscrizioniGironi: action.payload }
    default:
      return state
  }
}
