import {
  SET_CALENDARI,
  SET_NEW_DATA,
  SET_NEW_ORA,
  SET_SWAP_TEAMS,
  SET_NEEDS_REFEREE,
  SET_SANZIONE_REFERTO,
  SET_SANZIONE_MAIL,
  SET_RIPESCAGGIO_ID,
  SET_SELECTED_FUNCTION,
  SET_SELECTED_GARE,
  SET_PRESSO,
  SET_INDIRIZZO,
  SET_CITTA,
  RESET_INDIRIZZO,
  SET_GARA_INDIRIZZO,
  SET_ISCRIZIONE_INDIRIZZO,
} from './types'

const INIT_STATE = {
  presso: '',
  citta: '',
  indirizzo: '',
}

export const calendariReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CALENDARI:
      return { ...state, calendari: action.payload }
    case SET_NEW_DATA:
      return { ...state, newData: action.payload }
    case SET_NEW_ORA:
      return { ...state, newOra: action.payload }
    case SET_SWAP_TEAMS:
      return { ...state, swapTeams: action.payload }
    case SET_NEEDS_REFEREE:
      return { ...state, needsReferee: action.payload }
    case SET_SANZIONE_REFERTO:
      return { ...state, sanzioneReferto: action.payload }
    case SET_SANZIONE_MAIL:
      return { ...state, sanzioneMail: action.payload }
    case SET_RIPESCAGGIO_ID:
      return { ...state, ripescaggioID: action.payload }
    case SET_SELECTED_FUNCTION:
      return { ...state, selectedFunction: action.payload }
    case SET_SELECTED_GARE:
      return { ...state, selectedGare: action.payload }
    case SET_PRESSO:
      return { ...state, presso: action.payload }
    case SET_INDIRIZZO:
      return { ...state, indirizzo: action.payload }
    case SET_CITTA:
      return { ...state, citta: action.payload }
    case RESET_INDIRIZZO:
      return {
        ...state,
        selectedGare: INIT_STATE.selectedGare,
        presso: INIT_STATE.presso,
        indirizzo: INIT_STATE.indirizzo,
        citta: INIT_STATE.citta,
      }
    case SET_GARA_INDIRIZZO:
      return { ...state, garaIndirizzo: action.payload }
    case SET_ISCRIZIONE_INDIRIZZO:
      return { ...state, iscrizioneIndirizzo: action.payload }
    default:
      return state
  }
}
