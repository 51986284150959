import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { ColouredButton } from './Buttons'
import { sendRequest } from '../hooks/http-hook'
import { colours } from '../settings/settings'
import { SET_BACKEND_R, SET_ERROR } from '../container/home/types'

const ButtonRun = ({
  buttonText,
  algorithm,
  codiceUtente,
  backendR,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
}) => {
  const dispatch = useDispatch()

  const isDisabled = backendR?.status !== 'available'

  const lastTime = backendR?.[`${algorithm}-lastTime`]

  const runAlgorithm = async algorithm => {
    if (!algorithm) {
      dispatch({ type: SET_ERROR, payload: 'Algoritmo non definito!' })
    } else {
      try {
        await sendRequest(
          `algoritmi/run/${algorithm}${
            selectedCampionato ? `/${selectedCampionato}` : ''
          }${
            algorithm === '01_GenerateTable' &&
            selectedCategoria &&
            selectedCategoria !== availableCategorie[0]
              ? `/${selectedCategoria}`
              : ''
          }`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )
        dispatch({
          type: SET_BACKEND_R,
          payload: { ...backendR, status: 'busy', process: algorithm },
        })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
  }

  return (
    <center>
      <ColouredButton
        textcolour={colours.white}
        textbold='bold'
        backgroundcolour={isDisabled ? colours.greyLight : colours.blue}
        hovercolour={colours.blueDark}
        disabled={isDisabled}
        onClick={() => runAlgorithm(algorithm)}
      >
        {buttonText}
      </ColouredButton>
      <p style={{ fontSize: 16 }}>Ultima esecuzione:&nbsp;{lastTime || '?'}</p>
    </center>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  backendR: state.home.backendR,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
})

const ConnectedButtonRun = connect(mapStateToProps)(ButtonRun)

export default ConnectedButtonRun
