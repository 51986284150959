import {
  SET_ISCRIZIONI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_GROUPING,
  SET_DELETE_ALSO_GARE,
} from './types'

const INIT_STATE = {
  sortingAscending: true,
}

export const iscrizioniReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ISCRIZIONI:
      return { ...state, iscrizioni: action.payload }
    case SET_SORTING_COLUMN:
      return { ...state, sortingColumn: action.payload }
    case SET_SORTING_ASCENDING:
      return { ...state, sortingAscending: action.payload }
    case SET_GROUPING:
      return { ...state, grouping: action.payload }
    case SET_DELETE_ALSO_GARE:
      return { ...state, deleteAlsoGare: action.payload }
    default:
      return state
  }
}
