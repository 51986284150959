export const SET_CALENDARI = '@@home/SET_CALENDARI'

export const SET_NEW_DATA = '@@calendari/SET_NEW_DATA'
export const SET_NEW_ORA = '@@calendari/SET_NEW_ORA'
export const SET_SWAP_TEAMS = '@@calendari/SET_SWAP_TEAMS'
export const SET_NEEDS_REFEREE = '@@calendari/SET_NEEDS_REFEREE'
export const SET_SANZIONE_REFERTO = '@@calendari/SET_SANZIONE_REFERTO'
export const SET_SANZIONE_MAIL = '@@calendari/SET_SANZIONE_MAIL'
export const SET_RIPESCAGGIO_ID = '@@calendari/SET_RIPESCAGGIO_ID'

export const SET_SELECTED_FUNCTION = '@@calendari/SET_SELECTED_FUNCTION'

export const SET_SELECTED_GARE = '@@calendari/SET_SELECTED_GARE'
export const SET_PRESSO = '@@calendari/SET_PRESSO'
export const SET_INDIRIZZO = '@@calendari/SET_INDIRIZZO'
export const SET_CITTA = '@@calendari/SET_CITTA'
export const RESET_INDIRIZZO = '@@calendari/RESET_INDIRIZZO'

export const SET_GARA_INDIRIZZO = '@@calendari/SET_GARA_INDIRIZZO'
export const SET_ISCRIZIONE_INDIRIZZO = '@@calendari/SET_ISCRIZIONE_INDIRIZZO'
