import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'

import ButtonGroup from './ButtonGroup'
import SortableTableHead from './SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { sortFunctions } from '../hooks/sort-functions'
import { SET_ERROR } from '../container/home/types'
import {
  SET_SELECTED_GARE,
  SET_CHECK_PAYMENT,
} from '../container/arbitraggi/types'

let columnsGareTeam = [
  { label: 'Selezione', sortable: false },
  { label: 'Gara', sortable: false },
  { label: 'Data e Ora', sortable: false },
  { label: 'Avversario', sortable: false },
]

const DialogRichiestaArbitraggio = ({
  codiceUtente,
  iscrizioniLight,
  selectedCampionato,
  iscrizioni,
  selectedGare,
  checkPayment,
}) => {
  const dispatch = useDispatch()

  const [availableCategorie, setAvailableCategorie] = useState()
  const [availableSquadre, setAvailableSquadre] = useState()
  const [availableGare, setAvailableGare] = useState()
  const [availableGareTeam, setAvailableGareTeam] = useState()
  const [selectedCategoria, setSelectedCategoria] = useState()
  const [selectedSquadra, setSelectedSquadra] = useState()

  // sort functions
  const { sortNumeriGara } = sortFunctions()
  const sortNumeriGaraM = useCallback(sortNumeriGara, [])

  // Set categorie
  useEffect(() => {
    setAvailableCategorie(
      availableGare
        ?.map(i => i.categoria)
        ?.filter((v, i, s) => s.indexOf(v) === i)
        ?.sort()
    )
  }, [availableGare])

  // Set categoria
  useEffect(() => {
    setSelectedCategoria(
      availableCategorie?.length === 1 ? availableCategorie[0] : undefined
    )
  }, [availableCategorie])

  // Set squadre
  useEffect(() => {
    setAvailableSquadre(
      iscrizioniLight
        ?.filter(
          i =>
            availableGare?.map(a => a.squadraCasaID)?.includes(i.id) &&
            i.categoria === selectedCategoria
        )
        ?.map(i => i.id)
    )
  }, [availableGare, iscrizioniLight, selectedCategoria])

  // Set squadra
  useEffect(() => {
    setSelectedSquadra(
      availableSquadre?.length === 1 ? availableSquadre[0] : undefined
    )
  }, [availableSquadre])

  // Set gare
  useEffect(() => {
    setAvailableGareTeam(
      availableGare
        ?.filter(
          a =>
            a.squadraCasaID === selectedSquadra ||
            a.squadraTrasfertaID === selectedSquadra
        )
        ?.sort((a, b) => sortNumeriGaraM(a.numeroGara, b.numeroGara))
    )
  }, [availableGare, selectedSquadra, sortNumeriGaraM])

  // Reset inputs
  useEffect(() => {
    dispatch({ type: SET_SELECTED_GARE })
    dispatch({ type: SET_CHECK_PAYMENT })
  }, [dispatch, selectedSquadra])

  // fetch available gare arbitraggio
  useEffect(() => {
    const fetchAvailableGareArbitraggio = async () => {
      try {
        const data = await sendRequest(
          `calendari/campionato/${selectedCampionato}/societa/${codiceUtente}/arbitraggio`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data || !data.data) {
          dispatch({
            type: SET_ERROR,
            payload:
              "Impossibile trovare le partite di cui chiedere l'arbitraggio.",
          })
        } else {
          setAvailableGare(data.data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (selectedCampionato && codiceUtente) {
      fetchAvailableGareArbitraggio()
    } else {
      setAvailableGare()
    }
  }, [codiceUtente, dispatch, selectedCampionato])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => {
      dispatch({ type: SET_SELECTED_GARE })
      dispatch({ type: SET_CHECK_PAYMENT })
    }
  }, [dispatch])

  return (
    <>
      &nbsp;
      {((!iscrizioni || iscrizioni.length === 0) && (
        <Grid item xs={12}>
          Nessuna squadra iscritta a questo campionato.
        </Grid>
      )) || (
        <Grid container spacing={3} direction='column'>
          <Grid item xs={12}>
            Il presente modulo permette di richiedere l'arbitraggio di una o più
            gare. Selezionarle dalla tabella sottostante, dopo aver scelto la
            categoria e la squadra, poi spuntare la casella che appare in basso
            per confermare l'impegno a versare la tassa per l'arbitraggio.
            <br />
            <br />
            <b>
              La richiesta va inoltrata almeno 3 giorni prima dell'orario di
              inizio gara e può essere effettuata solo dalla squadra di casa.
            </b>
            <br />
            <br />
            Per domande o chiarimenti scrivere a{' '}
            <a href='mailto:pgsmilano@libero.it'>pgsmilano@libero.it</a>.
          </Grid>
          {!availableGare?.length ? (
            <Grid item xs={12}>
              <center>
                <b>
                  <em>Nessuna gara idonea.</em>
                </b>
              </center>
            </Grid>
          ) : (
            <>
              <Grid container item alignItems='center'>
                <Grid item xs={5} md={3}>
                  <center>
                    <b>Categoria</b>
                  </center>
                </Grid>
                <Grid item xs={7} md={9}>
                  {availableCategorie?.length > 0 ? (
                    <ButtonGroup
                      list={availableCategorie}
                      value={selectedCategoria}
                      func={setSelectedCategoria}
                    />
                  ) : (
                    'Nessuna categoria trovata.'
                  )}
                </Grid>
              </Grid>
              {!selectedCategoria ? (
                <Grid item>
                  <center>
                    <b>
                      <em>Selezionare una categoria.</em>
                    </b>
                  </center>
                </Grid>
              ) : (
                <Grid container item alignItems='center'>
                  <Grid item xs={5} md={3}>
                    <center>
                      <b>Squadra</b>
                    </center>
                  </Grid>
                  <Grid item xs={7} md={9}>
                    {availableSquadre?.length > 0 ? (
                      <ButtonGroup
                        list={availableSquadre}
                        labels={index =>
                          iscrizioniLight?.find(
                            i => i.id === availableSquadre[index]
                          )?.nomeSquadra
                        }
                        value={selectedSquadra}
                        func={setSelectedSquadra}
                      />
                    ) : (
                      'Nessuna squadra trovata.'
                    )}
                  </Grid>
                  &nbsp;
                  {availableGareTeam?.length > 0 && (
                    <TableContainer component={Paper}>
                      <Table size='small'>
                        <SortableTableHead
                          table={availableGareTeam}
                          columns={columnsGareTeam}
                        />
                        <TableBody>
                          {availableGareTeam?.map((a, index) => {
                            const isSelected = selectedGare?.includes(a._id)

                            const cellProps = { align: 'center' }

                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  backgroundColor: isSelected
                                    ? colours.highlightGreen
                                    : colours.white,
                                }}
                              >
                                <TableCell {...cellProps}>
                                  <Checkbox
                                    id={`${a._id}-checkbox`}
                                    color='success'
                                    checked={!!isSelected}
                                    onChange={() =>
                                      dispatch({
                                        type: SET_SELECTED_GARE,
                                        payload: isSelected
                                          ? selectedGare?.filter(
                                              g => g !== a._id
                                            )
                                          : !selectedGare
                                          ? [a._id]
                                          : [...selectedGare, a._id],
                                      })
                                    }
                                  />
                                </TableCell>
                                <TableCell {...cellProps}>
                                  {a?.numeroGara}
                                </TableCell>
                                <TableCell {...cellProps}>
                                  {a?.data}&nbsp;{a?.ora}
                                </TableCell>
                                <TableCell {...cellProps}>
                                  {
                                    iscrizioniLight?.find(
                                      i => i.id === a.squadraTrasfertaID
                                    )?.nomeSquadra
                                  }
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              )}
              {selectedGare?.length > 0 && (
                <Grid container item alignItems='center'>
                  <Grid item xs={2} md={1}>
                    <center>
                      <Checkbox
                        id='check-payment'
                        checked={!!checkPayment}
                        onChange={event => {
                          dispatch({
                            type: SET_CHECK_PAYMENT,
                            payload: event.target.checked,
                          })
                        }}
                      />
                    </center>
                  </Grid>
                  <Grid item xs={10} md={11}>
                    <b>
                      La società si impegna a versare la tassa per
                      l'arbitraggio.
                    </b>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  iscrizioniLight: state.home.iscrizioniLight,
  selectedCampionato: state.home.selectedCampionato,
  iscrizioni: state.iscrizioni.iscrizioni,
  selectedGare: state.arbitraggi.selectedGare,
  checkPayment: state.arbitraggi.checkPayment,
})

const ConnectedDialogRichiestaArbitraggio = connect(mapStateToProps)(
  DialogRichiestaArbitraggio
)

export default ConnectedDialogRichiestaArbitraggio
