import {
  RESET_DESIGNAZIONE,
  SET_DESIGNAZIONE,
  SET_MODE,
  SET_SELECTED_GARA,
  SET_SELECTED_ARBITRO_1,
  SET_SELECTED_ARBITRO_2,
  SET_CHECK_ACCEPT,
  SET_CHECK_REJECT,
} from './types'

const INIT_STATE = {
  mode: 'view',
}

export const designazioneReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_DESIGNAZIONE:
      return INIT_STATE
    case SET_DESIGNAZIONE:
      return { ...state, designazione: action.payload }
    case SET_MODE:
      return { ...state, mode: action.payload }
    case SET_SELECTED_GARA:
      return { ...state, selectedGara: action.payload }
    case SET_SELECTED_ARBITRO_1:
      return { ...state, selectedArbitro1: action.payload }
    case SET_SELECTED_ARBITRO_2:
      return { ...state, selectedArbitro2: action.payload }
    case SET_CHECK_ACCEPT:
      return { ...state, checkAccept: action.payload }
    case SET_CHECK_REJECT:
      return { ...state, checkReject: action.payload }
    default:
      return state
  }
}
