import { env } from '../env'

const BACKEND = `${env.api.endpoint.protocol}://${env.api.endpoint.host}:${env.api.endpoint.port}/api`

export const sendRequestBlob = async (
  url,
  method = 'GET',
  body = null,
  headers = {}
) => {
  try {
    const response = await fetch(`${BACKEND}/${url}`, { method, body, headers })

    const responseData = await response.blob()

    if (!response.ok) throw new Error(responseData.message)

    return responseData
  } catch (err) {
    console.log(err.message)
    throw err
  }
}
