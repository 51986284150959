import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'

import Select from './Select'
import InputField from './InputField'
import ButtonGroup from './ButtonGroup'
import SortableTableHead from './SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { errorFunctions } from '../hooks/error-functions'
import { sortFunctions } from '../hooks/sort-functions'
import { utilsFunctions } from '../hooks/utils-functions'
import {
  SET_IMPOSTAZIONI,
  SET_ACCOPPIAMENTI,
  SET_ERROR,
  SET_DUMMY_UPDATE,
} from '../container/home/types'

const columnsAccoppiamenti = [
  { label: 'Giornata', sortable: false },
  { label: 'Casa', sortable: false },
  { label: 'Trasferta', sortable: false },
  { label: 'Opzioni', sortable: false },
]

const availableGironiDa = [4, 6, 8, 10]
const availableLegsNames = ['Andata', 'Andata/Ritorno', 'Andata/Ritorno/Andata']
const availableLegsNumbers = [1, 2, 3]

const DialogAccoppiamenti = ({
  codiceUtente,
  impostazioni,
  accoppiamenti,
  dummyUpdate,
}) => {
  const dispatch = useDispatch()

  const [selectedGironiDa, setSelectedGironiDa] = useState()

  // states to manage new accoppiamenti addition/update
  const [editingAccoppiamento, setEditingAccoppiamento] = useState(false)
  const [addingAccoppiamento, setAddingAccoppiamento] = useState(false)
  const [newAccoppiamento, setNewAccoppiamento] = useState()
  const [newGiornata, setNewGiornata] = useState()
  const [newSquadraCasa, setNewSquadraCasa] = useState()
  const [newSquadraTrasferta, setNewSquadraTrasferta] = useState()

  // error functions
  const { errorNaturale } = errorFunctions()

  // sort functions
  const { sortGiornate } = sortFunctions()
  const sortGiornateM = useCallback(sortGiornate, [])

  // utils functions
  const { rangeFun } = utilsFunctions()

  useEffect(() => {
    if (!selectedGironiDa) setSelectedGironiDa(availableGironiDa[0])
  }, [selectedGironiDa])

  // fetch accoppiamenti
  useEffect(() => {
    const fetchAccoppiamenti = async () => {
      try {
        const accoppiamentiData = await sendRequest(
          `accoppiamenti/all`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!accoppiamentiData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare gli accoppiamenti.',
          })
        } else {
          dispatch({
            type: SET_ACCOPPIAMENTI,
            payload: accoppiamentiData
              ?.filter(a => a.gironiDa === selectedGironiDa)
              ?.sort((a, b) =>
                a.gironiDa === b.gironiDa
                  ? sortGiornateM(a.giornata, b.giornata)
                  : a.gironiDa > b.gironiDa
                  ? 1
                  : -1
              ),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    fetchAccoppiamenti()
  }, [codiceUtente, dispatch, dummyUpdate, selectedGironiDa, sortGiornateM])

  // add accoppiamento
  const addNewAccoppiamento = async () => {
    if (!!selectedGironiDa && !!newGiornata) {
      try {
        await sendRequest(
          `accoppiamenti/add`,
          'POST',
          JSON.stringify({
            gironiDa: selectedGironiDa,
            giornata: newGiornata,
            casa: newSquadraCasa,
            trasferta: newSquadraTrasferta,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        dispatch({
          type: SET_ERROR,
          payload: 'Accoppiamento aggiunto correttamente!',
        })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      clearNewAccoppiamento()
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          "Impossibile aggiungere l'accoppiamento: informazioni mancanti!",
      })
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // clear accoppiamento
  const clearNewAccoppiamento = () => {
    setEditingAccoppiamento(false)
    setAddingAccoppiamento(false)
    setNewAccoppiamento()
    setNewGiornata()
    setNewSquadraCasa()
    setNewSquadraTrasferta()
  }

  // edit accoppiamento
  const editAccoppiamento = async id => {
    if (!!id) {
      try {
        await sendRequest(
          `accoppiamenti/${id}`,
          'POST',
          JSON.stringify({
            gironiDa: selectedGironiDa,
            giornata: newGiornata,
            casa: newSquadraCasa,
            trasferta: newSquadraTrasferta,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      clearNewAccoppiamento()
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          "Impossibile aggiornare l'accoppiamento: accoppiamento non trovato!",
      })
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // delete accoppiamento
  const deleteAccoppiamento = async id => {
    if (!!id) {
      try {
        await sendRequest(`accoppiamenti/${id}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          "Impossibile eliminare l'accoppiamento: accoppiamento non trovato!",
      })
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // update impostazioni legs
  const updateImpostazioniLegs = async (gironiDa, legs) => {
    if (!!gironiDa && !!legs) {
      try {
        await sendRequest(
          `impostazioni/update`,
          'PUT',
          JSON.stringify({ ...impostazioni, [`Legs${gironiDa}`]: legs }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        dispatch({
          type: SET_IMPOSTAZIONI,
          payload: { ...impostazioni, [`Legs${gironiDa}`]: legs },
        })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          'Impossibile aggiornare le impostazioni: informazioni mancanti!',
      })
    }
  }

  return (
    <>
      &nbsp;
      {(!accoppiamenti && <h4>Accoppiamenti non trovati.</h4>) || (
        <>
          <p style={{ paddingLeft: 20 }}>
            Un accoppiamento è una partita tra due squadre, identificate dalla
            posizione che occupano nel girone, che avviene in una determinata
            giornata di gioco. Gli accoppiamenti possono essere definiti per
            gironi da 4, 6, 8 e 10 squadre.
            <br />
            <br />
            Gli accoppiamenti per gironi da 3, 5, 7 e 9 vengono presi da quelli
            dei gironi da 4, 6, 8 e 10 squadre eliminando le gare in eccesso.
            Per esempio, se nella prima giornata di un girone a 4 squadre (con
            posizioni 1-2-3-4) sono definiti gli abbinamenti 1-2 e 3-4, nel
            girone a 3 squadre (con posizioni 1-2-3) verrà preso solo
            l'abbinamento 1-2.
          </p>
          &nbsp;
          <Grid container item xs={12} spacing={3} alignItems='center'>
            <Grid item xs={6} md={3}>
              <div style={{ textAlign: 'right' }}>Numero di squadre</div>
            </Grid>
            <Grid item xs={6} md={9}>
              <ButtonGroup
                list={availableGironiDa}
                value={selectedGironiDa}
                func={setSelectedGironiDa}
              />
            </Grid>
          </Grid>
          &nbsp;
          <Grid container item xs={12} spacing={3} alignItems='center'>
            <Grid item xs={6} md={3}>
              <div style={{ textAlign: 'right' }}>Fasi previste</div>
            </Grid>
            <Grid item xs={6} md={9}>
              <ButtonGroup
                list={availableLegsNumbers}
                labels={index => availableLegsNames[index]}
                value={impostazioni[`Legs${selectedGironiDa}`]}
                func={i => updateImpostazioniLegs(selectedGironiDa, i)}
              />
            </Grid>
          </Grid>
          &nbsp;
          <Grid container item xs={12} spacing={3} alignItems='center'>
            {(!!addingAccoppiamento && (
              <>
                <Grid item xs={4} md={2}>
                  <center>
                    <InputField
                      id='giornata'
                      value={newGiornata}
                      onChange={setNewGiornata}
                      autoFocus={true}
                      label={columnsAccoppiamenti[0].label}
                      errorText='Il numero della giornata deve essere un intero positivo!'
                      errorFunc={errorNaturale}
                      type='number'
                    />
                  </center>
                </Grid>
                <Grid item xs={8} md={4}>
                  <center>
                    <Select
                      id='squadraCasa'
                      value={newSquadraCasa}
                      options={rangeFun(selectedGironiDa).filter(
                        i => i !== newSquadraTrasferta
                      )}
                      label={columnsAccoppiamenti[1].label}
                      onChange={setNewSquadraCasa}
                    />
                  </center>
                </Grid>
                <Grid item xs={8} md={4}>
                  <center>
                    <Select
                      id='squadraTrasferta'
                      value={newSquadraTrasferta}
                      options={rangeFun(selectedGironiDa).filter(
                        i => i !== newSquadraCasa
                      )}
                      label={columnsAccoppiamenti[2].label}
                      onChange={setNewSquadraTrasferta}
                    />
                  </center>
                </Grid>
                <Grid item xs={2} md={1}>
                  <center>
                    <IconButton
                      style={{ color: colours.blueDark }}
                      onClick={addNewAccoppiamento}
                    >
                      <Tooltip key='Salva' title='Salva'>
                        <SaveIcon />
                      </Tooltip>
                    </IconButton>
                  </center>
                </Grid>
                <Grid item xs={2} md={1}>
                  <center>
                    <IconButton
                      style={{ color: colours.red }}
                      onClick={clearNewAccoppiamento}
                    >
                      <Tooltip key='Annulla' title='Annulla'>
                        <ClearIcon />
                      </Tooltip>
                    </IconButton>
                  </center>
                </Grid>
              </>
            )) || (
              <Grid item xs={12}>
                <center>
                  <IconButton
                    style={{ color: colours.black }}
                    onClick={() => {
                      setEditingAccoppiamento(false)
                      setAddingAccoppiamento(true)
                      setNewAccoppiamento()
                      setNewGiornata()
                      setNewSquadraCasa()
                      setNewSquadraTrasferta()
                    }}
                  >
                    <AddIcon />
                    &nbsp; Nuovo accoppiamento
                  </IconButton>
                </center>
              </Grid>
            )}
          </Grid>
          &nbsp;
          {(accoppiamenti.length === 0 && (
            <h4>Nessun accoppiamento trovato.</h4>
          )) || (
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={accoppiamenti}
                  setTable={t =>
                    dispatch({ type: SET_ACCOPPIAMENTI, payload: t })
                  }
                  columns={columnsAccoppiamenti}
                />
                <TableBody>
                  {impostazioni[`Legs${selectedGironiDa}`] >= 1 && // Andata (Legs: 1, 2, 3)
                    accoppiamenti.map((a, index) => {
                      return (
                        <TableRow
                          key={index}
                          style={{ backgroundColor: colours.white }}
                        >
                          {(!!editingAccoppiamento &&
                            newAccoppiamento === a.id && (
                              <>
                                <TableCell align='center' padding='checkbox'>
                                  <InputField
                                    id='giornata'
                                    value={newGiornata}
                                    onChange={setNewGiornata}
                                    autoFocus={true}
                                    label={columnsAccoppiamenti[0].label}
                                    errorText='Il numero della giornata deve essere un intero positivo!'
                                    errorFunc={errorNaturale}
                                    type='number'
                                    mode='update'
                                  />
                                </TableCell>
                                <TableCell align='center' padding='checkbox'>
                                  <Select
                                    id='squadraCasa'
                                    value={newSquadraCasa}
                                    options={rangeFun(selectedGironiDa).filter(
                                      i => i !== newSquadraTrasferta
                                    )}
                                    label={columnsAccoppiamenti[1].label}
                                    onChange={setNewSquadraCasa}
                                    mode='update'
                                  />
                                </TableCell>
                                <TableCell align='center' padding='checkbox'>
                                  <Select
                                    id='squadraTrasferta'
                                    value={newSquadraTrasferta}
                                    options={rangeFun(selectedGironiDa).filter(
                                      i => i !== newSquadraCasa
                                    )}
                                    label={columnsAccoppiamenti[2].label}
                                    onChange={setNewSquadraTrasferta}
                                    mode='update'
                                  />
                                </TableCell>
                                <TableCell align='center' padding='checkbox'>
                                  <Grid container item>
                                    <Grid item xs>
                                      <IconButton
                                        style={{ color: colours.blueDark }}
                                        onClick={() => editAccoppiamento(a.id)}
                                      >
                                        <Tooltip key='Salva' title='Salva'>
                                          <SaveIcon />
                                        </Tooltip>
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs>
                                      <IconButton
                                        style={{ color: colours.red }}
                                        onClick={clearNewAccoppiamento}
                                      >
                                        <Tooltip key='Annulla' title='Annulla'>
                                          <ClearIcon />
                                        </Tooltip>
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </>
                            )) || (
                            <>
                              <TableCell align='center'>{a.giornata}</TableCell>
                              <TableCell align='center'>{a.casa}</TableCell>
                              <TableCell align='center'>
                                {a.trasferta}
                              </TableCell>
                              <TableCell align='center'>
                                <Grid container item>
                                  <Grid item xs>
                                    <IconButton
                                      style={{ color: colours.blueDark }}
                                      onClick={() => {
                                        setEditingAccoppiamento(true)
                                        setAddingAccoppiamento(false)
                                        setNewAccoppiamento(a.id)
                                        setNewGiornata(a.giornata)
                                        setNewSquadraCasa(a.casa)
                                        setNewSquadraTrasferta(a.trasferta)
                                      }}
                                    >
                                      <Tooltip key='Modifica' title='Modifica'>
                                        <EditIcon />
                                      </Tooltip>
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs>
                                    <IconButton
                                      style={{ color: colours.red }}
                                      onClick={() => deleteAccoppiamento(a.id)}
                                    >
                                      <Tooltip key='Elimina' title='Elimina'>
                                        <DeleteIcon />
                                      </Tooltip>
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      )
                    })}
                  {impostazioni[`Legs${selectedGironiDa}`] >= 2 && // Ritorno (Legs: 2, 3)
                    accoppiamenti.map((a, index) => {
                      return (
                        <TableRow
                          key={index}
                          style={{ backgroundColor: colours.white }}
                          sx={{ borderTop: index === 0 ? '2px solid' : '' }}
                        >
                          <TableCell align='center'>
                            {selectedGironiDa -
                              (selectedGironiDa % 2 === 0 ? 1 : 0) +
                              a.giornata}
                          </TableCell>
                          <TableCell align='center'>{a.trasferta}</TableCell>
                          <TableCell align='center'>{a.casa}</TableCell>
                          <TableCell align='center'>
                            <Grid container item>
                              <Grid item xs>
                                <IconButton
                                  disabled={true}
                                  style={{
                                    color: 'transparent',
                                    backgroundColor: 'transparent',
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {impostazioni[`Legs${selectedGironiDa}`] >= 3 && // Andata (Legs: 2, 3)
                    accoppiamenti.map((a, index) => {
                      return (
                        <TableRow
                          key={index}
                          style={{ backgroundColor: colours.white }}
                          sx={{ borderTop: index === 0 ? '2px solid' : '' }}
                        >
                          <TableCell align='center'>
                            {2 *
                              (selectedGironiDa -
                                (selectedGironiDa % 2 === 0 ? 1 : 0)) +
                              a.giornata}
                          </TableCell>
                          <TableCell align='center'>{a.casa}</TableCell>
                          <TableCell align='center'>{a.trasferta}</TableCell>
                          <TableCell align='center'>
                            <Grid container item>
                              <Grid item xs>
                                <IconButton
                                  disabled={true}
                                  style={{
                                    color: 'transparent',
                                    backgroundColor: 'transparent',
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  impostazioni: state.home.impostazioni,
  accoppiamenti: state.home.accoppiamenti,
  dummyUpdate: state.home.dummyUpdate,
})

const ConnectedDialogAccoppiamenti =
  connect(mapStateToProps)(DialogAccoppiamenti)

export default ConnectedDialogAccoppiamenti
