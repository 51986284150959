export const RESET_GIRONI = '@@gironi/RESET_GIRONI'

export const SET_NEW_TEAM = '@@gironi/SET_NEW_TEAM'
export const SET_NEW_GIRONE = '@@gironi/SET_NEW_GIRONE'
export const SET_NEW_POSIZIONE = '@@gironi/SET_NEW_POSIZIONE'
export const SET_NEW_GIRONE_ADD = '@@gironi/SET_NEW_GIRONE_ADD'
export const SET_NEW_POSIZIONE_ADD = '@@gironi/SET_NEW_POSIZIONE_ADD'

export const SET_SELECTED_FUNCTION = '@@gironi/SET_SELECTED_FUNCTION'

export const SET_ISCRIZIONI_GIRONI = '@@gironi/SET_ISCRIZIONI_GIRONI'
