import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import InputField from './InputField'
import ButtonGroup from './ButtonGroup'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { errorFunctions } from '../hooks/error-functions'
import { sortFunctions } from '../hooks/sort-functions'
import { utilsFunctions } from '../hooks/utils-functions'
import { SET_ERROR } from '../container/home/types'
import {
  RESET_RISULTATO,
  SET_SELECTED_GARA,
  SET_PARZIALI_CASA,
  SET_PARZIALI_TRASFERTA,
  SET_SET_VINTI_CASA_MINI,
  SET_SET_VINTI_TRASFERTA_MINI,
  SET_PARZIALE_GOLDEN_CASA,
  SET_PARZIALE_GOLDEN_TRASFERTA,
} from '../container/risultato/types'

const DialogRisultato = ({
  gara,
  admin,
  iscrizioniLight,
  categorie,
  iscrizioni,
  availableGare,
  selectedGara,
  parzialiCasa,
  parzialiTrasferta,
  setVintiCasaMini,
  setVintiTrasfertaMini,
  parzialeGoldenCasa,
  parzialeGoldenTrasferta,
}) => {
  const dispatch = useDispatch()

  const [availableCategorie, setAvailableCategorie] = useState()
  const [availableSquadre, setAvailableSquadre] = useState()
  const [availableGareTeam, setAvailableGareTeam] = useState()
  const [selectedCategoria, setSelectedCategoria] = useState()
  const [selectedSquadra, setSelectedSquadra] = useState()

  // state to manage scores
  const [setVintiCasa, setSetVintiCasa] = useState()
  const [setVintiTrasferta, setSetVintiTrasferta] = useState()
  const [puntiVintiCasa, setPuntiVintiCasa] = useState()
  const [puntiVintiTrasferta, setPuntiVintiTrasferta] = useState()

  // state to manage Mini and Propaganda
  const [isMini, setIsMini] = useState()
  const [isPropaganda, setIsPropaganda] = useState()
  const [isPriFin2223, setIsPriFin2223] = useState()

  // states to manage Golden set
  const [garaCurr, setGaraCurr] = useState()
  const [garaPrec, setGaraPrec] = useState()
  const [isReturn, setIsReturn] = useState()
  const [setVintiCasaPrec, setSetVintiCasaPrec] = useState()
  const [setVintiTrasfertaPrec, setSetVintiTrasfertaPrec] = useState()
  const [penalitaCasaPrec, setPenalitaCasaPrec] = useState()
  const [penalitaTrasfertaPrec, setPenalitaTrasfertaPrec] = useState()
  const [penalitaCasaCurr, setPenalitaCasaCurr] = useState()
  const [penalitaTrasfertaCurr, setPenalitaTrasfertaCurr] = useState()
  const [needsGoldenSet, setNeedsGoldenSet] = useState()

  // error functions
  const {
    errorPunteggioSet,
    errorPunteggioSetN,
    errorPunteggioGoldenSet,
    errorPunteggioMini,
    errorSetMini,
  } = errorFunctions()

  const fErrorSetMini = () =>
    errorSetMini(setVintiCasaMini, setVintiTrasfertaMini)
  const fErrorPunteggioMini = () =>
    errorPunteggioMini(parzialiCasa, parzialiTrasferta)
  const fErrorPunteggioSetN = n =>
    errorPunteggioSetN(
      parzialiCasa,
      parzialiTrasferta,
      n,
      setVintiCasa,
      setVintiTrasferta,
      isPropaganda,
      isPriFin2223
    )
  const fErrorPunteggioGoldenSet = () =>
    errorPunteggioGoldenSet(
      parzialeGoldenCasa,
      parzialeGoldenTrasferta,
      isPropaganda,
      isPriFin2223
    )

  // sort functions
  const { sortNumeriGara } = sortFunctions()
  const sortNumeriGaraM = useCallback(sortNumeriGara, [])

  // utils functions
  const { fromStringsToDateTime, setWinningScore } = utilsFunctions()

  const [squadraCasaName, setSquadraCasaName] = useState()
  const [squadraTrasfertaName, setSquadraTrasfertaName] = useState()

  // Set team names
  useEffect(() => {
    const tGara = admin
      ? gara
      : availableGareTeam?.find(c => c._id === selectedGara)

    if (tGara?.squadraCasaID)
      setSquadraCasaName(
        iscrizioniLight?.find(i => i.id === tGara.squadraCasaID)?.nomeSquadra ||
          tGara.squadraCasaID
      )

    if (tGara?.squadraTrasfertaID)
      setSquadraTrasfertaName(
        iscrizioniLight?.find(i => i.id === tGara.squadraTrasfertaID)
          ?.nomeSquadra || tGara.squadraTrasfertaID
      )
  }, [admin, availableGareTeam, gara, iscrizioniLight, selectedGara])

  // Set categorie
  useEffect(() => {
    setAvailableCategorie(
      availableGare
        ?.map(i => i.categoria)
        ?.filter((v, i, s) => s.indexOf(v) === i)
        ?.sort()
    )
  }, [availableGare])

  // Set categoria
  useEffect(() => {
    setSelectedCategoria(
      availableCategorie?.length === 1 ? availableCategorie[0] : undefined
    )
  }, [availableCategorie])

  // Set squadre
  useEffect(() => {
    if (availableGare && iscrizioni && selectedCategoria)
      setAvailableSquadre(
        availableGare
          ?.filter(
            a =>
              a.categoria === selectedCategoria &&
              (iscrizioni?.map(i => i.id)?.includes(a.squadraCasaID) ||
                iscrizioni?.map(i => i.id)?.includes(a.squadraTrasfertaID))
          )
          ?.map(a =>
            iscrizioni?.map(i => i.id)?.includes(a.squadraCasaID)
              ? a.squadraCasaID
              : a.squadraTrasfertaID
          )
          ?.filter((v, i, s) => s.indexOf(v) === i)
      )
  }, [availableGare, iscrizioni, selectedCategoria])

  // Set squadra
  useEffect(() => {
    setSelectedSquadra(
      availableSquadre?.length === 1 ? availableSquadre[0] : undefined
    )
  }, [availableSquadre])

  // Set gare
  useEffect(() => {
    setAvailableGareTeam(
      availableGare
        ?.filter(
          a =>
            a.squadraCasaID === selectedSquadra ||
            (a.squadraTrasfertaID === selectedSquadra && a.girone === 'finali')
        )
        ?.sort((a, b) => sortNumeriGaraM(a.numeroGara, b.numeroGara))
    )
  }, [availableGare, selectedSquadra, sortNumeriGaraM])

  // Set gara
  useEffect(() => {
    dispatch({
      type: SET_SELECTED_GARA,
      payload: admin
        ? gara?._id
        : availableGareTeam?.length === 1
        ? availableGareTeam[0]?._id
        : undefined,
    })
  }, [admin, availableGareTeam, dispatch, gara])

  // Set or reset scores
  useEffect(() => {
    if (admin) {
      if (gara) {
        dispatch({ type: SET_PARZIALI_CASA, payload: gara.parzialiCasa })
        dispatch({
          type: SET_PARZIALI_TRASFERTA,
          payload: gara.parzialiTrasferta,
        })
        dispatch({
          type: SET_SET_VINTI_CASA_MINI,
          payload: gara.setVintiCasa,
        })
        dispatch({
          type: SET_SET_VINTI_TRASFERTA_MINI,
          payload: gara.setVintiTrasferta,
        })
        dispatch({
          type: SET_PARZIALE_GOLDEN_CASA,
          payload: gara.parzialeGoldenCasa,
        })
        dispatch({
          type: SET_PARZIALE_GOLDEN_TRASFERTA,
          payload: gara.parzialeGoldenTrasferta,
        })
      }
    } else {
      dispatch({ type: SET_PARZIALI_CASA })
      dispatch({ type: SET_PARZIALI_TRASFERTA })
      dispatch({ type: SET_SET_VINTI_CASA_MINI })
      dispatch({ type: SET_SET_VINTI_TRASFERTA_MINI })
      dispatch({ type: SET_PARZIALE_GOLDEN_CASA })
      dispatch({ type: SET_PARZIALE_GOLDEN_TRASFERTA })
    }
  }, [admin, dispatch, gara])

  // set isMini, isPropaganda and Primaverile 2022/2023 Finali
  useEffect(() => {
    const updateMiniAndPropaganda = async () => {
      try {
        const garaData = await sendRequest(`calendari/${selectedGara}`)

        if (!garaData)
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare la partita.',
          })

        const tCategoria = categorie?.find(
          c => c.categoria === garaData?.data?.categoria
        )

        setIsMini(tCategoria?.isMini)
        setIsPropaganda(tCategoria?.isPropaganda)
        setIsPriFin2223(
          garaData?.data?.codiceCampionato === 'primaverile_2022-2023' &&
            garaData?.data?.girone === 'finali'
        )
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (!!selectedGara) updateMiniAndPropaganda()
  }, [categorie, dispatch, selectedGara])

  // Set setVinti
  useEffect(() => {
    setSetVintiCasa(
      (isMini
        ? setVintiCasaMini
        : parzialiCasa?.filter(
            (p, i) =>
              !errorPunteggioSet(
                parzialiCasa?.[i],
                parzialiTrasferta?.[i],
                setWinningScore(i + 1, isPropaganda, isPriFin2223)
              ) && p > parzialiTrasferta?.[i]
          )?.length) || 0
    )
    setSetVintiTrasferta(
      (isMini
        ? setVintiTrasfertaMini
        : parzialiTrasferta?.filter(
            (p, i) =>
              !errorPunteggioSet(
                parzialiCasa?.[i],
                parzialiTrasferta?.[i],
                setWinningScore(i + 1, isPropaganda, isPriFin2223)
              ) && p > parzialiCasa?.[i]
          )?.length) || 0
    )
  }, [
    errorPunteggioSet,
    isMini,
    isPriFin2223,
    isPropaganda,
    parzialiCasa,
    parzialiTrasferta,
    setVintiCasaMini,
    setVintiTrasfertaMini,
    setWinningScore,
  ])

  // Set puntiVinti
  useEffect(() => {
    setPuntiVintiCasa(
      parzialiCasa
        ?.filter(
          (_, i) =>
            isMini ||
            !errorPunteggioSet(
              parzialiCasa?.[i],
              parzialiTrasferta?.[i],
              setWinningScore(i + 1, isPropaganda, isPriFin2223)
            )
        )
        ?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0
    )
    setPuntiVintiTrasferta(
      parzialiTrasferta
        ?.filter(
          (_, i) =>
            isMini ||
            !errorPunteggioSet(
              parzialiCasa?.[i],
              parzialiTrasferta?.[i],
              setWinningScore(i + 1, isPropaganda, isPriFin2223)
            )
        )
        ?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0
    )
  }, [
    errorPunteggioSet,
    isMini,
    isPriFin2223,
    isPropaganda,
    parzialiCasa,
    parzialiTrasferta,
    setWinningScore,
  ])

  // set garaCurr and garaPrec
  useEffect(() => {
    const fetchGaraCurrPrec = async () => {
      try {
        // get current match
        const garaData = await sendRequest(`calendari/${selectedGara}`)
        if (!garaData || !garaData.data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare la partita.',
          })
        } else {
          // get matches of the current "sfida"
          const { codiceCampionato, categoria, sfidaTabellone } = garaData.data
          if (sfidaTabellone) {
            const sfidaData = await sendRequest(
              `calendari/campionato/${codiceCampionato}/categoria/${categoria}/sfida/${sfidaTabellone}`
            )
            if (!sfidaData) {
              dispatch({
                type: SET_ERROR,
                payload: 'Impossibile trovare le partite della sfida.',
              })
            } else {
              if (sfidaData.length === 2) {
                // check teamIDs
                if (
                  [
                    sfidaData[0]?.squadraCasaID,
                    sfidaData[0]?.squadraTrasfertaID,
                  ].every(i =>
                    [
                      sfidaData[1]?.squadraCasaID,
                      sfidaData[1]?.squadraTrasfertaID,
                    ].includes(i)
                  )
                ) {
                  setGaraCurr(sfidaData.find(s => s.id === selectedGara))
                  setGaraPrec(sfidaData.find(s => s.id !== selectedGara))
                }
              } else {
                setGaraCurr()
                setGaraPrec()
              }
            }
          }
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }

    if (!!selectedGara) {
      fetchGaraCurrPrec()
    } else {
      setGaraCurr()
      setGaraPrec()
    }
  }, [dispatch, selectedGara])

  // update setVintiPrec, penalitaPrec, penalitaCurr
  useEffect(() => {
    if (garaCurr && garaPrec) {
      const sameCasaTrasferta =
        garaCurr.squadraCasaID === garaPrec.squadraCasaID &&
        garaCurr.squadraTrasfertaID === garaPrec.squadraTrasfertaID

      if (sameCasaTrasferta) {
        setSetVintiCasaPrec(garaPrec.setVintiCasa)
        setSetVintiTrasfertaPrec(garaPrec.setVintiTrasferta)
        setPenalitaCasaPrec(garaPrec.penalitaCasa)
        setPenalitaTrasfertaPrec(garaPrec.penalitaTrasferta)
      } else {
        setSetVintiCasaPrec(garaPrec.setVintiTrasferta)
        setSetVintiTrasfertaPrec(garaPrec.setVintiCasa)
        setPenalitaCasaPrec(garaPrec.penalitaTrasferta)
        setPenalitaTrasfertaPrec(garaPrec.penalitaCasa)
      }

      setPenalitaCasaCurr(garaCurr.penalitaCasa)
      setPenalitaTrasfertaCurr(garaCurr.penalitaTrasferta)
    } else {
      setSetVintiCasaPrec()
      setSetVintiTrasfertaPrec()
      setPenalitaCasaPrec()
      setPenalitaTrasfertaPrec()
      setPenalitaCasaCurr()
      setPenalitaTrasfertaCurr()
    }
  }, [garaCurr, garaPrec])

  // update isReturn
  useEffect(() => {
    setIsReturn(
      garaCurr?.data &&
        garaCurr?.ora &&
        garaPrec?.data &&
        garaPrec?.ora &&
        fromStringsToDateTime(garaCurr.data, garaCurr.ora) >=
          fromStringsToDateTime(garaPrec.data, garaPrec.ora)
    )
  }, [fromStringsToDateTime, garaCurr, garaPrec])

  // update needsGoldenSet
  useEffect(() => {
    if (isReturn) {
      const puntiCasa =
        setVintiCasa +
        setVintiCasaPrec -
        (penalitaCasaCurr || 0) -
        (penalitaCasaPrec || 0)

      const puntiTrasferta =
        setVintiTrasferta +
        setVintiTrasfertaPrec -
        (penalitaTrasfertaCurr || 0) -
        (penalitaTrasfertaPrec || 0)

      if (puntiCasa === puntiTrasferta) {
        setNeedsGoldenSet(true)
      } else {
        setNeedsGoldenSet(false)
        dispatch({ type: SET_PARZIALE_GOLDEN_CASA })
        dispatch({ type: SET_PARZIALE_GOLDEN_TRASFERTA })
      }
    }
  }, [
    dispatch,
    isReturn,
    penalitaCasaCurr,
    penalitaCasaPrec,
    penalitaTrasfertaCurr,
    penalitaTrasfertaPrec,
    setVintiCasa,
    setVintiCasaPrec,
    setVintiTrasferta,
    setVintiTrasfertaPrec,
  ])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: RESET_RISULTATO })
  }, [dispatch])

  const mainContainerProps = {
    container: true,
    item: true,
    xs: 12,
    spacing: 1,
    direction: 'column',
    alignItems: 'baseline',
  }

  const rowContainerProps = {
    container: true,
    item: true,
    xs: 12,
    spacing: 2,
    alignItems: 'center',
    justifyContent: 'center',
  }

  const inputProps = {
    type: 'number',
    align: 'center',
    helperText: '',
    errorText: '',
    mode: admin ? 'update' : undefined,
  }

  const goldenStyle = { paddingBottom: 20, backgroundColor: colours.goldenSet }

  const fColor = (isError, isUndefined) =>
    (isError && colours.redLight) ||
    (isUndefined && colours.greyLight) ||
    colours.black

  const updateParziali = (p, n, i) => {
    if (!p) return [...new Array(n - 1).fill(undefined), i]

    if (p.length >= n)
      return [
        ...p.filter((_, x) => x < n - 1),
        i,
        ...p.filter((_, x) => x > n - 1),
      ]

    return [...p, ...new Array(n - p.length - 1).fill(undefined), i]
  }

  return (
    <>
      {!admin &&
        (((!iscrizioni || iscrizioni.length === 0) && (
          <Grid item xs={12}>
            Nessuna squadra iscritta a questo campionato.
          </Grid>
        )) || (
          <Grid container spacing={3} direction='column'>
            <Grid item xs={12}>
              Il presente modulo permette di comunicare i risultati delle gare.
              <br />
              Nelle fasi a gironi il risultato deve essere comunicato dalla
              squadra di casa, nelle fasi a eliminazione dalla squadra vincente.
              <br />
              <br />
              Non è possibile inviare i risultati per le gare:
              <ul>
                <li>calendarizzate nel futuro,</li>
                <li>rimandate a data da destinarsi (cioè senza data),</li>
                <li>con spostamento in sospeso,</li>
                <li>con risultato già presente.</li>
              </ul>
              Per domande o chiarimenti scrivere a{' '}
              <a href='mailto:risultati@pgsmilano.org'>
                risultati@pgsmilano.org
              </a>
              .
            </Grid>
            &nbsp;
            {((!availableGare || availableGare.length === 0) && (
              <Grid item>
                <center>
                  <b>
                    <em>Nessun risultato da inviare.</em>
                  </b>
                </center>
              </Grid>
            )) || (
              <>
                <Grid container item alignItems='center'>
                  <Grid item xs={5} md={3}>
                    <center>
                      <b>Categoria</b>
                    </center>
                  </Grid>
                  <Grid item xs={7} md={9}>
                    {(availableCategorie?.length > 0 && (
                      <ButtonGroup
                        list={availableCategorie}
                        value={selectedCategoria}
                        func={setSelectedCategoria}
                      />
                    )) ||
                      'Nessuna categoria trovata.'}
                  </Grid>
                </Grid>
                {!selectedCategoria ? (
                  <Grid item>
                    <center>
                      <b>
                        <em>Selezionare una categoria.</em>
                      </b>
                    </center>
                  </Grid>
                ) : (
                  <>
                    <Grid container item alignItems='center'>
                      <Grid item xs={5} md={3}>
                        <center>
                          <b>Squadra</b>
                        </center>
                      </Grid>
                      <Grid item xs={7} md={9}>
                        {!!selectedCategoria &&
                          ((availableSquadre?.length > 0 && (
                            <ButtonGroup
                              list={availableSquadre}
                              labels={index =>
                                iscrizioni?.find(
                                  i => i.id === availableSquadre[index]
                                )?.nomeSquadra
                              }
                              value={selectedSquadra}
                              func={setSelectedSquadra}
                            />
                          )) ||
                            'Nessuna squadra trovata.')}
                      </Grid>
                    </Grid>
                    {!selectedSquadra ? (
                      <Grid item>
                        <center>
                          <b>
                            <em>Selezionare una squadra.</em>
                          </b>
                        </center>
                      </Grid>
                    ) : (
                      <Grid container item alignItems='center'>
                        <Grid item xs={5} md={3}>
                          <center>
                            <b>Gara</b>
                          </center>
                        </Grid>
                        <Grid item xs={7} md={9}>
                          {!!selectedSquadra &&
                            ((availableGareTeam?.length > 0 && (
                              <ButtonGroup
                                list={availableGareTeam?.map(i => i._id)}
                                labels={index =>
                                  availableGareTeam[index]?.numeroGara
                                }
                                value={selectedGara}
                                func={i =>
                                  dispatch({
                                    type: SET_SELECTED_GARA,
                                    payload: i,
                                  })
                                }
                              />
                            )) ||
                              'Nessuna gara trovata.')}
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        ))}
      &nbsp;
      {!selectedGara && (admin || (selectedCategoria && selectedSquadra)) ? (
        <Grid item>
          <center>
            <b>
              <em>{admin ? 'Gara non trovata' : 'Selezionare una gara.'}</em>
            </b>
          </center>
        </Grid>
      ) : (
        <Grid container item xs={12} alignItems='center'>
          <Grid item xs={6}>
            <center>
              <b>{squadraCasaName}</b>
            </center>
          </Grid>
          <Grid item xs={6}>
            <center>
              <b>{squadraTrasfertaName}</b>
            </center>
          </Grid>
        </Grid>
      )}
      &nbsp;
      <Grid container spacing={3} direction='column'>
        {!!selectedGara && isMini && (
          <Grid {...mainContainerProps}>
            <Grid {...rowContainerProps}>
              <Grid item xs md={2}>
                <center>
                  <InputField
                    id='setVintiCasaMini'
                    value={setVintiCasaMini}
                    onChange={i =>
                      dispatch({ type: SET_SET_VINTI_CASA_MINI, payload: i })
                    }
                    autoFocus={true}
                    errorFunc={fErrorSetMini}
                    {...inputProps}
                  />
                </center>
              </Grid>
              <Grid item xs={3} md={4}>
                <center
                  style={{
                    color: fColor(
                      fErrorSetMini(),
                      setVintiCasaMini === undefined ||
                        setVintiTrasfertaMini === undefined
                    ),
                  }}
                >
                  set
                </center>
              </Grid>
              <Grid item xs md={2}>
                <center>
                  <InputField
                    id='setVintiTrasfertaMini'
                    value={setVintiTrasfertaMini}
                    onChange={i =>
                      dispatch({
                        type: SET_SET_VINTI_TRASFERTA_MINI,
                        payload: i,
                      })
                    }
                    errorFunc={fErrorSetMini}
                    {...inputProps}
                  />
                </center>
              </Grid>
            </Grid>
            {[1].map(n => (
              <Grid key={n} {...rowContainerProps}>
                <Grid item xs md={2}>
                  <center>
                    <InputField
                      id='parzialiCasa'
                      value={parzialiCasa?.[n - 1]}
                      onChange={i =>
                        dispatch({
                          type: SET_PARZIALI_CASA,
                          payload: updateParziali(parzialiCasa, n, i),
                        })
                      }
                      errorFunc={fErrorPunteggioMini}
                      {...inputProps}
                    />
                  </center>
                </Grid>
                <Grid item xs={3} md={4}>
                  <center
                    style={{
                      color: fColor(
                        fErrorPunteggioMini(),
                        parzialiCasa?.[n - 1] === undefined ||
                          parzialiTrasferta?.[n - 1] === undefined
                      ),
                    }}
                  >
                    punti
                  </center>
                </Grid>
                <Grid item xs md={2}>
                  <center>
                    <InputField
                      id='parzialiTrasferta'
                      value={parzialiTrasferta?.[n - 1]}
                      onChange={i =>
                        dispatch({
                          type: SET_PARZIALI_TRASFERTA,
                          payload: updateParziali(parzialiTrasferta, n, i),
                        })
                      }
                      errorFunc={fErrorPunteggioMini}
                      {...inputProps}
                    />
                  </center>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        {!!selectedGara && !isMini && (
          <Grid {...mainContainerProps}>
            {[1, 2, 3, 4, 5].map(n => (
              <Grid key={n} {...rowContainerProps}>
                <Grid item xs md={2}>
                  <center>
                    <InputField
                      id={`parzialiCasa-${n}`}
                      value={parzialiCasa?.[n - 1]}
                      onChange={i =>
                        dispatch({
                          type: SET_PARZIALI_CASA,
                          payload: updateParziali(parzialiCasa, n, i),
                        })
                      }
                      autoFocus={n === 1}
                      errorFunc={() => fErrorPunteggioSetN(n)}
                      {...inputProps}
                    />
                  </center>
                </Grid>
                <Grid item xs={3} md={4}>
                  <center
                    style={{
                      color: fColor(
                        fErrorPunteggioSetN(n),
                        parzialiCasa?.[n - 1] === undefined ||
                          parzialiTrasferta?.[n - 1] === undefined
                      ),
                    }}
                  >
                    {n}° set
                  </center>
                </Grid>
                <Grid item xs md={2}>
                  <center>
                    <InputField
                      id={`parzialiTrasferta-${n}`}
                      value={parzialiTrasferta?.[n - 1]}
                      onChange={i =>
                        dispatch({
                          type: SET_PARZIALI_TRASFERTA,
                          payload: updateParziali(parzialiTrasferta, n, i),
                        })
                      }
                      errorFunc={() => fErrorPunteggioSetN(n)}
                      {...inputProps}
                    />
                  </center>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        {!!selectedGara && !!isReturn && (
          <Grid {...rowContainerProps} alignItems='stretch'>
            <Grid item xs md={2} style={goldenStyle}>
              <center>
                <InputField
                  id='parzialeGoldenCasa'
                  value={parzialeGoldenCasa}
                  onChange={i =>
                    dispatch({ type: SET_PARZIALE_GOLDEN_CASA, payload: i })
                  }
                  disabled={!needsGoldenSet}
                  errorFunc={fErrorPunteggioGoldenSet}
                  {...inputProps}
                />
              </center>
            </Grid>
            <Grid item xs={3} md={4} style={goldenStyle}>
              <center
                style={{
                  color: fColor(fErrorPunteggioGoldenSet(), !needsGoldenSet),
                }}
              >
                Golden set
              </center>
            </Grid>
            <Grid item xs md={2} style={goldenStyle}>
              <center>
                <InputField
                  id='parzialeGoldenTrasferta'
                  value={parzialeGoldenTrasferta}
                  onChange={i =>
                    dispatch({
                      type: SET_PARZIALE_GOLDEN_TRASFERTA,
                      payload: i,
                    })
                  }
                  disabled={!needsGoldenSet}
                  errorFunc={fErrorPunteggioGoldenSet}
                  {...inputProps}
                />
              </center>
            </Grid>
          </Grid>
        )}
        &nbsp;
        {!!selectedGara && (
          <Grid container item xs={12} spacing={2} alignItems='center'>
            <Grid item xs={6} md={3}>
              <center>
                Risultato
                <br />
                <b>{setVintiCasa}</b>
                &nbsp; - &nbsp;
                <b>{setVintiTrasferta}</b>
              </center>
            </Grid>
            <Grid item xs={6} md={3}>
              <center>
                Punteggio
                <br />
                <b>{puntiVintiCasa}</b>
                &nbsp; - &nbsp;
                <b>{puntiVintiTrasferta}</b>
              </center>
            </Grid>
            <Grid item xs={12} md={6}>
              <center>
                Squadra vincitrice
                <br />
                <b>
                  {setVintiCasa > setVintiTrasferta
                    ? squadraCasaName
                    : setVintiCasa < setVintiTrasferta
                    ? squadraTrasfertaName
                    : isMini &&
                      setVintiCasa &&
                      setVintiTrasferta &&
                      setVintiCasa === setVintiTrasferta
                    ? 'PAREGGIO'
                    : '?'}
                </b>
              </center>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  iscrizioniLight: state.home.iscrizioniLight,
  categorie: state.home.categorie,
  iscrizioni: state.iscrizioni.iscrizioni,
  availableGare: state.risultato.availableGare,
  selectedGara: state.risultato.selectedGara,
  parzialiCasa: state.risultato.parzialiCasa,
  parzialiTrasferta: state.risultato.parzialiTrasferta,
  setVintiCasaMini: state.risultato.setVintiCasaMini,
  setVintiTrasfertaMini: state.risultato.setVintiTrasfertaMini,
  parzialeGoldenCasa: state.risultato.parzialeGoldenCasa,
  parzialeGoldenTrasferta: state.risultato.parzialeGoldenTrasferta,
})

const ConnectedDialogRisultato = connect(mapStateToProps)(DialogRisultato)

export default ConnectedDialogRisultato
