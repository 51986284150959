import React from 'react'
import { Link } from 'react-router-dom'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import DialogZone from './DialogZone'
import DialogGirone from './DialogGirone'
import DialogDomeniche from './DialogDomeniche'
import DialogCalendario from './DialogCalendario'
import DialogSpostamento from './DialogSpostamento'
import DialogSpostamentoInfo from './DialogSpostamentoInfo'
import DialogAccoppiamenti from './DialogAccoppiamenti'
import DialogRisultato from './DialogRisultato'
import DialogPenalita from './DialogPenalita'
import DialogOmologa from './DialogOmologa'
import DialogCategorie from './DialogCategorie'
import DialogArbitraggio from './DialogArbitraggio'
import DialogSanzioneReferto from './DialogSanzioneReferto'
import DialogTabelloni from './DialogTabelloni'
import DialogTabellone from './DialogTabellone'
import DialogRipescaggio from './DialogRipescaggio'
import DialogDeleteIscrizione from './DialogDeleteIscrizione'
import DialogDeleteAffiliazione from './DialogDeleteAffiliazione'
import DialogDesignazione from './DialogDesignazione'
import DialogDeleteArbitro from './DialogDeleteArbitro'
import DialogRimborso from './DialogRimborso'
import DialogRimborsoPagato from './DialogRimborsoPagato'
import DialogRendiconto from './DialogRendiconto'
import DialogRendicontoAdd from './DialogRendicontoAdd'
import DialogRichiestaArbitraggio from './DialogRichiestaArbitraggio'
import DialogCambioIndirizzo from './DialogCambioIndirizzo'
import DialogIndirizzo from './DialogIndirizzo'
import DialogCambioPassword from './DialogCambioPassword'
import { ColouredButton, TransparentButton } from '../components/Buttons'
import { colours } from '../settings/settings'

const DialogMini = ({
  open = false,
  handleClose = () => {},
  title = 'Titolo',
  textContent,
  textUndo,
  textConfirm,
  triggerFunction = () => {},
  triggerPath,
  colourText = colours.white,
  colourBackground = colours.blue,
  colourHover = colours.blueDark,
  dialogZone = false,
  dialogGirone = false,
  dialogDomeniche = false,
  dialogCalendario = false,
  dialogSpostamento = false,
  dialogSpostamentoInfo = false,
  dialogAccoppiamenti = false,
  dialogRisultato = false,
  dialogPenalita = false,
  dialogOmologa = false,
  dialogCategorie = false,
  dialogArbitraggio = false,
  dialogSanzioneReferto = false,
  dialogTabelloni = false,
  dialogTabellone = false,
  dialogRipescaggio = false,
  dialogDeleteIscrizione = false,
  dialogDeleteAffiliazione = false,
  dialogDesignazione = false,
  dialogDeleteArbitro = false,
  dialogRimborso = false,
  dialogRimborsoPagato = false,
  dialogRendiconto = false,
  dialogRendicontoAdd = false,
  dialogRichiestaArbitraggio = false,
  dialogCambioIndirizzo = false,
  dialogIndirizzo = false,
  dialogCambioPassword = false,
  affiliazione,
  arbitro,
  team,
  gara,
}) => (
  <Dialog
    open={open}
    onClose={(_, reason) => {
      if (!['backdropClick', 'escapeKeyDown'].includes(reason)) handleClose()
    }}
    fullWidth={true}
    maxWidth={
      dialogZone ||
      dialogDomeniche ||
      dialogSpostamento ||
      dialogCategorie ||
      dialogTabelloni ||
      dialogTabellone ||
      dialogDesignazione ||
      dialogRimborso ||
      dialogRendiconto ||
      dialogCambioIndirizzo
        ? 'lg'
        : dialogGirone ||
          dialogCalendario ||
          dialogAccoppiamenti ||
          dialogSpostamentoInfo ||
          dialogRisultato ||
          dialogPenalita ||
          dialogOmologa ||
          dialogArbitraggio ||
          dialogSanzioneReferto ||
          dialogRipescaggio ||
          dialogRimborsoPagato ||
          dialogRichiestaArbitraggio
        ? 'md'
        : 'sm'
    }
    componentsProps={{ backdrop: { backgroundcolor: colours.backDrop } }}
    PaperProps={{
      style: {
        minHeight:
          (dialogZone ||
            dialogDomeniche ||
            dialogSpostamento ||
            dialogAccoppiamenti ||
            (dialogRisultato && !gara) ||
            dialogCategorie ||
            dialogTabelloni ||
            dialogTabellone ||
            dialogRimborso ||
            dialogRendiconto ||
            dialogRichiestaArbitraggio ||
            dialogCambioIndirizzo) &&
          window.innerHeight * 0.95,
      },
    }}
    transitionDuration={{ enter: 250, exit: 0 }}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {!!textContent && <DialogContentText>{textContent}</DialogContentText>}
      {!!dialogGirone && <DialogGirone team={team} />}
      {!!dialogCalendario && <DialogCalendario gara={gara} />}
      {!!dialogZone && <DialogZone />}
      {!!dialogDomeniche && <DialogDomeniche />}
      {!!dialogAccoppiamenti && <DialogAccoppiamenti />}
      {!!dialogSpostamento && <DialogSpostamento />}
      {!!dialogSpostamentoInfo && <DialogSpostamentoInfo />}
      {!!dialogRisultato && <DialogRisultato gara={gara} />}
      {!!dialogPenalita && <DialogPenalita gara={gara} />}
      {!!dialogOmologa && <DialogOmologa gara={gara} />}
      {!!dialogCategorie && <DialogCategorie />}
      {!!dialogArbitraggio && <DialogArbitraggio gara={gara} />}
      {!!dialogSanzioneReferto && <DialogSanzioneReferto gara={gara} />}
      {!!dialogTabelloni && <DialogTabelloni />}
      {!!dialogTabellone && <DialogTabellone />}
      {!!dialogRipescaggio && <DialogRipescaggio gara={gara} />}
      {!!dialogDeleteIscrizione && <DialogDeleteIscrizione team={team} />}
      {!!dialogDeleteAffiliazione && (
        <DialogDeleteAffiliazione affiliazione={affiliazione} />
      )}
      {!!dialogDesignazione && <DialogDesignazione />}
      {!!dialogDeleteArbitro && <DialogDeleteArbitro arbitro={arbitro} />}
      {!!dialogRimborso && <DialogRimborso />}
      {!!dialogRimborsoPagato && <DialogRimborsoPagato />}
      {!!dialogRendiconto && <DialogRendiconto />}
      {!!dialogRendicontoAdd && <DialogRendicontoAdd />}
      {!!dialogRichiestaArbitraggio && <DialogRichiestaArbitraggio />}
      {!!dialogCambioIndirizzo && <DialogCambioIndirizzo />}
      {!!dialogIndirizzo && <DialogIndirizzo />}
      {!!dialogCambioPassword && <DialogCambioPassword />}
    </DialogContent>
    <DialogActions>
      {!!textUndo && (
        <TransparentButton onClick={handleClose}>{textUndo}</TransparentButton>
      )}
      &nbsp;
      {!!textConfirm &&
        ((!!triggerPath && (
          <Link
            to={triggerPath}
            style={{ textDecoration: 'none' }}
            onClick={triggerFunction}
          >
            <ColouredButton
              textcolour={colourText}
              textbold='bold'
              backgroundcolour={colourBackground}
              hovercolour={colourHover}
            >
              {textConfirm}
            </ColouredButton>
          </Link>
        )) || (
          <ColouredButton
            textcolour={colourText}
            textbold='bold'
            backgroundcolour={colourBackground}
            hovercolour={colourHover}
            onClick={triggerFunction}
          >
            {textConfirm}
          </ColouredButton>
        ))}
    </DialogActions>
  </Dialog>
)

export default DialogMini
