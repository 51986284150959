import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { sendRequest } from './http-hook'
import { useInterval } from './loop-hook'
import {
  SET_AFFILIAZIONI_LIGHT,
  SET_ISCRIZIONI_LIGHT,
  SET_ARBITRI_LIGHT,
  SET_IMPOSTAZIONI,
  SET_ZONE,
  SET_CATEGORIE,
  SET_BACKEND_R,
  SET_ERROR,
  SET_LOADING_SPOSTAMENTI,
  SET_LOADING_ARBITRI,
  SET_LOADING_DESIGNAZIONI,
  SET_LOADING_ARBITRAGGI,
  SET_SELECTED_CAMPIONATO_ISCRIZIONI_APERTE,
  SET_SELECTED_CAMPIONATO_GIRONI_PROVVISORI,
  SET_SELECTED_CAMPIONATO_GIRONI_PUBBLICATI,
  SET_SELECTED_CAMPIONATO_CALENDARI_PROVVISORI,
  SET_SELECTED_CAMPIONATO_CALENDARI_PUBBLICATI,
} from '../container/home/types'
import { SET_ARBITRI } from '../container/arbitri/types'
import { SET_SPOSTAMENTI } from '../container/spostamenti/types'
import { SET_DESIGNAZIONI } from '../container/designazioni/types'
import { SET_ARBITRAGGI } from '../container/arbitraggi/types'

export const useFetch = (
  auth,
  admin,
  societa,
  arbitro,
  designatore,
  idArbitro,
  codiceUtente,
  impostazioni,
  selectedCampionato,
  backendR,
  dummyUpdate
) => {
  const dispatch = useDispatch()

  const fetchArbitriAll = (admin || designatore) && codiceUtente // serve in "Arbitri", "Designazioni" e "Rimborsi" a utenti "admin" e "designatore"
  const fetchArbitriLight = arbitro && codiceUtente // serve in "DialogDesignazione" a utenti "arbitro"

  dispatch({
    type: SET_SELECTED_CAMPIONATO_ISCRIZIONI_APERTE,
    payload:
      (!!impostazioni?.iscrizioniAperteInvernale &&
        impostazioni?.campionatoInvernale === selectedCampionato) ||
      (!!impostazioni?.iscrizioniApertePrimaverile &&
        impostazioni?.campionatoPrimaverile === selectedCampionato),
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_GIRONI_PROVVISORI,
    payload:
      (!!impostazioni?.gironiProvvisoriInvernale &&
        impostazioni?.campionatoInvernale === selectedCampionato) ||
      (!!impostazioni?.gironiProvvisoriPrimaverile &&
        impostazioni?.campionatoPrimaverile === selectedCampionato),
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_GIRONI_PUBBLICATI,
    payload:
      (!!impostazioni?.gironiPubblicatiInvernale &&
        impostazioni?.campionatoInvernale === selectedCampionato) ||
      (!!impostazioni?.gironiPubblicatiPrimaverile &&
        impostazioni?.campionatoPrimaverile === selectedCampionato) ||
      (impostazioni?.campionatoInvernale !== selectedCampionato &&
        impostazioni?.campionatoPrimaverile !== selectedCampionato),
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_CALENDARI_PROVVISORI,
    payload:
      (selectedCampionato === impostazioni?.campionatoInvernale &&
        impostazioni?.calendariProvvisoriInvernale) ||
      (selectedCampionato === impostazioni?.campionatoPrimaverile &&
        impostazioni?.calendariProvvisoriPrimaverile),
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_CALENDARI_PUBBLICATI,
    payload:
      (selectedCampionato === impostazioni?.campionatoInvernale &&
        impostazioni?.calendariPubblicatiInvernale) ||
      (selectedCampionato === impostazioni?.campionatoPrimaverile &&
        impostazioni?.calendariPubblicatiPrimaverile) ||
      (impostazioni?.campionatoInvernale !== selectedCampionato &&
        impostazioni?.campionatoPrimaverile !== selectedCampionato),
  })

  // fetch affiliazioni light
  useEffect(() => {
    const fetchAffiliazioniLight = async () => {
      try {
        const affiliazioniData = await sendRequest(
          'affiliazioni/light',
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!affiliazioniData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le società "light".',
          })
        } else {
          dispatch({ type: SET_AFFILIAZIONI_LIGHT, payload: affiliazioniData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if ((admin || arbitro || designatore) && codiceUtente) {
      fetchAffiliazioniLight()
    } else {
      dispatch({ type: SET_AFFILIAZIONI_LIGHT })
    }
  }, [admin, arbitro, codiceUtente, designatore, dispatch, dummyUpdate])

  // fetch iscrizioni light
  useEffect(() => {
    const fetchIscrizioniLight = async () => {
      try {
        const iscrizioniLightData = await sendRequest(
          `iscrizioni/campionato/${selectedCampionato}/light`
        )

        if (!iscrizioniLightData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le iscrizioni "light".',
          })
        } else {
          dispatch({ type: SET_ISCRIZIONI_LIGHT, payload: iscrizioniLightData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (selectedCampionato) {
      fetchIscrizioniLight()
    } else {
      dispatch({ type: SET_ISCRIZIONI_LIGHT })
    }
  }, [dispatch, selectedCampionato])

  // fetch arbitri
  useEffect(() => {
    const fetchArbitri = async () => {
      dispatch({ type: SET_LOADING_ARBITRI, payload: true })
      try {
        const arbitriData = await sendRequest(`arbitri/all`, 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!arbitriData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare gli arbitri.',
          })
        } else {
          dispatch({ type: SET_ARBITRI, payload: arbitriData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      dispatch({ type: SET_LOADING_ARBITRI, payload: false })
    }
    if (fetchArbitriAll) {
      fetchArbitri()
    } else {
      dispatch({ type: SET_ARBITRI })
    }
  }, [codiceUtente, dispatch, dummyUpdate, fetchArbitriAll])

  // fetch arbitri "light"
  useEffect(() => {
    const fetchArbitri = async () => {
      dispatch({ type: SET_LOADING_ARBITRI, payload: true })
      try {
        const arbitriLightData = await sendRequest('arbitri/light')

        if (!arbitriLightData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare gli arbitri "light".',
          })
        } else {
          dispatch({ type: SET_ARBITRI_LIGHT, payload: arbitriLightData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      dispatch({ type: SET_LOADING_ARBITRI, payload: false })
    }
    if (fetchArbitriLight) {
      fetchArbitri()
    } else {
      dispatch({ type: SET_ARBITRI_LIGHT })
    }
  }, [dispatch, fetchArbitriLight])

  // fetch impostazioni
  useEffect(() => {
    const fetchImpostazioni = async () => {
      try {
        const impostazioniData = await sendRequest('impostazioni/all')

        if (!impostazioniData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le impostazioni.',
          })
        } else {
          dispatch({
            type: SET_IMPOSTAZIONI,
            payload: impostazioniData[0],
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    fetchImpostazioni()
  }, [auth, dispatch, dummyUpdate])

  // fetch zone
  useEffect(() => {
    const fetchZone = async () => {
      try {
        const zoneData = await sendRequest('zone/all', 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!zoneData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le zone.',
          })
        } else {
          dispatch({
            type: SET_ZONE,
            payload: zoneData?.sort((a, b) => (a.zona > b.zona ? 1 : -1)),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (admin && codiceUtente) {
      fetchZone()
    } else {
      dispatch({ type: SET_ZONE })
    }
  }, [admin, codiceUtente, dispatch, dummyUpdate])

  // fetch categorie
  useEffect(() => {
    const fetchCategorie = async () => {
      try {
        const categorieData = await sendRequest(`categorie/all`)

        if (!categorieData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le categorie.',
          })
        } else {
          dispatch({
            type: SET_CATEGORIE,
            payload: categorieData?.sort((a, b) => (a.nome > b.nome ? 1 : -1)),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    fetchCategorie()
  }, [auth, dispatch, dummyUpdate])

  // fetch spostamenti
  useEffect(() => {
    const fetchSpostamenti = async () => {
      dispatch({ type: SET_LOADING_SPOSTAMENTI, payload: true })
      try {
        const spostamentiData = await sendRequest(
          `spostamenti/campionato/${selectedCampionato}${
            admin ? '' : `/societa/${codiceUtente}`
          }`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!spostamentiData) {
          dispatch({
            type: SET_ERROR,
            payload: "Impossibile trovare l'elenco degli spostamenti.",
          })
        } else {
          dispatch({
            type: SET_SPOSTAMENTI,
            payload: spostamentiData.reverse(),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      dispatch({ type: SET_LOADING_SPOSTAMENTI, payload: false })
    }
    if ((admin || societa) && codiceUtente && selectedCampionato) {
      fetchSpostamenti()
    } else {
      dispatch({ type: SET_SPOSTAMENTI })
    }
  }, [admin, codiceUtente, dispatch, dummyUpdate, selectedCampionato, societa])

  // fetch designazioni
  useEffect(() => {
    const fetchDesignazioni = async () => {
      dispatch({ type: SET_LOADING_DESIGNAZIONI, payload: true })
      try {
        const designazioniData = await sendRequest(
          `designazioni${
            admin || designatore
              ? '/all' // get all to correctly check overlaps
              : `/campionato/${selectedCampionato}/arbitro/${idArbitro}`
          }`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!designazioniData) {
          dispatch({
            type: SET_ERROR,
            payload: "Impossibile trovare l'elenco delle designazioni.",
          })
        } else {
          dispatch({
            type: SET_DESIGNAZIONI,
            payload: designazioniData.reverse(),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      dispatch({ type: SET_LOADING_DESIGNAZIONI, payload: false })
    }
    if (
      (admin || designatore || (arbitro && idArbitro)) &&
      codiceUtente &&
      selectedCampionato
    ) {
      fetchDesignazioni()
    } else {
      dispatch({ type: SET_DESIGNAZIONI })
    }
  }, [
    admin,
    arbitro,
    codiceUtente,
    designatore,
    dispatch,
    dummyUpdate,
    idArbitro,
    selectedCampionato,
  ])

  // fetch arbitraggi
  useEffect(() => {
    const fetchArbitraggi = async () => {
      dispatch({ type: SET_LOADING_ARBITRAGGI, payload: true })
      try {
        const arbitraggiData = await sendRequest(
          `arbitraggi/campionato/${selectedCampionato}${
            admin || designatore ? '' : `/societa/${codiceUtente}`
          }`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!arbitraggiData) {
          dispatch({
            type: SET_ERROR,
            payload: "Impossibile trovare l'elenco degli arbitraggi.",
          })
        } else {
          dispatch({ type: SET_ARBITRAGGI, payload: arbitraggiData.reverse() })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      dispatch({ type: SET_LOADING_ARBITRAGGI, payload: false })
    }
    if (
      (admin || societa || designatore) &&
      codiceUtente &&
      selectedCampionato
    ) {
      fetchArbitraggi()
    } else {
      dispatch({ type: SET_ARBITRAGGI })
    }
  }, [
    admin,
    codiceUtente,
    designatore,
    dispatch,
    dummyUpdate,
    selectedCampionato,
    societa,
  ])

  // fetch backend R
  const fetchBackendR = async () => {
    if (admin) {
      try {
        const backendData = await sendRequest('backendR/all', 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!backendData || !backendData.data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare il backend R.',
          })
        } else {
          // update "backendR" only if it has really changed, so to avoid
          // useless re-renders that damage the file upload input component
          if (
            !backendR ||
            Object.keys(backendData.data).some(
              k => backendData.data[k] !== backendR[k]
            )
          ) {
            dispatch({ type: SET_BACKEND_R, payload: backendData.data })
          }
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
  }

  // loop backend R
  useInterval(fetchBackendR, backendR?.status === 'available' ? 30000 : 5000)
}
