import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'

import { colours } from '../settings/settings'
import { SET_NEEDS_REFEREE } from '../container/calendari/types'

const DialogArbitraggio = ({ gara, iscrizioniLight, needsReferee }) => {
  const dispatch = useDispatch()

  const [squadraCasaName, setSquadraCasaName] = useState()
  const [squadraTrasfertaName, setSquadraTrasfertaName] = useState()

  // Set team names
  useEffect(() => {
    if (gara?.squadraCasaID)
      setSquadraCasaName(
        iscrizioniLight?.find(i => i.id === gara.squadraCasaID)?.nomeSquadra ||
          gara.squadraCasaID
      )
    if (gara?.squadraTrasfertaID)
      setSquadraTrasfertaName(
        iscrizioniLight?.find(i => i.id === gara.squadraTrasfertaID)
          ?.nomeSquadra || gara.squadraTrasfertaID
      )
  }, [gara, iscrizioniLight])

  // Set arbitraggio
  useEffect(() => {
    dispatch({ type: SET_NEEDS_REFEREE, payload: gara?.needsReferee })
  }, [dispatch, gara])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_NEEDS_REFEREE })
  }, [dispatch])

  return (
    <>
      &nbsp;
      {((!gara || gara.length === 0) && (
        <Grid item xs={12}>
          Nessuna partita selezionata.
        </Grid>
      )) || (
        <Grid container spacing={3} direction='column'>
          <Grid item xs={12}>
            Il presente modulo permette di indicare se la gara preveda un
            arbitraggio ufficiale.
            <br />
            Se l'arbitraggio è stato richiesto dalla squadra di casa, non è
            possibile rimuoverlo da questa finestra.
          </Grid>
          &nbsp;
          {!!gara && (
            <Grid container item xs={12} spacing={3} alignItems='center'>
              <Grid item xs={12} md={6}>
                <center>
                  <b>{squadraCasaName}</b>
                  &nbsp;-&nbsp;
                  <b>{squadraTrasfertaName}</b>
                </center>
              </Grid>
              <Grid item xs={6} md={3}>
                <center>{gara.data || 'Data non definita'}</center>
              </Grid>
              <Grid item xs={6} md={3}>
                <center>{gara.ora || 'Ora non definita'}</center>
              </Grid>
            </Grid>
          )}
          &nbsp;
          {needsReferee && gara?.requiresReferee && (
            <Grid item xs={12} style={{ color: colours.red }}>
              <center>
                <b>
                  Attenzione: la squadra di casa ha richiesto l'arbitraggio ma
                  era già previsto l'arbitro ufficiale!
                </b>
              </center>
            </Grid>
          )}
          {!!gara && (
            <Grid
              container
              item
              xs={12}
              spacing={3}
              alignItems='center'
              justifyContent='center'
            >
              <Grid item>
                <center>Arbitraggio ufficiale:</center>
              </Grid>
              <Grid item>
                <center>
                  {gara?.requiresReferee ? (
                    <>
                      <Checkbox
                        id='requiresReferee'
                        checked={!!gara?.requiresReferee}
                      />
                      (richiesto dalla squadra di casa)
                    </>
                  ) : (
                    <Checkbox
                      id='needsReferee'
                      checked={!!needsReferee}
                      onChange={event =>
                        dispatch({
                          type: SET_NEEDS_REFEREE,
                          payload: event.target.checked,
                        })
                      }
                    />
                  )}
                </center>
              </Grid>
            </Grid>
          )}
          &nbsp;
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  iscrizioniLight: state.home.iscrizioniLight,
  needsReferee: state.calendari.needsReferee,
  requiresReferee: state.calendari.requiresReferee,
})

const ConnectedDialogArbitraggio = connect(mapStateToProps)(DialogArbitraggio)

export default ConnectedDialogArbitraggio
