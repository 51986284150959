import React, { useState } from 'react'
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@mui/material'
import WorkIcon from '@mui/icons-material/Work'
import EmailIcon from '@mui/icons-material/Email'
import EventIcon from '@mui/icons-material/Event'
import PhoneIcon from '@mui/icons-material/Phone'
import PlaceIcon from '@mui/icons-material/Place'
import PersonIcon from '@mui/icons-material/Person'
import ScheduleIcon from '@mui/icons-material/Schedule'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import LocationCityIcon from '@mui/icons-material/LocationCity'

import { colours } from '../settings/settings'

const MyTextField = ({
  id,
  value,
  onChange,
  align = 'left',
  color = 'primary',
  variant = 'filled',
  autoFocus = false,
  disabled = false,
  fullWidth = true,
  multiline = false,
  readOnly = false,
  label = '',
  placeholder = '',
  helperText = ' ',
  errorText = 'Valore non valido!',
  errorFunc = () => false,
  type = 'text',
  adornment = undefined,
  mode,
}) => {
  const [hasBlurred, setHasBlurred] = useState(false)

  const isError =
    !disabled &&
    errorFunc(value) &&
    (mode === 'update' || ((!mode || mode === 'add') && hasBlurred))

  const isReadOnly = readOnly || mode === 'view'

  // variable to manage the adornment icon
  let adornmentIcon
  switch (adornment) {
    case 'date':
      adornmentIcon = <EventIcon />
      break
    case 'time':
      adornmentIcon = <ScheduleIcon />
      break
    case 'city':
      adornmentIcon = <LocationCityIcon />
      break
    case 'zone':
      adornmentIcon = <GpsFixedIcon />
      break
    case 'person':
      adornmentIcon = <PersonIcon />
      break
    case 'phone':
      adornmentIcon = <PhoneIcon />
      break
    case 'place':
      adornmentIcon = <PlaceIcon />
      break
    case 'email':
      adornmentIcon = <EmailIcon />
      break
    case 'work':
      adornmentIcon = <WorkIcon />
      break
    default:
      adornmentIcon = <></>
  }

  return (
    <FormControl fullWidth={true} error={!!isError}>
      <TextField
        id={id}
        value={!!value || value === 0 ? value : ''}
        color={color}
        variant={variant}
        autoFocus={!!autoFocus}
        error={!!isError}
        disabled={!!disabled}
        fullWidth={!!fullWidth}
        multiline={!!multiline}
        label={label}
        placeholder={placeholder}
        onChange={event =>
          onChange &&
          onChange(
            type === 'number'
              ? event.target.value === ''
                ? undefined
                : +event.target.value
              : event.target.value
          )
        }
        onBlur={() => {
          setHasBlurred(true)
          type === 'text' &&
            value &&
            onChange &&
            onChange(value.trim().replace(/\s+/g, ' '))
        }}
        type={type}
        inputProps={{ style: { textAlign: align } }}
        InputProps={{
          readOnly: !!isReadOnly,
          style: {
            backgroundColor:
              (!!disabled && 'transparent') || (!!isError && colours.redError),
          },
          endAdornment: adornment && (
            <InputAdornment position='end'>{adornmentIcon}</InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: type === 'date' || !!value || value === 0 }}
      />
      <FormHelperText>{!!isError ? errorText : helperText}</FormHelperText>
    </FormControl>
  )
}

export default MyTextField
