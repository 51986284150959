import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import DialogMini from '../components/DialogMini'
import SortableTableHead from '../components/SortableTableHead'
import ButtonRunDownload from '../components/ButtonRunDownload'
import { colours, titlesAffiliazione as titles } from '../settings/settings'
import { ColouredButton } from '../components/Buttons'
import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import {
  SET_AFFILIAZIONI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
} from '../container/affiliazioni/types'
import {
  RESET_AFFILIAZIONE,
  SET_AFFILIAZIONE,
  SET_MODE,
} from '../container/affiliazione/types'

const columnsAffiliazioni = [
  { label: 'Codice', sortable: true, sortingField: 'codiceSocieta' },
  { label: 'Ragione sociale', sortable: false },
  { label: 'Nome breve', sortable: true, sortingField: 'nomeSocieta' },
  { label: 'Zona', sortable: true, sortingField: 'zona' },
  { label: 'Stato', sortable: true, sortingField: 'status' },
  { label: '', sortable: false },
]

const Affiliazioni = ({
  admin,
  codiceUtente,
  dummyUpdate,
  affiliazioni,
  sortingColumn,
  sortingAscending,
  deleteAlsoIscrizioni,
}) => {
  const dispatch = useDispatch()

  const history = useHistory()

  document.title = 'PGS Milano - Società'

  const [isLoading, setIsLoading] = useState(false)

  // state to manage the delete dialog
  const [openDeleteAffiliazione, setOpenDeleteAffiliazione] = useState(false)
  const [deletingAffiliazione, setDeletingAffiliazione] = useState()

  const handleClickOpenDeleteAffiliazione = a => {
    setDeletingAffiliazione(a)
    setOpenDeleteAffiliazione(true)
  }

  const handleCloseDeleteAffiliazione = () => {
    setOpenDeleteAffiliazione(false)
  }

  const confirmDeleteAffiliazione = id => {
    deleteAffiliazione(id)
    handleCloseDeleteAffiliazione()
  }

  // fetch affiliazioni
  useEffect(() => {
    const fetchAffiliazioni = async () => {
      setIsLoading(true)
      try {
        const affiliazioniData = await sendRequest(
          `affiliazioni/all`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!affiliazioniData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le società.',
          })
        } else {
          dispatch({ type: SET_AFFILIAZIONI, payload: affiliazioniData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setIsLoading(false)
    }
    if (admin && codiceUtente) {
      fetchAffiliazioni()
    } else {
      dispatch({ type: SET_AFFILIAZIONI, payload: undefined })
    }
  }, [admin, codiceUtente, dispatch, dummyUpdate])

  // edit affiliazione
  const editAffiliazione = async id => {
    if (!!id) {
      try {
        const affiliazioniData = await sendRequest(
          `affiliazioni/get/${id}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!affiliazioniData || !affiliazioniData.data) {
          dispatch({
            type: SET_ERROR,
            payload: "Impossibile trovare l'affiliazione.",
          })
        } else {
          dispatch({ type: RESET_AFFILIAZIONE })
          dispatch({ type: SET_MODE, payload: 'update' })
          dispatch({ type: SET_AFFILIAZIONE, payload: affiliazioniData.data })
          history.push('/affiliazione')
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
  }

  // delete affiliazione
  const deleteAffiliazione = async () => {
    if (!!deletingAffiliazione?.codiceSocieta) {
      if (!!admin && !!deleteAlsoIscrizioni) {
        try {
          await sendRequest(
            `iscrizioni/societa/${deletingAffiliazione.codiceSocieta}`,
            'DELETE',
            null,
            { Authorization: codiceUtente }
          )
        } catch (err) {
          console.log(err)
          dispatch({ type: SET_ERROR, payload: err.message })
        }
      }

      try {
        await sendRequest(
          `affiliazioni/${deletingAffiliazione.id}`,
          'DELETE',
          null,
          { Authorization: codiceUtente }
        )
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setDeletingAffiliazione()
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    }
  }

  return (
    <Grid container item direction='column' paddingY={2}>
      <Grid item>
        <center>
          <h2>Elenco società</h2>
        </center>
      </Grid>
      &nbsp;
      <Grid container item>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={6}>
          <center>
            <Link
              to='/affiliazione'
              style={{ textDecoration: 'none' }}
              onClick={() => dispatch({ type: RESET_AFFILIAZIONE })}
            >
              <ColouredButton
                textcolour={colours.white}
                textbold='bold'
                backgroundcolour={colours.blue}
                hovercolour={colours.blueDark}
              >
                Nuova società
              </ColouredButton>
            </Link>
          </center>
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonRunDownload
            buttonText='Scarica Excel'
            algorithm='14_GenerateExcelAffiliazioni'
            type='XLSX'
          />
        </Grid>
      </Grid>
      &nbsp;
      {(isLoading && (
        <center>
          <CircularProgress disableShrink={true} />
        </center>
      )) ||
        (!affiliazioni && (
          <Grid item>
            <center>
              <h4>Elenco delle società non trovato.</h4>
            </center>
          </Grid>
        )) ||
        (affiliazioni.length === 0 && (
          <Grid item>
            <center>
              <h4>Non è ancora stata inserita nessuna società.</h4>
            </center>
          </Grid>
        )) || (
          <Grid container padding={3}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={affiliazioni.map(a => {
                    return {
                      ...a,
                      codiceSocieta: +a.codiceSocieta,
                      status: a.isActive ? a.status : 'inactive',
                    }
                  })}
                  setTable={t =>
                    dispatch({ type: SET_AFFILIAZIONI, payload: t })
                  }
                  columns={columnsAffiliazioni}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {affiliazioni.map((a, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: colours.white,
                          ':hover': { backgroundColor: colours.greyVeryLight },
                        }}
                        onClick={() => editAffiliazione(a.id)}
                      >
                        <TableCell align='center'>{a.codiceSocieta}</TableCell>
                        <TableCell align='center'>
                          {a.denominazioneSocieta}
                        </TableCell>
                        <TableCell align='center'>{a.nomeSocieta}</TableCell>
                        <TableCell align='center'>{a.zona}</TableCell>
                        <TableCell align='center'>
                          <Chip
                            variant={
                              a.status === 'complete' || !a.isActive
                                ? 'outlined'
                                : 'filled'
                            }
                            label={
                              !a.isActive
                                ? 'inattiva'
                                : a.status === 'complete'
                                ? 'completa'
                                : a.status === 'incomplete'
                                ? 'da completare'
                                : 'sconosciuto'
                            }
                            color={
                              !a.isActive
                                ? 'error'
                                : a.status === 'complete'
                                ? 'success'
                                : a.status === 'incomplete'
                                ? 'warning'
                                : 'default'
                            }
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <Grid container item>
                            <Grid item xs>
                              <IconButton
                                style={{ color: colours.red }}
                                onClick={e => {
                                  e.stopPropagation()
                                  handleClickOpenDeleteAffiliazione(a)
                                }}
                              >
                                <Tooltip title='Elimina'>
                                  <DeleteIcon />
                                </Tooltip>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      <DialogMini
        open={openDeleteAffiliazione}
        handleClose={handleCloseDeleteAffiliazione}
        title={titles.titleDelete}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={confirmDeleteAffiliazione}
        colourBackground={colours.red}
        colourHover={colours.redDark}
        dialogDeleteAffiliazione={true}
        affiliazione={deletingAffiliazione}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  dummyUpdate: state.home.dummyUpdate,
  affiliazioni: state.affiliazioni.affiliazioni,
  sortingColumn: state.affiliazioni.sortingColumn,
  sortingAscending: state.affiliazioni.sortingAscending,
  deleteAlsoIscrizioni: state.affiliazioni.deleteAlsoIscrizioni,
})

const ConnectedAffiliazioni = connect(mapStateToProps)(Affiliazioni)

export default ConnectedAffiliazioni
