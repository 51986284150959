import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Checkbox, Grid } from '@mui/material'

import Select from './Select'
import InputField from './InputField'
import AutoComplete from './AutoComplete'
import { ColouredButton } from './Buttons'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { sortFunctions } from '../hooks/sort-functions'
import { utilsFunctions } from '../hooks/utils-functions'
import {
  SET_SELECTED_CAMPIONATO,
  SET_SELECTED_CATEGORIA,
  SET_ERROR,
} from '../container/home/types'

const DialogTabellone = ({ codiceUtente, categorieColl }) => {
  const dispatch = useDispatch()

  const history = useHistory()

  // sort functions
  const { sortAlphabetically, sortCampionati, sortCategorie, sortGironi } =
    sortFunctions()
  const sortAlphabeticallyM = useCallback(sortAlphabetically, [])
  const sortCampionatiM = useCallback(sortCampionati, [])
  const sortCategorieM = useCallback(sortCategorie, [])
  const sortGironiM = useCallback(sortGironi, [])

  // utils functions
  const { formatCampionato } = utilsFunctions()

  const [campionati, setCampionati] = useState()
  const [campionato, setCampionato] = useState()
  const [categorie, setCategorie] = useState()
  const [categoria, setCategoria] = useState()
  const [prefisso, setPrefisso] = useState()
  const [squadre, setSquadre] = useState()
  const [numeriSquadre, setNumeriSquadre] = useState()
  const [numeroSquadre, setNumeroSquadre] = useState()
  const [sfide, setSfide] = useState()
  const [fasi, setFasi] = useState()
  const [soloAndata, setSoloAndata] = useState()
  const [arbitraggio, setArbitraggio] = useState()
  const [squadreScelte, setSquadreScelte] = useState()

  // fetch campionati
  useEffect(() => {
    const fetchCampionati = async () => {
      try {
        const campionatiData = await sendRequest(`calendari/campionati`)

        if (!campionatiData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i campionati.',
          })
        } else {
          setCampionati(campionatiData?.sort((a, b) => sortCampionatiM(a, b)))
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    fetchCampionati()
  }, [dispatch, sortCampionatiM])

  // fetch categorie
  useEffect(() => {
    const fetchCategorie = async () => {
      try {
        const categorieData = await sendRequest(
          `calendari/categorie/campionato/${campionato}`
        )

        if (!categorieData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le categorie.',
          })
        } else {
          setCategorie(categorieData?.sort((a, b) => sortCategorieM(a, b)))
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (campionato) {
      fetchCategorie()
    } else {
      setCategorie()
    }
  }, [campionato, dispatch, sortCategorieM])

  // fetch numeriSquadre
  useEffect(() => {
    const fetchNumeriSquadre = async () => {
      try {
        const numeriSquadreData = await sendRequest(
          `tabelloni/numeri-squadre`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!numeriSquadreData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i numeri-squadre.',
          })
        } else {
          setNumeriSquadre(
            numeriSquadreData
              ?.filter(n => squadre && n <= squadre.length)
              ?.sort((a, b) => a - b)
          )
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    fetchNumeriSquadre()
  }, [codiceUtente, dispatch, squadre])

  // fetch sfide
  useEffect(() => {
    const fetchSfide = async () => {
      try {
        const sfideData = await sendRequest(
          `tabelloni/squadre/${numeroSquadre}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!sfideData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le sfide.',
          })
        } else {
          setSfide(sfideData)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (numeroSquadre) {
      fetchSfide()
    } else {
      setSfide()
    }
  }, [codiceUtente, dispatch, numeroSquadre])

  // update fasi
  useEffect(() => {
    setFasi(
      sfide
        ?.map(s => +s.fase)
        ?.filter((v, i, a) => a.indexOf(v) === i)
        ?.sort((a, b) => a - b)
    )
  }, [sfide])

  // update soloAndata and arbitraggio
  useEffect(() => {
    setSoloAndata(Array(fasi?.length).fill(false))
    setArbitraggio(Array(fasi?.length).fill(false))
  }, [fasi])

  // fetch squadre
  useEffect(() => {
    const fetchSquadre = async () => {
      try {
        const squadreData = await sendRequest(
          `iscrizioni/campionato/${campionato}/categoria/${categoria}`
        )

        if (!squadreData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le squadre.',
          })
        } else {
          setSquadre(
            squadreData?.sort(
              (a, b) =>
                sortGironiM(a.girone, b.girone) ||
                sortAlphabeticallyM(a.nomeSquadra, b.nomeSquadra)
            )
          )
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (campionato && categoria) {
      fetchSquadre()
    } else {
      setSquadre()
    }
  }, [campionato, categoria, dispatch, sortAlphabeticallyM, sortGironiM])

  // set random squadreScelte
  const squadreRandom = () => {
    const randomTeams = squadre
      ?.sort(() => Math.random() - 0.5)
      ?.slice(0, numeroSquadre)

    setSquadreScelte(
      sfide
        ?.filter(s => +s.fase === 1)
        ?.map((s, i) => [
          { sfida: +s.sfida, n: 1, team: randomTeams?.[i * 2] },
          { sfida: +s.sfida, n: 2, team: randomTeams?.[i * 2 + 1] },
        ])
        ?.flat()
    )
  }

  // update state
  useEffect(() => {
    setNumeroSquadre()
    setPrefisso()
    setSquadreScelte()
  }, [campionato, categoria])

  // create tabellone
  const createTabellone = async () => {
    if (!sfide?.length) {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossibile procedere: sfide non trovate!',
      })
    } else if (!fasi?.length) {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossibile procedere: fasi non trovate!',
      })
    } else if (
      sfide?.filter(s => +s.fase === 1)?.length * 2 !==
      squadreScelte?.length
    ) {
      dispatch({
        type: SET_ERROR,
        payload: `Impossibile procedere: sono state scelte ${
          squadreScelte?.length || 0
        } squadre per ${sfide?.filter(s => +s.fase === 1)?.length || 0} sfide!`,
      })
    } else if (!prefisso) {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossibile procedere: prefisso non trovato!',
      })
    } else {
      const fNumeroGara = n => `${prefisso}${n.toString().padStart(2, '0')}`
      const nomeSquadra = (nSfida, nIndice) =>
        squadreScelte?.find(s => s.sfida === nSfida && s.n === nIndice)?.team.id

      const sfideNumeriGara = sfide
        ?.map(x =>
          soloAndata?.[+x.fase - 1]
            ? [{ sfida: +x.sfida }]
            : [{ sfida: +x.sfida }, { sfida: +x.sfida }]
        )
        ?.flat()
        ?.sort((a, b) => a.sfida - b.sfida)
        ?.map((x, i) => {
          return { ...x, numeroGara: fNumeroGara(i + 1) }
        })

      sfide.map(async s => {
        const fase = +s.fase
        const faseSoloAndata = soloAndata?.[fase - 1]
        const fasePrecSoloAndata = soloAndata?.[fase - 2]
        const faseArbitraggio = arbitraggio?.[fase - 1]

        const sfida = +s.sfida
        const numeriGara = sfideNumeriGara
          ?.filter(x => x.sfida === sfida)
          ?.map(x => x.numeroGara)
          ?.sort()

        // placeholder per fasi successive
        const numeriGaraPrecV = sfide
          ?.filter(x => +x.sfidaVincente === sfida)
          ?.map(x =>
            sfideNumeriGara
              ?.filter(y => +y.sfida === +x.sfida)
              ?.map(y => y.numeroGara)
              ?.join('-')
          )
        const numeriGaraPrecP = sfide
          ?.filter(x => +x.sfidaPerdente === sfida)
          ?.map(x =>
            sfideNumeriGara
              ?.filter(y => +y.sfida === +x.sfida)
              ?.map(y => y.numeroGara)
              ?.join('-')
          )

        let placeholderCasa, placeholderTrasferta
        if (numeriGaraPrecV?.length === 2 && numeriGaraPrecP?.length === 0) {
          placeholderCasa = !fasePrecSoloAndata
            ? `Vincente gare ${numeriGaraPrecV[0]}`
            : `Vincente gara ${numeriGaraPrecV[0]}`
          placeholderTrasferta = !fasePrecSoloAndata
            ? `Vincente gare ${numeriGaraPrecV[1]}`
            : `Vincente gara ${numeriGaraPrecV[1]}`
        }
        if (numeriGaraPrecV?.length === 0 && numeriGaraPrecP?.length === 2) {
          placeholderCasa = !fasePrecSoloAndata
            ? `Perdente gare ${numeriGaraPrecP[0]}`
            : `Perdente gara ${numeriGaraPrecP[0]}`
          placeholderTrasferta = !fasePrecSoloAndata
            ? `Perdente gare ${numeriGaraPrecP[1]}`
            : `Perdente gara ${numeriGaraPrecP[1]}`
        }
        if (numeriGaraPrecV?.length === 1 && numeriGaraPrecP?.length === 0) {
          placeholderCasa = !fasePrecSoloAndata
            ? `Vincente gare ${numeriGaraPrecV[0]}`
            : `Vincente gara ${numeriGaraPrecV[0]}`
          placeholderTrasferta = 'Ripescaggio'
        }
        if (numeriGaraPrecV?.length === 0 && numeriGaraPrecP?.length === 1) {
          placeholderCasa = !fasePrecSoloAndata
            ? `Perdente gare ${numeriGaraPrecP[0]}`
            : `Perdente gara ${numeriGaraPrecP[0]}`
          placeholderTrasferta = 'Ripescaggio'
        }

        let body = {
          codiceCampionato: campionato,
          categoria: categoria,
          girone: 'finali',
          giornata: fase,
          needsReferee: faseArbitraggio,
          sfidaTabellone: sfida,
        }

        if (s.sfidaVincente) body = { ...body, sfidaVincente: s.sfidaVincente }
        if (s.sfidaPerdente) body = { ...body, sfidaPerdente: s.sfidaPerdente }

        try {
          if (faseSoloAndata) {
            // solo andata
            await sendRequest(
              `calendari/add`,
              'POST',
              JSON.stringify({
                ...body,
                numeroGara: numeriGara?.[0],
                squadraCasaID:
                  fase === 1 ? nomeSquadra(sfida, 1) : placeholderCasa,
                squadraTrasfertaID:
                  fase === 1 ? nomeSquadra(sfida, 2) : placeholderTrasferta,
              }),
              {
                'Content-Type': 'application/json',
                Authorization: codiceUtente,
              }
            )
          } else {
            // andata
            await sendRequest(
              `calendari/add`,
              'POST',
              JSON.stringify({
                ...body,
                numeroGara: numeriGara?.[1], // Ferruccio convention
                squadraCasaID:
                  fase === 1 ? nomeSquadra(sfida, 2) : placeholderTrasferta,
                squadraTrasfertaID:
                  fase === 1 ? nomeSquadra(sfida, 1) : placeholderCasa,
              }),
              {
                'Content-Type': 'application/json',
                Authorization: codiceUtente,
              }
            )

            // ritorno
            await sendRequest(
              `calendari/add`,
              'POST',
              JSON.stringify({
                ...body,
                numeroGara: numeriGara?.[0], // Ferruccio convention
                squadraCasaID:
                  fase === 1 ? nomeSquadra(sfida, 1) : placeholderCasa,
                squadraTrasfertaID:
                  fase === 1 ? nomeSquadra(sfida, 2) : placeholderTrasferta,
              }),
              {
                'Content-Type': 'application/json',
                Authorization: codiceUtente,
              }
            )
          }
        } catch (err) {
          console.log(err)
          dispatch({ type: SET_ERROR, payload: err.message })
        }
      })
      dispatch({
        type: SET_ERROR,
        payload: 'Tabellone generato correttamente!',
      })
      dispatch({ type: SET_SELECTED_CAMPIONATO, payload: campionato })
      dispatch({ type: SET_SELECTED_CATEGORIA, payload: categoria })
      history.push('/finali')
    }
  }

  return (
    <>
      &nbsp;
      <Grid container spacing={3} direction='column'>
        <Grid container item spacing={2} alignItems='center'>
          <Grid item xs={12} md={3}>
            <Select
              id='campionato'
              value={campionato}
              options={campionati}
              optionLabels={campionati?.map(c => formatCampionato(c))}
              label='Campionato'
              disabled={!campionati}
              onChange={setCampionato}
              errorText='Selezionare un campionato!'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              id='categoria'
              value={categoria}
              options={categorie}
              optionLabels={categorie?.map(a =>
                categorieColl?.filter(c => c.categoria === a)?.map(c => c.nome)
              )}
              label='Categoria'
              disabled={!categorie}
              onChange={setCategoria}
              errorText='Selezionare una categoria!'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              id='prefisso'
              value={prefisso}
              onChange={setPrefisso}
              label='Prefisso numero gara'
              disabled={!campionato || !categoria}
              errorText='Indicare un prefisso!'
              errorFunc={x => !x}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              id='numeri-squadre'
              value={numeroSquadre}
              options={numeriSquadre}
              label='Numero di squadre'
              disabled={!squadre || !numeriSquadre}
              onChange={setNumeroSquadre}
              errorText='Selezionare un valore!'
            />
          </Grid>
        </Grid>
        {fasi && soloAndata && arbitraggio && (
          <>
            <Grid
              container
              item
              xs={12}
              alignItems='center'
              justifyContent='center'
            >
              <Grid item xs={4} md={2} />
              <Grid item xs={4} md={2}>
                <center>Solo andata?</center>
              </Grid>
              <Grid item xs={4} md={2}>
                <center>Arbitraggio PGS?</center>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {fasi?.map(n => {
                return (
                  <Grid
                    key={n}
                    container
                    item
                    xs={12}
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Grid item xs={4} md={2}>
                      <center>
                        {n === fasi?.length
                          ? 'Finali'
                          : n === fasi?.length - 1
                          ? 'Semifinali'
                          : `Fase ${n}`}
                      </center>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <center>
                        <Checkbox
                          id={`solo-andata-${n}`}
                          checked={soloAndata[n - 1] || false}
                          onChange={event =>
                            setSoloAndata(
                              soloAndata.map((x, i) =>
                                i === n - 1 ? event.target.checked : x
                              )
                            )
                          }
                        />
                      </center>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <center>
                        <Checkbox
                          id={`arbitraggio-${n}`}
                          checked={arbitraggio[n - 1] || false}
                          onChange={event =>
                            setArbitraggio(
                              arbitraggio.map((x, i) =>
                                i === n - 1 ? event.target.checked : x
                              )
                            )
                          }
                        />
                      </center>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </>
        )}
        &nbsp;
        {sfide && (
          <>
            <Grid container item xs={12}>
              <Grid item xs={12} md={3}>
                <center>
                  <ColouredButton
                    textcolour={colours.white}
                    backgroundcolour={colours.red}
                    hovercolour={colours.redDark}
                    onClick={() => setSquadreScelte()}
                  >
                    Reset squadre
                  </ColouredButton>
                </center>
              </Grid>
              <Grid item xs={12} md={6}>
                <center>
                  <b>Incontri della prima fase</b>
                </center>
              </Grid>
              <Grid item xs={12} md={3}>
                <center>
                  <ColouredButton
                    textcolour={colours.white}
                    backgroundcolour={colours.blueDark}
                    hovercolour={colours.blueVeryDark}
                    onClick={squadreRandom}
                  >
                    Squadre a caso
                  </ColouredButton>
                </center>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {sfide
                ?.filter(s => +s.fase === 1)
                ?.map(s => +s.sfida)
                ?.map((sfida, i) => {
                  const i1 = (i + 1) * 2 - 1
                  const i2 = (i + 1) * 2
                  return (
                    <Grid
                      key={i}
                      container
                      item
                      xs={12}
                      alignItems='center'
                      columnSpacing={2}
                    >
                      <Grid item xs={12} md={5}>
                        <center>
                          <AutoComplete
                            id={`squadra-${i1}`}
                            value={
                              squadreScelte?.find(
                                s => s.sfida === sfida && s.n === 1
                              )?.team
                            }
                            label={`Squadra ${i1}`}
                            options={squadre}
                            groupBy={op =>
                              !!op.girone
                                ? `Girone ${+op.girone}`
                                : 'Senza girone'
                            }
                            getOptionLabel={op => op.nomeSquadra}
                            getOptionDisabled={op =>
                              squadreScelte
                                ?.map(s => s.team?.id)
                                ?.includes(op.id)
                            }
                            onChange={value => {
                              const newChoice = { sfida, n: 1, team: value }
                              setSquadreScelte(
                                squadreScelte
                                  ? [
                                      ...squadreScelte?.filter(
                                        s => s.sfida !== sfida || s.n !== 1
                                      ),
                                      newChoice,
                                    ]?.sort((a, b) => a.n - b.n)
                                  : [newChoice]
                              )
                            }}
                          />
                        </center>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <center>contro</center>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <center>
                          <AutoComplete
                            id={`squadra-${i2}`}
                            value={
                              squadreScelte?.find(
                                s => s.sfida === sfida && s.n === 2
                              )?.team
                            }
                            label={`Squadra ${i2}`}
                            options={squadre}
                            groupBy={op =>
                              !!op.girone
                                ? `Girone ${+op.girone}`
                                : 'Senza girone'
                            }
                            getOptionLabel={op => op.nomeSquadra}
                            getOptionDisabled={op =>
                              squadreScelte
                                ?.map(s => s.team.id)
                                ?.includes(op.id)
                            }
                            onChange={value => {
                              const newChoice = { sfida, n: 2, team: value }
                              setSquadreScelte(
                                squadreScelte
                                  ? [
                                      ...squadreScelte?.filter(
                                        s => s.sfida !== sfida || s.n !== 2
                                      ),
                                      newChoice,
                                    ]?.sort((a, b) => a.n - b.n)
                                  : [newChoice]
                              )
                            }}
                          />
                        </center>
                      </Grid>
                    </Grid>
                  )
                })}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          Cliccando su <b>GENERA TABELLONE</b> verranno create tutte le partite
          del tabellone a eliminazione e verranno impostate le fasi successive
          fino alle finali 1°/2° e 3°/4° posto.
        </Grid>
        <Grid item xs={12} md={4}>
          <center>
            <ColouredButton
              textbold='bold'
              textcolour={colours.white}
              backgroundcolour={colours.greenExcel}
              hovercolour={colours.greenExcelHover}
              disabled={
                !campionato ||
                !categoria ||
                !numeroSquadre ||
                !prefisso ||
                squadreScelte?.length !==
                  sfide?.filter(s => +s.fase === 1)?.length * 2
              }
              onClick={createTabellone}
            >
              Genera tabellone
            </ColouredButton>
          </center>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  categorieColl: state.home.categorie,
})

const ConnectedDialogTabellone = connect(mapStateToProps)(DialogTabellone)

export default ConnectedDialogTabellone
