import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import ButtonRun from '../components/ButtonRun'
import ButtonUpload from '../components/ButtonUpload'
import ButtonDownload from '../components/ButtonDownload'
import { TransparentButton } from '../components/Buttons'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import {
  SET_ERROR,
  SET_OPEN_UPLOAD_DIALOG,
  SET_UPLOAD_DIALOG_PHRASE,
  SET_UPLOAD_DIALOG_SUCCESS,
} from '../container/home/types'
import { SET_ISCRIZIONI } from '../container/iscrizioni/types'

const Algoritmi = ({
  codiceUtente,
  iscrizioniLight,
  backendR,
  openUploadDialog,
  uploadDialogPhrase,
  uploadDialogSuccess,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Algoritmi'

  const handleCloseUpload = () => {
    dispatch({ type: SET_OPEN_UPLOAD_DIALOG, payload: false })
    dispatch({ type: SET_UPLOAD_DIALOG_PHRASE, payload: undefined })
    dispatch({ type: SET_UPLOAD_DIALOG_SUCCESS, payload: undefined })
  }

  // fetch iscrizioni
  useEffect(() => {
    const fetchIscrizioni = async () => {
      try {
        const iscrizioniData = await sendRequest(
          `iscrizioni/campionato/${selectedCampionato}`
        )

        if (!iscrizioniData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le squadre iscritte.',
          })
        } else {
          dispatch({ type: SET_ISCRIZIONI, payload: iscrizioniData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (!!codiceUtente && !!selectedCampionato) {
      fetchIscrizioni()
    } else {
      dispatch({ type: SET_ISCRIZIONI, payload: undefined })
    }
  }, [codiceUtente, dispatch, selectedCampionato])

  const Arrow = () => {
    return (
      <Grid item xs>
        <center>
          <ArrowDownwardIcon />
        </center>
      </Grid>
    )
  }

  const GridColumn = ({ algorithm, title }) => {
    return (
      <Grid
        container
        item
        xs={12}
        md={4}
        rowSpacing={1}
        direction='column'
        alignItems='center'
      >
        &nbsp;
        <Grid item>
          <center>
            <h3>{title}</h3>
          </center>
        </Grid>
        {['01_GenerateTable'].includes(algorithm) && (
          <Grid item>
            <center>
              <TransparentButton>
                {iscrizioniLight?.filter(i =>
                  selectedCategoria &&
                  selectedCategoria !== availableCategorie[0]
                    ? i.categoria === selectedCategoria
                    : true
                )?.length || 0}{' '}
                squadre iscritte
              </TransparentButton>
            </center>
          </Grid>
        )}
        {['02_GenerateGironi', '03_GenerateCalendari'].includes(algorithm) && (
          <Grid item>
            <ButtonUpload
              buttonText='Carica CSV'
              algorithm={algorithm}
              fileName='Input'
            />
          </Grid>
        )}
        <Arrow />
        <Grid item>
          <ButtonRun
            buttonText={`Genera ${
              algorithm === '01_GenerateTable'
                ? 'tabella iscrizioni'
                : algorithm === '02_GenerateGironi'
                ? 'gironi'
                : algorithm === '03_GenerateCalendari'
                ? 'calendari'
                : algorithm
            }`}
            algorithm={algorithm}
          />
        </Grid>
        <Arrow />
        {[
          '01_GenerateTable',
          '02_GenerateGironi',
          '03_GenerateCalendari',
        ].includes(algorithm) && (
          <Grid item>
            <ButtonDownload algorithm={algorithm} type='TXT' />
          </Grid>
        )}
        <Grid item>
          <ButtonDownload algorithm={algorithm} type='CSV' />
        </Grid>
        {['02_GenerateGironi', '03_GenerateCalendari'].includes(algorithm) && (
          <Grid item>
            <ButtonDownload algorithm={algorithm} type='PDF' />
          </Grid>
        )}
        {['03_GenerateCalendari'].includes(algorithm) && (
          <Grid item>
            <ButtonDownload algorithm={algorithm} type='XLSX' />
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <Grid container direction='column' paddingY={2}>
      <Grid item>
        <center>
          <h2>Algoritmi</h2>
        </center>
      </Grid>
      <SelectionBar includeGirone={false} includeTeam={false} />
      <Grid item>
        <center>
          <h4>
            {backendR?.status === 'available'
              ? 'Gli algoritmi sono pronti a partire!'
              : backendR?.status === 'busy'
              ? `È in esecuzione l'algoritmo ${backendR?.process}`
              : 'Lo stato degli algoritmi è sconosciuto.'}
          </h4>
        </center>
      </Grid>
      <Grid item>
        <center>
          {backendR?.status === 'busy' && <CircularProgress color='inherit' />}
        </center>
      </Grid>
      <Grid container alignItems='flex-start'>
        <GridColumn algorithm='01_GenerateTable' title='Iscrizioni' />
        <GridColumn algorithm='02_GenerateGironi' title='Gironi' />
        <GridColumn algorithm='03_GenerateCalendari' title='Calendari' />
      </Grid>
      <DialogMini
        open={openUploadDialog}
        handleClose={handleCloseUpload}
        title={
          !!uploadDialogSuccess
            ? 'Caricamento effettuato'
            : 'Errore nel caricamento'
        }
        textContent={uploadDialogPhrase}
        textConfirm='Ok'
        triggerFunction={handleCloseUpload}
        colourBackground={!!uploadDialogSuccess ? undefined : colours.red}
        colourHover={!!uploadDialogSuccess ? undefined : colours.redDark}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  iscrizioniLight: state.home.iscrizioniLight,
  backendR: state.home.backendR,
  openUploadDialog: state.home.openUploadDialog,
  uploadDialogPhrase: state.home.uploadDialogPhrase,
  uploadDialogSuccess: state.home.uploadDialogSuccess,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
})

const ConnectedAlgoritmi = connect(mapStateToProps)(Algoritmi)

export default ConnectedAlgoritmi
