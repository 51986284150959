import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'

import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR } from '../container/home/types'
import { SET_DELETE_ALSO_DESIGNAZIONI } from '../container/arbitri/types'

const DialogDeleteArbitro = ({
  arbitro,
  admin,
  codiceUtente,
  deleteAlsoDesignazioni,
}) => {
  const dispatch = useDispatch()

  const [numeroDesignazioni, setNumeroDesignazioni] = useState()

  // fetch numero designazioni
  useEffect(() => {
    const fetchNumeroDesignazioni = async () => {
      try {
        const designazioniData = await sendRequest(
          `designazioni/arbitro/${arbitro.id}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!designazioniData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le designazioni.',
          })
        } else {
          setNumeroDesignazioni(designazioniData?.length)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (admin && codiceUtente && arbitro?.id) {
      fetchNumeroDesignazioni()
    } else {
      setNumeroDesignazioni()
    }
  }, [admin, arbitro, codiceUtente, dispatch])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_DELETE_ALSO_DESIGNAZIONI })
  }, [dispatch])

  return !admin ? null : (
    <>
      &nbsp;
      {(!arbitro && (
        <Grid item xs={12}>
          Nessun arbitro selezionato.
        </Grid>
      )) ||
        (numeroDesignazioni === 0 && (
          <Grid item xs={12}>
            L'arbitro selezionato non ha ancora ricevuto nessuna designazione.
          </Grid>
        )) || (
          <Grid container spacing={3} direction='column'>
            <Grid item xs={12}>
              {numeroDesignazioni === 1
                ? `C'è ${numeroDesignazioni} designazione legata`
                : `Ci sono ${numeroDesignazioni} designazioni legate`}{' '}
              a questo arbitro. Selezionare la seguente casella se si intendono
              eliminare dalla piattaforma anche tutte le designazioni; lasciarla
              vuota se invece si intendono mantenere.
            </Grid>
            &nbsp;
            {arbitro && (
              <Grid
                container
                item
                xs={12}
                spacing={3}
                alignItems='center'
                justifyContent='center'
              >
                <Grid item>
                  <center>Eliminare anche le designazioni?</center>
                </Grid>
                <Grid item>
                  <center>
                    <Checkbox
                      id='deleteAlsoDesignazioni'
                      checked={!!deleteAlsoDesignazioni}
                      onChange={event =>
                        dispatch({
                          type: SET_DELETE_ALSO_DESIGNAZIONI,
                          payload: event.target.checked,
                        })
                      }
                    />
                  </center>
                </Grid>
              </Grid>
            )}
            &nbsp;
          </Grid>
        )}
    </>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  deleteAlsoDesignazioni: state.arbitri.deleteAlsoDesignazioni,
})

const ConnectedDialogDeleteArbitro =
  connect(mapStateToProps)(DialogDeleteArbitro)

export default ConnectedDialogDeleteArbitro
