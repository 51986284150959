import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'

import GridCard from '../components/GridCard'
import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import { ColouredButton } from '../components/Buttons'
import {
  colours,
  titlesIscrizioni as titles,
  phrasesIscrizioni as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { sortFunctions } from '../hooks/sort-functions'
import { SET_ERROR } from '../container/home/types'
import { SET_ISCRIZIONI } from '../container/iscrizioni/types'
import { RESET_ISCRIZIONE } from '../container/iscrizione/types'
import { SET_AVAILABLE_GARE as SET_GARE_RISULTATO } from '../container/risultato/types'

const Dashboard = ({
  codiceUtente,
  iscrizioni,
  gareRisultato,
  dummyUpdate,
  selectedCampionatoIscrizioniAperte,
  selectedCampionatoCalendariPubblicati,
  selectedCampionato,
  selectedGare,
  presso,
  indirizzo,
  citta,
  spostamenti,
  selectedGara,
  selectedFunction,
  newData,
  newOra,
  note,
  checkPayment,
  selectedRisultato,
  parzialiCasa,
  parzialiTrasferta,
  setVintiCasaMini,
  setVintiTrasfertaMini,
  parzialeGoldenCasa,
  parzialeGoldenTrasferta,
  arbitraggi,
  selectedGareArb,
  checkPaymentArb,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Home'

  // state to manage dialogs
  const [openIndirizzo, setOpenIndirizzo] = useState(false)
  const [openSpostamento, setOpenSpostamento] = useState(false)
  const [openRisultato, setOpenRisultato] = useState(false)
  const [openArbitraggio, setOpenArbitraggio] = useState(false)

  // state to manage feedback dialog
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
  const [feedbackDialogTitle, setFeedbackDialogTitle] = useState()
  const [feedbackDialogPhrase, setFeedbackDialogPhrase] = useState()
  const [feedbackDialogSuccess, setFeedbackDialogSuccess] = useState()

  const handleClickOpenIndirizzo = () => setOpenIndirizzo(true)
  const handleClickOpenSpostamento = () => setOpenSpostamento(true)
  const handleClickOpenRisultato = () => setOpenRisultato(true)
  const handleClickOpenArbitraggio = () => setOpenArbitraggio(true)

  const handleClickOpenFeedbackDialog = (success, title, phrase) => {
    setFeedbackDialogSuccess(!!success)
    setFeedbackDialogTitle(title)
    setFeedbackDialogPhrase(phrase)
    setOpenFeedbackDialog(true)
  }

  const handleCloseIndirizzo = () => setOpenIndirizzo(false)
  const handleCloseSpostamento = () => setOpenSpostamento(false)
  const handleCloseRisultato = () => setOpenRisultato(false)
  const handleCloseArbitraggio = () => setOpenArbitraggio(false)

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false)
    setFeedbackDialogTitle()
    setFeedbackDialogPhrase()
    setFeedbackDialogSuccess()
  }

  const eDispatch = message =>
    handleClickOpenFeedbackDialog(false, 'Impossibile procedere', message)

  // sort functions
  const { sortNumeriGara } = sortFunctions()
  const sortNumeriGaraM = useCallback(sortNumeriGara, [])

  // update address
  const updateIndirizzo = async () => {
    if (!codiceUtente) {
      eDispatch('Utente non trovato!')
    } else if (!selectedGare?.length) {
      eDispatch('Nessuna gara selezionata!')
    } else if (!indirizzo) {
      eDispatch('Indirizzo non trovato!')
    } else if (!citta) {
      eDispatch('Città non trovata!')
    } else {
      try {
        for (let idGara of selectedGare) {
          await sendRequest(
            `calendari/${idGara}/indirizzo`,
            'PATCH',
            JSON.stringify({ presso, indirizzo, citta }),
            { 'Content-Type': 'application/json', Authorization: codiceUtente }
          )
        }
        handleCloseIndirizzo()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleIndirizzo,
          phrases.phrasesIndirizzoSuccess
        )
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    }
  }

  // add richiesta spostamento
  const performSpostamento = async () => {
    if (!selectedGara) {
      eDispatch('Nessuna gara selezionata!')
    } else {
      try {
        await sendRequest(
          'spostamenti/add',
          'POST',
          JSON.stringify({
            codiceUtente,
            idGara: selectedGara,
            tipologia: selectedFunction,
            nuovaData: newData,
            nuovaOra: newOra,
            note,
            checkPayment,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseSpostamento()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleSpostamentoDomanda,
          phrases.phraseSpostamentoDomandaSuccess
        )
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    }
  }

  // update risultato
  const updateRisultato = async () => {
    if (!selectedRisultato) {
      eDispatch('Nessuna gara selezionata!')
    } else {
      try {
        await sendRequest(
          `calendari/risultato/${selectedRisultato}`,
          'PATCH',
          JSON.stringify({
            parzialiCasa,
            parzialiTrasferta,
            setVintiCasaMini,
            setVintiTrasfertaMini,
            parzialeGoldenCasa,
            parzialeGoldenTrasferta,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseRisultato()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleRisultato,
          phrases.phraseRisultatoSuccess
        )
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    }
  }

  // add richiesta arbitraggio
  const addArbitraggio = async () => {
    if (!selectedGareArb?.length) {
      eDispatch('Nessuna gara selezionata!')
    } else {
      try {
        for (let idGara of selectedGareArb) {
          await sendRequest(
            `arbitraggi/add`,
            'POST',
            JSON.stringify({ idGara, checkPayment: !!checkPaymentArb }),
            { 'Content-Type': 'application/json', Authorization: codiceUtente }
          )
        }
        handleCloseArbitraggio()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleArbitraggio,
          phrases.phraseArbitraggioSuccess
        )
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    }
  }

  // fetch iscrizioni
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(
          `iscrizioni/campionato/${selectedCampionato}/societa/${codiceUtente}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le squadre iscritte.',
          })
        } else {
          dispatch({ type: SET_ISCRIZIONI, payload: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && selectedCampionato) {
      fetch()
    } else {
      dispatch({ type: SET_ISCRIZIONI })
    }
  }, [codiceUtente, dispatch, dummyUpdate, selectedCampionato])

  // fetch available gare risultato
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(
          `calendari/campionato/${selectedCampionato}/societa/${codiceUtente}/risultato`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data || !data.data) {
          dispatch({
            type: SET_ERROR,
            payload:
              'Impossibile trovare le partite di cui inviare il risultato.',
          })
        } else {
          dispatch({
            type: SET_GARE_RISULTATO,
            payload: data.data?.sort((a, b) =>
              sortNumeriGaraM(a.numeroGara, b.numeroGara)
            ),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (selectedCampionato && codiceUtente) {
      fetch()
    } else {
      dispatch({ type: SET_GARE_RISULTATO })
    }
  }, [codiceUtente, dispatch, selectedCampionato, sortNumeriGaraM])

  const GridButton = ({ text, link, onClick }) => {
    const cButton = (
      <ColouredButton
        textcolour={colours.white}
        textbold='bold'
        backgroundcolour={colours.blue}
        hovercolour={colours.blueDark}
        onClick={link ? () => {} : onClick}
      >
        {text}
      </ColouredButton>
    )

    const cLink = link ? (
      <Link to={link} onClick={onClick} style={{ textDecoration: 'none' }}>
        {cButton}
      </Link>
    ) : (
      cButton
    )

    return (
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <center>{cLink}</center>
      </Grid>
    )
  }

  return (
    <Grid container item xs={12}>
      &nbsp;
      <SelectionBar
        includeCategoria={false}
        includeGirone={false}
        includeTeam={false}
      />
      &nbsp;
      <Grid container item spacing={3} alignItems='center'>
        {selectedCampionatoIscrizioniAperte && (
          <GridButton
            text='Nuova iscrizione'
            link='/iscrizione'
            onClick={() => dispatch({ type: RESET_ISCRIZIONE })}
          />
        )}
        {selectedCampionatoCalendariPubblicati && (
          <GridButton
            text='Cambio indirizzo'
            onClick={handleClickOpenIndirizzo}
          />
        )}
        {selectedCampionatoCalendariPubblicati && (
          <GridButton
            text='Richiesta spostamento'
            onClick={handleClickOpenSpostamento}
          />
        )}
        {selectedCampionatoCalendariPubblicati && (
          <GridButton
            text='Invio risultato'
            onClick={handleClickOpenRisultato}
          />
        )}
        {selectedCampionatoCalendariPubblicati && (
          <GridButton
            text='Richiesta arbitraggio'
            onClick={handleClickOpenArbitraggio}
          />
        )}
      </Grid>
      <Grid container item marginY={2} rowSpacing={5} alignItems='stretch'>
        <GridCard
          title='Iscrizioni'
          subtitle1={`${
            iscrizioni?.filter(i => i.status === 'complete')?.length || 0
          } ${
            iscrizioni?.filter(i => i.status === 'complete')?.length === 1
              ? 'completa'
              : 'complete'
          }`}
          subtitle2={`${
            iscrizioni?.filter(i => i.status === 'incomplete')?.length || 0
          } ${
            iscrizioni?.filter(i => i.status === 'incomplete')?.length === 1
              ? 'da completare'
              : 'da completare'
          }`}
          linkTo='/iscrizioni'
          isError={
            iscrizioni?.filter(i => i.status === 'incomplete')?.length > 0
          }
        />
        <GridCard
          title='Spostamenti'
          subtitle1={`${
            spostamenti?.filter(
              s =>
                s.codiceSocietaRisposta === codiceUtente &&
                typeof s.acceptedBySocietaRisposta !== 'boolean'
            )?.length || 0
          } da confermare`}
          subtitle2={`${
            spostamenti?.filter(
              s =>
                s.codiceSocietaDomanda === codiceUtente &&
                typeof s.acceptedBySocietaRisposta !== 'boolean'
            )?.length || 0
          } in attesa`}
          linkTo='/spostamenti'
          isError={
            spostamenti?.filter(
              s =>
                s.codiceSocietaRisposta === codiceUtente &&
                typeof s.acceptedBySocietaRisposta !== 'boolean'
            )?.length > 0
          }
        />
        <GridCard
          title='Risultati'
          subtitle1={`${gareRisultato?.length || 0} da inviare`}
          isError={gareRisultato?.length > 0}
        />
        <GridCard
          title='Arbitraggi'
          subtitle1={`${arbitraggi?.length || 0} ${
            arbitraggi?.length === 1 ? 'richiesta' : 'richieste'
          }`}
          linkTo='/arbitraggi'
        />
      </Grid>
      <DialogMini
        open={openIndirizzo}
        handleClose={handleCloseIndirizzo}
        title={titles.titleIndirizzo}
        textUndo='Chiudi'
        textConfirm='Conferma'
        triggerFunction={updateIndirizzo}
        dialogCambioIndirizzo={true}
      />
      <DialogMini
        open={openSpostamento}
        handleClose={handleCloseSpostamento}
        title={titles.titleSpostamentoDomanda}
        textUndo='Chiudi'
        textConfirm='Conferma'
        triggerFunction={performSpostamento}
        dialogSpostamento={true}
      />
      <DialogMini
        open={openRisultato}
        handleClose={handleCloseRisultato}
        title={titles.titleRisultato}
        textUndo='Chiudi'
        textConfirm='Conferma'
        triggerFunction={updateRisultato}
        dialogRisultato={true}
      />
      <DialogMini
        open={openArbitraggio}
        handleClose={handleCloseArbitraggio}
        title={titles.titleArbitraggio}
        textUndo='Chiudi'
        textConfirm='Conferma'
        triggerFunction={addArbitraggio}
        dialogRichiestaArbitraggio={true}
      />
      <DialogMini
        open={openFeedbackDialog}
        handleClose={handleCloseFeedbackDialog}
        title={feedbackDialogTitle}
        textContent={feedbackDialogPhrase}
        textConfirm='Ok'
        triggerFunction={handleCloseFeedbackDialog}
        colourBackground={!!feedbackDialogSuccess ? undefined : colours.red}
        colourHover={!!feedbackDialogSuccess ? undefined : colours.redDark}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  iscrizioni: state.iscrizioni.iscrizioni,
  gareRisultato: state.risultato.availableGare,
  dummyUpdate: state.home.dummyUpdate,
  selectedCampionatoIscrizioniAperte:
    state.home.selectedCampionatoIscrizioniAperte,
  selectedCampionatoCalendariPubblicati:
    state.home.selectedCampionatoCalendariPubblicati,
  selectedCampionato: state.home.selectedCampionato,
  selectedGare: state.calendari.selectedGare,
  presso: state.calendari.presso,
  indirizzo: state.calendari.indirizzo,
  citta: state.calendari.citta,
  spostamenti: state.spostamenti.spostamenti,
  selectedGara: state.spostamenti.selectedGara,
  selectedFunction: state.spostamenti.selectedFunction,
  newData: state.spostamenti.newData,
  newOra: state.spostamenti.newOra,
  note: state.spostamenti.note,
  checkPayment: state.spostamenti.checkPayment,
  selectedRisultato: state.risultato.selectedGara,
  parzialiCasa: state.risultato.parzialiCasa,
  parzialiTrasferta: state.risultato.parzialiTrasferta,
  setVintiCasaMini: state.risultato.setVintiCasaMini,
  setVintiTrasfertaMini: state.risultato.setVintiTrasfertaMini,
  parzialeGoldenCasa: state.risultato.parzialeGoldenCasa,
  parzialeGoldenTrasferta: state.risultato.parzialeGoldenTrasferta,
  arbitraggi: state.arbitraggi.arbitraggi,
  selectedGareArb: state.arbitraggi.selectedGare,
  checkPaymentArb: state.arbitraggi.checkPayment,
})

const ConnectedDashboard = connect(mapStateToProps)(Dashboard)

export default ConnectedDashboard
