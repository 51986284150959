import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'

import DialogMini from './DialogMini'
import InputField from './InputField'
import SortableTableHead from './SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { errorFunctions } from '../hooks/error-functions'
import { sortFunctions } from '../hooks/sort-functions'
import { utilsFunctions } from '../hooks/utils-functions'
import { SET_ERROR } from '../container/home/types'
import {
  SET_RENDICONTO,
  SET_ID_GARE_ARBITRO,
  SET_ID_GARE_RITARDO,
} from '../container/rendiconti/types'

const DialogRendiconto = ({
  admin,
  codiceUtente,
  affiliazioniLight,
  rendicontoMode,
  rendiconto,
  idGareArbitro,
  idGareRitardo,
}) => {
  const dispatch = useDispatch()

  const isView = rendicontoMode === 'view'

  let columnsQuota = [
    { label: 'Causale', sortable: false },
    { label: 'Importo', sortable: false },
    { label: 'Note', sortable: false },
    { label: 'Pagamento', sortable: false },
  ]

  let columnsIscrizioni = [
    { label: 'Campionato', sortable: false },
    { label: 'Categoria', sortable: false },
    { label: 'Importo', sortable: false },
    { label: 'Note', sortable: false },
    { label: 'Pagamento', sortable: false },
  ]

  let columnsSpostamenti = [
    { label: 'Campionato', sortable: false },
    { label: 'Categoria', sortable: false },
    { label: 'Gara', sortable: false },
    { label: 'Importo', sortable: false },
    { label: 'Note', sortable: false },
    { label: 'Pagamento', sortable: false },
  ]

  let columnsGareArbitro = [
    { label: 'Campionato', sortable: false },
    { label: 'Categoria', sortable: false },
    { label: 'Gara', sortable: false },
    { label: 'Importo', sortable: false },
    { label: 'Note', sortable: false },
    { label: 'Pagamento', sortable: false },
  ]

  let columnsRisultatiRitardo = [
    { label: 'Campionato', sortable: false },
    { label: 'Categoria', sortable: false },
    { label: 'Gara', sortable: false },
    { label: 'Importo', sortable: false },
    { label: 'Note', sortable: false },
    { label: 'Pagamento', sortable: false },
  ]

  let columnsSanzioni = [
    { label: 'Descrizione', sortable: false },
    { label: 'Importo', sortable: false },
    { label: 'Note', sortable: false },
    { label: 'Pagamento', sortable: false },
  ]

  const codiceSocieta = rendiconto?.codiceSocieta

  const [loadingIscrizioni, setLoadingIscrizioni] = useState()
  const [loadingSpostamenti, setLoadingSpostamenti] = useState()
  const [loadingGareArbitro, setLoadingGareArbitro] = useState()
  const [loadingGareRitardo, setLoadingGareRitardo] = useState()
  const loadingSanzioni = false
  const [nomeSocieta, setNomeSocieta] = useState()
  const [rendicontoQuota, setRendicontoQuota] = useState()
  const [rendicontoIscrizioni, setRendicontoIscrizioni] = useState()
  const [rendicontoSpostamenti, setRendicontoSpostamenti] = useState()
  const [rendicontoGareArbitro, setRendicontoGareArbitro] = useState()
  const [rendicontoRisultatiRitardo, setRendicontoRisultatiRitardo] = useState()
  const [rendicontoSanzioni, setRendicontoSanzioni] = useState()
  const [societaIscrizioni, setSocietaIscrizioni] = useState()
  const [societaSpostamenti, setSocietaSpostamenti] = useState()
  const [societaGareArbitro, setSocietaGareArbitro] = useState()
  const [societaGareRitardo, setSocietaGareRitardo] = useState()
  const [newSanzione, setNewSanzione] = useState()

  // state to manage feedback dialog
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
  const [feedbackDialogTitle, setFeedbackDialogTitle] = useState()
  const [feedbackDialogPhrase, setFeedbackDialogPhrase] = useState()
  const [feedbackDialogSuccess, setFeedbackDialogSuccess] = useState()

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false)
    setFeedbackDialogTitle()
    setFeedbackDialogPhrase()
    setFeedbackDialogSuccess()
  }

  const { errorNumber0 } = errorFunctions()
  const errorImporto = useCallback(x => x && errorNumber0(x), [errorNumber0])

  // sort functions
  const { sortAlphabetically, sortCampionati, sortCategorie, sortNumeriGara } =
    sortFunctions()
  const sortAlphabeticallyM = useCallback(sortAlphabetically, [])
  const sortCampionatiM = useCallback(sortCampionati, [])
  const sortCategorieM = useCallback(sortCategorie, [])
  const sortNumeriGaraM = useCallback(sortNumeriGara, [])

  // utils functions
  const { formatCampionato } = utilsFunctions()

  const mySum = x => x?.reduce((a, b) => (a || 0) + (b || 0), 0)
  const myFormat = x => (typeof x === 'number' ? `€ ${x?.toFixed(2)}` : '-')

  const totTotaleDovuto = () =>
    mySum(rendiconto?.voci?.map(v => v.importo)) || 0
  const totTotalePagato = () =>
    mySum(rendiconto?.voci?.filter(v => v.datePaid)?.map(v => v.importo)) || 0

  // fetch iscrizioni
  useEffect(() => {
    const fetchIscrizioni = async () => {
      setLoadingIscrizioni(true)
      try {
        const data = await sendRequest(
          `iscrizioni/societa/${codiceSocieta}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le iscrizioni.',
          })
        } else {
          setSocietaIscrizioni(data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setLoadingIscrizioni(false)
    }
    if (codiceUtente && codiceSocieta) {
      fetchIscrizioni()
    } else {
      setSocietaIscrizioni()
    }
  }, [codiceSocieta, codiceUtente, dispatch])

  // fetch spostamenti
  useEffect(() => {
    const fetchSpostamenti = async () => {
      setLoadingSpostamenti(true)
      try {
        const data = await sendRequest(
          `spostamenti/societa/${codiceSocieta}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare gli spostamenti.',
          })
        } else {
          setSocietaSpostamenti(data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setLoadingSpostamenti(false)
    }
    if (codiceUtente && codiceSocieta) {
      fetchSpostamenti()
    } else {
      setSocietaSpostamenti()
    }
  }, [codiceSocieta, codiceUtente, dispatch])

  // fetch gare arbitro
  useEffect(() => {
    const fetchGareArbitro = async () => {
      setLoadingGareArbitro(true)
      try {
        const data = await sendRequest(
          `calendari/many`,
          'POST',
          JSON.stringify({ ids: idGareArbitro }),
          { 'Content-Type': 'application/json' }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le partite.',
          })
        } else {
          setSocietaGareArbitro(data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setLoadingGareArbitro(false)
    }
    if (idGareArbitro) {
      fetchGareArbitro()
    } else {
      setSocietaGareArbitro()
    }
  }, [dispatch, idGareArbitro])

  // fetch gare ritardo
  useEffect(() => {
    const fetchGareRitardo = async () => {
      setLoadingGareRitardo(true)
      try {
        const data = await sendRequest(
          `calendari/many`,
          'POST',
          JSON.stringify({ ids: idGareRitardo }),
          { 'Content-Type': 'application/json' }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le partite.',
          })
        } else {
          setSocietaGareRitardo(data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setLoadingGareRitardo(false)
    }
    if (idGareRitardo) {
      fetchGareRitardo()
    } else {
      setSocietaGareRitardo()
    }
  }, [dispatch, idGareRitardo])

  // update nomeSocieta
  useEffect(() => {
    setNomeSocieta(
      admin &&
        affiliazioniLight?.find(a => a.codiceSocieta === codiceSocieta)
          ?.nomeSocieta
    )
  }, [admin, affiliazioniLight, codiceSocieta])

  // filter quota
  useEffect(() => {
    setRendicontoQuota(
      rendiconto?.voci?.filter(v => v.tipologia === 'quota-segreteria')
    )
  }, [rendiconto])

  // filter iscrizioni
  useEffect(() => {
    setRendicontoIscrizioni(
      rendiconto?.voci
        ?.filter(v => v.tipologia === 'iscrizione')
        ?.map(v => {
          const vInfo = societaIscrizioni?.find(s => s._id === v.idSquadra)
          return {
            ...v,
            codiceCampionato: vInfo?.codiceCampionato,
            categoria: vInfo?.categoria,
          }
        })
        ?.sort(
          (a, b) =>
            -sortCampionatiM(a.codiceCampionato, b.codiceCampionato) ||
            sortCategorieM(a.categoria, b.categoria)
        )
    )
  }, [rendiconto, societaIscrizioni, sortCampionatiM, sortCategorieM])

  // filter spostamenti
  useEffect(() => {
    setRendicontoSpostamenti(
      rendiconto?.voci
        ?.filter(v => v.tipologia === 'spostamento')
        ?.map(v => {
          const vInfo = societaSpostamenti?.find(s => s._id === v.idSpostamento)
          return {
            ...v,
            codiceCampionato: vInfo?.codiceCampionato,
            categoria: vInfo?.categoria,
            numeroGara: vInfo?.numeroGara,
          }
        })
        ?.sort(
          (a, b) =>
            -sortCampionatiM(a.codiceCampionato, b.codiceCampionato) ||
            sortCategorieM(a.categoria, b.categoria) ||
            sortNumeriGaraM(a.numeroGara, b.numeroGara)
        )
    )
  }, [
    rendiconto,
    societaSpostamenti,
    sortCampionatiM,
    sortCategorieM,
    sortNumeriGaraM,
  ])

  // filter gare arbitro
  useEffect(() => {
    setRendicontoGareArbitro(
      rendiconto?.voci
        ?.filter(
          v =>
            v.tipologia === 'needsReferee' || v.tipologia === 'requiresReferee'
        )
        ?.map(v => {
          const vInfo = societaGareArbitro?.find(s => s._id === v.idGara)
          return {
            ...v,
            codiceCampionato: vInfo?.codiceCampionato,
            categoria: vInfo?.categoria,
            numeroGara: vInfo?.numeroGara,
          }
        })
        ?.sort(
          (a, b) =>
            -sortCampionatiM(a.codiceCampionato, b.codiceCampionato) ||
            sortCategorieM(a.categoria, b.categoria) ||
            sortNumeriGaraM(a.numeroGara, b.numeroGara)
        )
    )
  }, [
    rendiconto,
    societaGareArbitro,
    sortCampionatiM,
    sortCategorieM,
    sortNumeriGaraM,
  ])

  // filter risultati ritardo
  useEffect(() => {
    setRendicontoRisultatiRitardo(
      rendiconto?.voci
        ?.filter(v => v.tipologia === 'risultato-ritardo')
        ?.map(v => {
          const vInfo = societaGareRitardo?.find(s => s._id === v.idGara)
          return {
            ...v,
            codiceCampionato: vInfo?.codiceCampionato,
            categoria: vInfo?.categoria,
            numeroGara: vInfo?.numeroGara,
          }
        })
        ?.sort(
          (a, b) =>
            -sortCampionatiM(a.codiceCampionato, b.codiceCampionato) ||
            sortCategorieM(a.categoria, b.categoria) ||
            sortNumeriGaraM(a.numeroGara, b.numeroGara)
        )
    )
  }, [
    rendiconto,
    societaGareRitardo,
    sortCampionatiM,
    sortCategorieM,
    sortNumeriGaraM,
  ])

  // filter sanzioni
  useEffect(() => {
    setRendicontoSanzioni(
      rendiconto?.voci
        ?.filter(v => v.tipologia === 'sanzione')
        ?.sort((a, b) => sortAlphabeticallyM(a.descrizione, b.descrizione))
    )
  }, [rendiconto, sortAlphabeticallyM])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => {
      dispatch({ type: SET_RENDICONTO })
      dispatch({ type: SET_ID_GARE_ARBITRO })
      dispatch({ type: SET_ID_GARE_RITARDO })
    }
  }, [dispatch])

  const fTitle = title => (
    <Grid item xs={12}>
      <center>
        <h4>{title}</h4>
      </center>
    </Grid>
  )

  const alignProps = { align: 'center' }
  const sxProps = { sx: { whiteSpace: 'nowrap' } }
  const fStyleProps = (x, i) => {
    return {
      style: {
        borderBottomColor:
          i < x.length - 1 &&
          x[i].codiceCampionato !== x[i + 1].codiceCampionato &&
          colours.greyVeryDark,
      },
    }
  }

  const rDispatch = (x, r) =>
    dispatch({
      type: SET_RENDICONTO,
      payload: {
        ...rendiconto,
        voci: rendiconto?.voci?.map(v =>
          v._id === r._id ? { ...v, ...x } : v
        ),
      },
    })

  const rInputField = (r, field, aDispatch, type, errorFunc) => (
    <InputField
      align='center'
      variant='standard'
      placeholder={type === 'number' ? '0.00' : ''}
      value={
        (type === 'date' && r[field]?.split('/').reverse().join('-')) ||
        (type === 'number' &&
          (typeof r[field] === 'number' ? r.importo : undefined)) ||
        r[field]
      }
      onChange={x =>
        aDispatch({
          [field]:
            type === 'date'
              ? x || undefined
              : type === 'number'
              ? +x?.toFixed(2)
              : x,
        })
      }
      errorFunc={errorFunc}
      helperText=''
      errorText=''
      type={type}
      readOnly={isView}
    />
  )

  return (
    <>
      <Grid container item xs={12} spacing={3}>
        <Grid container item xs={12} alignItems='center'>
          <Grid item xs={12} md={6}>
            <center>
              {admin ? (
                <>
                  {codiceSocieta} - <b>{nomeSocieta}</b> - Stagione{' '}
                  {rendiconto?.stagione}
                </>
              ) : (
                <>Stagione {rendiconto?.stagione}</>
              )}
            </center>
          </Grid>
          <Grid item xs={12} md={3}>
            <center>
              <b>Dovuto:&nbsp;{myFormat(totTotaleDovuto() || 0)}</b>
            </center>
          </Grid>
          <Grid item xs={12} md={3}>
            <center>
              <b>Pagato:&nbsp;{myFormat(totTotalePagato() || 0)}</b>
            </center>
          </Grid>
        </Grid>
        &nbsp;
        {rendicontoQuota?.length > 0 && (
          <Grid container item xs={12} alignItems='center'>
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={rendicontoQuota}
                  columns={columnsQuota}
                />
                <TableBody>
                  {rendicontoQuota?.map((r, index) => {
                    const cellProps = {
                      align: 'center',
                      sx: { whiteSpace: 'nowrap' },
                    }

                    const aDispatch = x => rDispatch(x, r)

                    const aInputField = (field, type, errorFunc) =>
                      rInputField(r, field, aDispatch, type, errorFunc)

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            r.importo > 0 && !r.datePaid && colours.highlight,
                        }}
                      >
                        <TableCell {...cellProps}>
                          Quota di segreteria
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.1}
                        >
                          {isView || !admin
                            ? myFormat(r.importo)
                            : aInputField('importo', 'number', errorImporto)}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.1}
                        >
                          {isView || !admin
                            ? myFormat(r.note)
                            : aInputField('note')}
                        </TableCell>
                        <TableCell {...cellProps}>
                          {aInputField('datePaid', 'date')}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        {(loadingIscrizioni && (
          <Grid item xs={12}>
            <center>
              <CircularProgress disableShrink={true} />
            </center>
          </Grid>
        )) ||
          (rendicontoIscrizioni?.length > 0 && (
            <Grid container item xs={12} alignItems='center'>
              {fTitle('Iscrizioni')}
              <TableContainer component={Paper}>
                <Table size='small'>
                  <SortableTableHead
                    table={rendicontoIscrizioni}
                    columns={columnsIscrizioni}
                  />
                  <TableBody>
                    {rendicontoIscrizioni?.map((r, index) => {
                      const cellProps = {
                        ...alignProps,
                        ...sxProps,
                        ...fStyleProps(rendicontoIscrizioni, index),
                      }

                      const aDispatch = x => rDispatch(x, r)

                      const aInputField = (field, type, errorFunc) =>
                        rInputField(r, field, aDispatch, type, errorFunc)

                      return (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor:
                              r.importo > 0 && !r.datePaid && colours.highlight,
                          }}
                        >
                          <TableCell {...cellProps}>
                            {formatCampionato(r.codiceCampionato) || '??'}
                          </TableCell>
                          <TableCell {...cellProps}>
                            {r.categoria || '??'}
                          </TableCell>
                          <TableCell
                            {...cellProps}
                            width={window.innerWidth * 0.1}
                          >
                            {isView || !admin
                              ? myFormat(r.importo)
                              : aInputField('importo', 'number', errorImporto)}
                          </TableCell>
                          <TableCell
                            {...cellProps}
                            width={window.innerWidth * 0.1}
                          >
                            {isView || !admin
                              ? myFormat(r.note)
                              : aInputField('note')}
                          </TableCell>
                          <TableCell {...cellProps}>
                            {aInputField('datePaid', 'date')}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ))}
        {(loadingSpostamenti && (
          <Grid item xs={12}>
            <center>
              <CircularProgress disableShrink={true} />
            </center>
          </Grid>
        )) ||
          (rendicontoSpostamenti?.length > 0 && (
            <Grid container item xs={12} alignItems='center'>
              {fTitle('Spostamenti')}
              <TableContainer component={Paper}>
                <Table size='small'>
                  <SortableTableHead
                    table={rendicontoSpostamenti}
                    columns={columnsSpostamenti}
                  />
                  <TableBody>
                    {rendicontoSpostamenti?.map((r, index) => {
                      const cellProps = {
                        ...alignProps,
                        ...sxProps,
                        ...fStyleProps(rendicontoSpostamenti, index),
                      }

                      const aDispatch = x => rDispatch(x, r)

                      const aInputField = (field, type, errorFunc) =>
                        rInputField(r, field, aDispatch, type, errorFunc)

                      return (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor:
                              r.importo > 0 && !r.datePaid && colours.highlight,
                          }}
                        >
                          <TableCell {...cellProps}>
                            {formatCampionato(r.codiceCampionato) || '??'}
                          </TableCell>
                          <TableCell {...cellProps}>
                            {r.categoria || '??'}
                          </TableCell>
                          <TableCell {...cellProps}>
                            {r.numeroGara || '??'}
                          </TableCell>
                          <TableCell
                            {...cellProps}
                            width={window.innerWidth * 0.1}
                          >
                            {isView || !admin
                              ? myFormat(r.importo)
                              : aInputField('importo', 'number', errorImporto)}
                          </TableCell>
                          <TableCell
                            {...cellProps}
                            width={window.innerWidth * 0.1}
                          >
                            {isView || !admin
                              ? myFormat(r.note)
                              : aInputField('note')}
                          </TableCell>
                          <TableCell {...cellProps}>
                            {aInputField('datePaid', 'date')}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ))}
        {(loadingGareArbitro && (
          <Grid item xs={12}>
            <center>
              <CircularProgress disableShrink={true} />
            </center>
          </Grid>
        )) ||
          (rendicontoGareArbitro?.length > 0 && (
            <Grid container item xs={12} alignItems='center'>
              {fTitle('Tasse gara')}
              <TableContainer component={Paper}>
                <Table size='small'>
                  <SortableTableHead
                    table={rendicontoGareArbitro}
                    columns={columnsGareArbitro}
                  />
                  <TableBody>
                    {rendicontoGareArbitro?.map((r, index) => {
                      const cellProps = {
                        ...alignProps,
                        ...sxProps,
                        ...fStyleProps(rendicontoGareArbitro, index),
                      }

                      const aDispatch = x => rDispatch(x, r)

                      const aInputField = (field, type, errorFunc) =>
                        rInputField(r, field, aDispatch, type, errorFunc)

                      return (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor:
                              r.importo > 0 && !r.datePaid && colours.highlight,
                          }}
                        >
                          <TableCell {...cellProps}>
                            {formatCampionato(r.codiceCampionato) || '??'}
                          </TableCell>
                          <TableCell {...cellProps}>
                            {r.categoria || '??'}
                          </TableCell>
                          <TableCell {...cellProps}>
                            {r.numeroGara || '??'}
                          </TableCell>
                          <TableCell
                            {...cellProps}
                            width={window.innerWidth * 0.1}
                          >
                            {isView || !admin
                              ? myFormat(r.importo)
                              : aInputField('importo', 'number', errorImporto)}
                          </TableCell>
                          <TableCell
                            {...cellProps}
                            width={window.innerWidth * 0.1}
                          >
                            {isView || !admin
                              ? myFormat(r.note)
                              : aInputField('note')}
                          </TableCell>
                          <TableCell {...cellProps}>
                            {aInputField('datePaid', 'date')}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ))}
        {(loadingGareRitardo && (
          <Grid item xs={12}>
            <center>
              <CircularProgress disableShrink={true} />
            </center>
          </Grid>
        )) ||
          (rendicontoRisultatiRitardo?.length > 0 && (
            <Grid container item xs={12} alignItems='center'>
              {fTitle('Sanzioni per risultati inviati in ritardo')}
              <TableContainer component={Paper}>
                <Table size='small'>
                  <SortableTableHead
                    table={rendicontoRisultatiRitardo}
                    columns={columnsRisultatiRitardo}
                  />
                  <TableBody>
                    {rendicontoRisultatiRitardo?.map((r, index) => {
                      const cellProps = {
                        ...alignProps,
                        ...sxProps,
                        ...fStyleProps(rendicontoRisultatiRitardo, index),
                      }

                      const aDispatch = x => rDispatch(x, r)

                      const aInputField = (field, type, errorFunc) =>
                        rInputField(r, field, aDispatch, type, errorFunc)

                      return (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor:
                              r.importo > 0 && !r.datePaid && colours.highlight,
                          }}
                        >
                          <TableCell {...cellProps}>
                            {formatCampionato(r.codiceCampionato) || '??'}
                          </TableCell>
                          <TableCell {...cellProps}>
                            {r.categoria || '??'}
                          </TableCell>
                          <TableCell {...cellProps}>
                            {r.numeroGara || '??'}
                          </TableCell>
                          <TableCell
                            {...cellProps}
                            width={window.innerWidth * 0.1}
                          >
                            {isView || !admin
                              ? myFormat(r.importo)
                              : aInputField('importo', 'number', errorImporto)}
                          </TableCell>
                          <TableCell
                            {...cellProps}
                            width={window.innerWidth * 0.1}
                          >
                            {isView || !admin
                              ? myFormat(r.note)
                              : aInputField('note')}
                          </TableCell>
                          <TableCell {...cellProps}>
                            {aInputField('datePaid', 'date')}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ))}
        {(loadingSanzioni && (
          <Grid item xs={12}>
            <center>
              <CircularProgress disableShrink={true} />
            </center>
          </Grid>
        )) ||
          (rendicontoSanzioni?.length > 0 && (
            <Grid container item xs={12} alignItems='center'>
              {fTitle('Sanzioni')}
              <TableContainer component={Paper}>
                <Table size='small'>
                  <SortableTableHead
                    table={rendicontoSanzioni}
                    columns={columnsSanzioni}
                  />
                  <TableBody>
                    {rendicontoSanzioni?.map((r, index) => {
                      const cellProps = { ...alignProps, ...sxProps }

                      const aDispatch = x => rDispatch(x, r)

                      const aInputField = (field, type, errorFunc) =>
                        rInputField(r, field, aDispatch, type, errorFunc)

                      return (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor:
                              r.importo > 0 && !r.datePaid && colours.highlight,
                          }}
                        >
                          <TableCell {...cellProps}>
                            {r.descrizione || '??'}
                          </TableCell>
                          <TableCell
                            {...cellProps}
                            width={window.innerWidth * 0.1}
                          >
                            {isView || !admin
                              ? myFormat(r.importo)
                              : aInputField('importo', 'number', errorImporto)}
                          </TableCell>
                          <TableCell
                            {...cellProps}
                            width={window.innerWidth * 0.1}
                          >
                            {isView || !admin
                              ? myFormat(r.note)
                              : aInputField('note')}
                          </TableCell>
                          <TableCell {...cellProps}>
                            {aInputField('datePaid', 'date')}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ))}
        {admin && (
          <Grid container item xs={12} alignItems='center'>
            {newSanzione ? (
              <>
                <Grid item xs={10}>
                  <center>
                    <InputField
                      id='descrizione'
                      value={newSanzione}
                      onChange={setNewSanzione}
                      label={columnsSanzioni?.[0].label}
                      helperText=''
                    />
                  </center>
                </Grid>
                <Grid item xs={1}>
                  <center>
                    <IconButton
                      style={{ color: colours.blueDark }}
                      onClick={() => {
                        dispatch({
                          type: SET_RENDICONTO,
                          payload: {
                            ...rendiconto,
                            voci: [
                              ...rendiconto?.voci,
                              {
                                _id: new Date().getTime(), // temporary id, will be reset by the backend
                                tipologia: 'sanzione',
                                descrizione: newSanzione,
                              },
                            ],
                          },
                        })
                        setNewSanzione()
                      }}
                    >
                      <Tooltip key='Salva' title='Salva'>
                        <SaveIcon />
                      </Tooltip>
                    </IconButton>
                  </center>
                </Grid>
                <Grid item xs={1}>
                  <center>
                    <IconButton
                      style={{ color: colours.red }}
                      onClick={() => setNewSanzione()}
                    >
                      <Tooltip key='Annulla' title='Annulla'>
                        <ClearIcon />
                      </Tooltip>
                    </IconButton>
                  </center>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <center>
                  <IconButton
                    size='small'
                    style={{ color: colours.black }}
                    onClick={() =>
                      setNewSanzione(
                        'Comunicato XX del DD/MM/YYYY, provvedimento NN'
                      )
                    }
                  >
                    <AddIcon />
                    &nbsp; Aggiungi sanzione
                  </IconButton>
                </center>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <DialogMini
        open={openFeedbackDialog}
        handleClose={handleCloseFeedbackDialog}
        title={feedbackDialogTitle}
        textContent={feedbackDialogPhrase}
        textConfirm='Ok'
        triggerFunction={handleCloseFeedbackDialog}
        colourBackground={!!feedbackDialogSuccess ? undefined : colours.red}
        colourHover={!!feedbackDialogSuccess ? undefined : colours.redDark}
      />
    </>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  affiliazioniLight: state.home.affiliazioniLight,
  rendicontoMode: state.rendiconti.rendicontoMode,
  rendiconto: state.rendiconti.rendiconto,
  idGareArbitro: state.rendiconti.idGareArbitro,
  idGareRitardo: state.rendiconti.idGareRitardo,
})

const ConnectedDialogRendiconto = connect(mapStateToProps)(DialogRendiconto)

export default ConnectedDialogRendiconto
