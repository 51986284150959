import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid, Radio } from '@mui/material'

import Select from './Select'
import InputField from './InputField'
import AutoComplete from './AutoComplete'
import { sortFunctions } from '../hooks/sort-functions'
import { utilsFunctions } from '../hooks/utils-functions'
import {
  RESET_GIRONI,
  SET_NEW_TEAM,
  SET_NEW_GIRONE,
  SET_NEW_POSIZIONE,
  SET_NEW_GIRONE_ADD,
  SET_NEW_POSIZIONE_ADD,
  SET_SELECTED_FUNCTION,
} from '../container/gironi/types'

const DialogGirone = ({
  team,
  newTeam,
  newGirone,
  newPosizione,
  newGironeAdd,
  newPosizioneAdd,
  availableFunctions,
  selectedFunction,
  iscrizioniGironi,
}) => {
  const dispatch = useDispatch()

  // error functions
  const errorNewGirone = x =>
    x &&
    (iscrizioniGironi
      .filter(i => i.categoria === team?.categoria)
      .map(t => t.girone)
      .includes(x) ||
      !/^[a-zA-Z0-9-_]+$/.test(x))

  // sort functions
  const { sortNumerically, sortGironi } = sortFunctions()

  // utils functions
  const { rangeFun } = utilsFunctions()

  // Set selectedFunction
  useEffect(() => {
    if (!selectedFunction)
      dispatch({ type: SET_SELECTED_FUNCTION, payload: availableFunctions[0] })
  }, [availableFunctions, dispatch, selectedFunction])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: RESET_GIRONI })
  }, [dispatch])

  return (
    <>
      &nbsp;
      <Grid container spacing={5} direction='column'>
        <Grid container item xs={12} spacing={3} alignItems='center'>
          <Grid item xs={12} md={6}>
            <center>
              <b>{team?.nomeSquadra}</b>
            </center>
          </Grid>
          <Grid item xs md>
            <center>
              Girone <b>{team?.girone}</b>
            </center>
          </Grid>
          <Grid item xs md>
            <center>
              Posizione <b>{team?.posizione}</b>
            </center>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} alignItems='center'>
          <Grid container item xs={12} md={6} alignItems='center'>
            <Grid item xs={2}>
              <center>
                <Radio
                  id='girone-posizione'
                  value={availableFunctions[0]}
                  checked={selectedFunction === availableFunctions[0]}
                  onChange={event => {
                    dispatch({
                      type: SET_SELECTED_FUNCTION,
                      payload: event.target.value,
                    })
                  }}
                />
              </center>
            </Grid>
            <Grid item xs>
              Scegli girone e posizione...
            </Grid>
          </Grid>
          <Grid item xs md>
            <center>
              <Select
                id='girone'
                value={newGirone}
                options={iscrizioniGironi
                  ?.filter(i => i.categoria === team?.categoria && !!i.girone)
                  ?.map(t => t.girone)
                  ?.filter((v, i, s) => s.indexOf(v) === i)
                  ?.sort((a, b) => +a - +b)}
                label='Girone'
                onChange={value => {
                  dispatch({ type: SET_NEW_GIRONE, payload: value })
                  dispatch({ type: SET_NEW_POSIZIONE, payload: undefined })
                }}
                errorText='Selezionare un girone!'
                disabled={selectedFunction !== availableFunctions[0]}
              />
            </center>
          </Grid>
          <Grid item xs md>
            <center>
              <Select
                id='posizione'
                value={newPosizione}
                options={
                  !!newGirone
                    ? rangeFun(
                        Math.max(
                          ...iscrizioniGironi
                            ?.filter(
                              i =>
                                i.categoria === team?.categoria &&
                                i.girone === newGirone
                            )
                            ?.map(t => t.posizione)
                        ) + 1
                      )
                    : undefined
                }
                label='Posizione'
                onChange={value =>
                  dispatch({ type: SET_NEW_POSIZIONE, payload: value })
                }
                errorText='Selezionare una posizione!'
                disabled={selectedFunction !== availableFunctions[0]}
              />
            </center>
          </Grid>
        </Grid>
        <Grid container item spacing={3} alignItems='center'>
          <Grid container item xs={12} md={6} alignItems='center'>
            <Grid item xs={2}>
              <center>
                <Radio
                  id='scambia'
                  value={availableFunctions[1]}
                  checked={selectedFunction === availableFunctions[1]}
                  onChange={event => {
                    dispatch({
                      type: SET_SELECTED_FUNCTION,
                      payload: event.target.value,
                    })
                  }}
                />
              </center>
            </Grid>
            <Grid item xs>
              Scambia con squadra...
            </Grid>
          </Grid>
          <Grid item xs md>
            <center>
              <AutoComplete
                id='squadra'
                disabled={selectedFunction !== availableFunctions[1]}
                value={newTeam}
                label='Squadra'
                options={iscrizioniGironi
                  ?.filter(i => i.categoria === team?.categoria)
                  ?.sort(
                    (a, b) =>
                      sortGironi(a.girone, b.girone) ||
                      sortNumerically(a.posizione, b.posizione)
                  )}
                groupBy={op =>
                  !!op.girone
                    ? `Girone ${+op.girone || op.girone}`
                    : 'Senza girone'
                }
                getOptionLabel={op => op.nomeSquadra}
                helperText=''
                onChange={value =>
                  dispatch({ type: SET_NEW_TEAM, payload: value || undefined })
                }
              />
            </center>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} alignItems='center'>
          <Grid container item xs={12} md={6} alignItems='center'>
            <Grid item xs={2}>
              <center>
                <Radio
                  id='nuovo-girone'
                  value={availableFunctions[2]}
                  checked={selectedFunction === availableFunctions[2]}
                  onChange={event => {
                    dispatch({
                      type: SET_SELECTED_FUNCTION,
                      payload: event.target.value,
                    })
                  }}
                />
              </center>
            </Grid>
            <Grid item xs>
              Metti in un nuovo girone...
            </Grid>
          </Grid>
          <Grid item xs md>
            <center>
              <InputField
                id='girone-add'
                disabled={selectedFunction !== availableFunctions[2]}
                value={newGironeAdd}
                label='Nuovo girone'
                onChange={value =>
                  dispatch({ type: SET_NEW_GIRONE_ADD, payload: value })
                }
                fullWidth
                errorText='Non valido: esiste già o ha caratteri non ammessi!'
                errorFunc={errorNewGirone}
              />
            </center>
          </Grid>
          <Grid item xs md>
            <center>
              <Select
                id='posizione-add'
                value={newPosizioneAdd}
                options={[1, 2, 3, 4, 5, 6]}
                label='Posizione'
                onChange={value =>
                  dispatch({ type: SET_NEW_POSIZIONE_ADD, payload: value })
                }
                errorText='Selezionare una posizione!'
                disabled={selectedFunction !== availableFunctions[2]}
              />
            </center>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} alignItems='center'>
          <Grid container item xs={12} md={6} alignItems='center'>
            <Grid item xs={2}>
              <center>
                <Radio
                  id='togli'
                  value={availableFunctions[3]}
                  checked={selectedFunction === availableFunctions[3]}
                  onChange={event =>
                    dispatch({
                      type: SET_SELECTED_FUNCTION,
                      payload: event.target.value,
                    })
                  }
                />
              </center>
            </Grid>
            <Grid item xs>
              Togli dai gironi.
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  newTeam: state.gironi.newTeam,
  newGirone: state.gironi.newGirone,
  newPosizione: state.gironi.newPosizione,
  newGironeAdd: state.gironi.newGironeAdd,
  newPosizioneAdd: state.gironi.newPosizioneAdd,
  availableFunctions: state.gironi.availableFunctions,
  selectedFunction: state.gironi.selectedFunction,
  iscrizioniGironi: state.gironi.iscrizioniGironi,
})

const ConnectedDialogGirone = connect(mapStateToProps)(DialogGirone)

export default ConnectedDialogGirone
