import React, { useRef } from 'react'
import { connect, useDispatch } from 'react-redux'

import { ColouredButton } from './Buttons'
import { sendRequest } from '../hooks/http-hook'
import { colours } from '../settings/settings'
import {
  SET_BACKEND_R,
  SET_OPEN_UPLOAD_DIALOG,
  SET_UPLOAD_DIALOG_PHRASE,
  SET_UPLOAD_DIALOG_SUCCESS,
  SET_ERROR,
} from '../container/home/types'

const ButtonUpload = ({
  buttonText,
  algorithm,
  fileName,
  runScript = false,
  uploadCampionato,
  codiceUtente,
  backendR,
}) => {
  const dispatch = useDispatch()

  const uploadFileRef = useRef()

  const handleClickOpenDialog = (success, phrase) => {
    dispatch({ type: SET_UPLOAD_DIALOG_SUCCESS, payload: !!success })
    dispatch({ type: SET_UPLOAD_DIALOG_PHRASE, payload: phrase })
    dispatch({ type: SET_OPEN_UPLOAD_DIALOG, payload: true })
  }

  const uploadFile = async (algorithm, file, fileName, runScript) => {
    if (file.type !== 'text/csv') {
      handleClickOpenDialog(false, 'Il file deve essere in formato CSV.')
    } else if (!algorithm) {
      handleClickOpenDialog(false, 'Algoritmo non definito.')
    } else {
      const formData = new FormData()
      formData.append('File', file)
      try {
        await sendRequest(`algoritmi/checkfolder/${algorithm}`)
        await sendRequest(
          `algoritmi/upload/${algorithm}${fileName ? `/${fileName}` : ''}`,
          'POST',
          formData,
          { 'Content-Type': 'text/csv', Authorization: codiceUtente }
        )

        handleClickOpenDialog(true, 'Il file è stato caricato correttamente!')

        if (!!runScript) {
          try {
            await sendRequest(
              `algoritmi/run/${algorithm}${
                algorithm === '05_UpdateCalendari' ? `/${uploadCampionato}` : ''
              }`,
              'GET',
              null,
              { Authorization: codiceUtente }
            )
          } catch (err) {
            console.log(err)
            dispatch({ type: SET_ERROR, payload: err.message })
            handleClickOpenDialog(false, "Errore nell'esecuzione dello script.")
          }
          dispatch({
            type: SET_BACKEND_R,
            payload: { ...backendR, status: 'busy', process: algorithm },
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
        handleClickOpenDialog(false, 'Errore nel caricamento del file.')
      }
    }
  }

  return (
    <center>
      <input
        id={`upload-file-${algorithm}`}
        ref={uploadFileRef}
        type='file'
        accept='text/csv'
        style={{ display: 'none' }}
        onChange={event =>
          uploadFile(algorithm, event.target.files[0], fileName, runScript)
        }
      />
      <ColouredButton
        textcolour={colours.white}
        textbold='bold'
        backgroundcolour={colours.blueLight}
        hovercolour={colours.blue}
        disabled={algorithm === '05_UpdateCalendari' && !uploadCampionato}
        onClick={() => uploadFileRef.current.click()}
      >
        {buttonText}
      </ColouredButton>
    </center>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  backendR: state.home.backendR,
})

const ConnectedButtonUpload = connect(mapStateToProps)(ButtonUpload)

export default ConnectedButtonUpload
