import React from 'react'
import { useDispatch } from 'react-redux'
import fileDownload from 'js-file-download'

import { ColouredButton } from './Buttons'
import { sendRequest } from '../hooks/http-hook'
import { sendRequestBlob } from '../hooks/http-hook-blob'
import { colours } from '../settings/settings'
import { SET_ERROR } from '../container/home/types'

const ButtonDownload = ({ algorithm, type }) => {
  const dispatch = useDispatch()

  const isTxt = type === 'TXT'
  const isPdf = type === 'PDF'
  const isExcel = type === 'XLSX'

  const downloadFile = async (algorithm, type) => {
    if (!algorithm) {
      dispatch({ type: SET_ERROR, payload: 'Algoritmo non definito!' })
    } else if (!type) {
      dispatch({ type: SET_ERROR, payload: 'Tipo di file non definito!' })
    } else {
      try {
        const fileName = type === 'CSV' ? 'Output' : 'all'
        const checkFileData = await sendRequest(
          `algoritmi/checkfile/${algorithm}/${type}/${fileName}`
        )
        const fileNames = checkFileData.fileNames
        if (fileNames.length > 0) {
          let i = 0
          // add some delay because some browsers do not download more than 10 files simultaneously
          const delayedDownload = () => {
            setTimeout(async () => {
              let fileName = fileNames[i]
              const fileData = await sendRequestBlob(
                `algoritmi/download/${algorithm}/${fileName}`
              )
              fileDownload(fileData, `${algorithm}-${fileName}`)
              i++
              if (i < fileNames.length) delayedDownload()
            }, 500)
          }
          delayedDownload()
          dispatch({
            type: SET_ERROR,
            payload: 'File scaricati correttamente.',
          })
        } else {
          dispatch({ type: SET_ERROR, payload: 'Nessun file trovato.' })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
  }

  return (
    <center>
      <ColouredButton
        textcolour={colours.white}
        textbold='bold'
        backgroundcolour={
          isTxt
            ? colours.greyLight
            : isPdf
            ? colours.red
            : isExcel
            ? colours.greenExcel
            : colours.blueDark
        }
        hovercolour={
          isTxt
            ? colours.grey
            : isPdf
            ? colours.redDark
            : isExcel
            ? colours.greenExcelHover
            : colours.blueVeryDark
        }
        onClick={() => downloadFile(algorithm, type)}
      >
        {type === 'TXT' && 'Scarica LOG'}
        {type === 'CSV' && 'Scarica CSV'}
        {type === 'PDF' &&
          ((algorithm === '02_GenerateGironi' && 'Scarica PDF / categoria') ||
            (algorithm === '03_GenerateCalendari' && 'Scarica PDF / girone'))}
        {type === 'XLSX' &&
          algorithm === '03_GenerateCalendari' &&
          'Scarica XLSX / girone'}
      </ColouredButton>
    </center>
  )
}

export default ButtonDownload
