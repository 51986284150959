export const utilsFunctions = () => {
  const fromDateToString = (date, utc = false) => {
    if (!(date instanceof Date)) return null

    let day, month, year

    if (utc) {
      day = String(date.getUTCDate())
      month = String(date.getUTCMonth() + 1)
      year = String(date.getUTCFullYear())
    } else {
      day = String(date.getDate())
      month = String(date.getMonth() + 1)
      year = String(date.getFullYear())
    }

    day = day.padStart(2, '0')
    month = month.padStart(2, '0')
    year = year.padStart(4, '0')

    return `${day}/${month}/${year}`
  }

  const fromDateTimeToString = (date, utc = false) => {
    if (!(date instanceof Date)) return null

    const dateString = fromDateToString(date, utc)

    let hours, minutes

    if (utc) {
      hours = String(date.getUTCHours())
      minutes = String(date.getUTCMinutes())
    } else {
      hours = String(date.getHours())
      minutes = String(date.getMinutes())
    }

    hours = hours.padStart(2, '0')
    minutes = minutes.padStart(2, '0')

    return `${dateString} ${hours}:${minutes}`
  }

  const fromStringToDate = date => {
    if (date?.split('/')?.length !== 3) return null

    const [day, month, year] = date.split('/')

    return new Date(year, month - 1, day)
  }

  const fromStringsToDateTime = (date, time) => {
    if (date?.split('/')?.length !== 3) return null
    if (time?.split(':')?.length !== 2) return null

    const [day, month, year] = date.split('/')
    const [hours, minutes] = time.split(':')

    return new Date(year, month - 1, day, hours, minutes)
  }

  const fromStringToDecimalHours = time => {
    if (time?.split(':')?.length !== 2) return null

    const [hours, minutes] = time.split(':')

    return +hours + +minutes / 60
  }

  const formatCampionato = x =>
    x &&
    x?.charAt(0)?.toUpperCase() +
      x?.slice(1)?.replace('_', ' ')?.replace('-', '/')

  const formatIndirizzo = (iscrizione, calendario) => {
    // get address from calendario
    if (calendario) {
      const { presso, indirizzo, citta } = calendario
      if (indirizzo && citta) {
        if (!presso) return `${indirizzo}, ${citta}`
        return `${presso}, ${indirizzo}, ${citta}`
      }
    }

    // get address from iscrizione
    if (iscrizione) {
      const { presso, indirizzo, citta } = iscrizione
      if (indirizzo && citta) {
        if (!presso) return `${indirizzo}, ${citta}`
        return `${presso}, ${indirizzo}, ${citta}`
      }
    }

    return undefined
  }

  const DAYS_TO_MILLISECONDS = 24 * 60 * 60 * 1000

  const isObjectId = id => id?.match(/^[0-9a-fA-F]{24}$/)

  const rangeFun = i => (i ? rangeFun(i - 1).concat(i) : [])

  const setWinningScore = (n, isPropaganda, isPriFin2223) =>
    isPropaganda ? (isPriFin2223 ? 20 : 17) : n === 5 ? 15 : 25

  const filterByNumeroGara = (data, selectedNumeroGara) =>
    data?.filter(x =>
      selectedNumeroGara ? x.numeroGara === selectedNumeroGara : true
    )

  const filterByPeriod = (data, availablePeriodi, selectedPeriodo) =>
    data?.filter(x => {
      const xCheck = x.data && x.ora
      const xDate = fromStringsToDateTime(x.data, x.ora)
      const nDate = new Date()
      const nDatePlusN = N => new Date(Date.now() + N * DAYS_TO_MILLISECONDS)
      return selectedPeriodo === availablePeriodi[0] // Tutte
        ? true
        : selectedPeriodo === availablePeriodi[1] // Passate
        ? xCheck && xDate <= nDate
        : selectedPeriodo === availablePeriodi[2] // Future
        ? xCheck && xDate >= nDate
        : selectedPeriodo === availablePeriodi[3] // Prossimi 10 giorni
        ? xCheck && nDate <= xDate && xDate <= nDatePlusN(10)
        : selectedPeriodo === availablePeriodi[4] // Prossimi 30 giorni
        ? xCheck && nDate <= xDate && xDate <= nDatePlusN(30)
        : selectedPeriodo === availablePeriodi[5] // Senza data
        ? !xCheck
        : true
    })

  const filterByDesStatus = (data, STATUSES, selectedStatus, admin, arbitro) =>
    data?.filter(x =>
      selectedStatus === STATUSES[0] // Tutte
        ? admin || arbitro
        : selectedStatus === STATUSES[1] // In sospeso
        ? admin
          ? (x?.idDesignazione1 || x?.idDesignazione2) &&
            ((x?.idDesignazione1 &&
              x?.isValid1 &&
              ![true, false].includes(x?.acceptedByArbitro1)) ||
              (x?.idDesignazione2 &&
                x?.isValid2 &&
                ![true, false].includes(x?.acceptedByArbitro2)))
          : arbitro
          ? x?.isValid && ![true, false].includes(x?.acceptedByArbitro)
          : false
        : selectedStatus === STATUSES[2] // Accettate
        ? admin
          ? (x?.idDesignazione1 || x?.idDesignazione2) &&
            (!x?.idDesignazione1 || (x?.isValid1 && x?.acceptedByArbitro1)) &&
            (!x?.idDesignazione2 || (x?.isValid2 && x?.acceptedByArbitro2))
          : arbitro
          ? x?.isValid && x?.acceptedByArbitro
          : false
        : selectedStatus === STATUSES[3] // Rifiutate
        ? admin
          ? (x?.idDesignazione1 || x?.idDesignazione2) &&
            ((x?.idDesignazione1 &&
              x?.isValid1 === false &&
              x?.acceptedByArbitro1 === false) ||
              (x?.idDesignazione2 &&
                x?.isValid2 === false &&
                x?.acceptedByArbitro2 === false))
          : arbitro
          ? x?.isValid === false && x?.acceptedByArbitro === false
          : false
        : selectedStatus === STATUSES[4] // Da designare || Annullate
        ? admin
          ? !x?.idDesignazione1 ||
            x?.isValid1 === false ||
            x?.isValid2 === false
          : arbitro
          ? x?.isValid === false && x?.acceptedByArbitro !== false
          : false
        : false
    )

  const filterByMissingInfo = (data, FILTERS, selectedFilter) =>
    data?.filter(x =>
      selectedFilter === FILTERS[0] // Tutte
        ? true
        : selectedFilter === FILTERS[1] // Senza data
        ? (!x.data || !x.ora) &&
          isObjectId(x.squadraCasaID) &&
          isObjectId(x.squadraTrasfertaID) &&
          typeof x.setVintiCasa !== 'number' &&
          typeof x.setVintiTrasferta !== 'number'
        : selectedFilter === FILTERS[2] // Da giocare
        ? x.data &&
          x.ora &&
          fromStringsToDateTime(x.data, x.ora) >= new Date() &&
          isObjectId(x.squadraCasaID) &&
          isObjectId(x.squadraTrasfertaID) &&
          (typeof x.setVintiCasa !== 'number' ||
            typeof x.setVintiTrasferta !== 'number')
        : true
    )

  return {
    fromDateToString,
    fromDateTimeToString,
    fromStringToDate,
    fromStringsToDateTime,
    fromStringToDecimalHours,
    formatCampionato,
    formatIndirizzo,
    DAYS_TO_MILLISECONDS,
    isObjectId,
    rangeFun,
    setWinningScore,
    filterByNumeroGara,
    filterByPeriod,
    filterByDesStatus,
    filterByMissingInfo,
  }
}
