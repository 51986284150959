import {
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_SELECTED_GROUP,
  SET_SELECTED_NEEDS_REFEREE,
} from './types'

const INIT_STATE = {
  sortingAscending: true,
}

export const provvedimentiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SORTING_COLUMN:
      return { ...state, sortingColumn: action.payload }
    case SET_SORTING_ASCENDING:
      return { ...state, sortingAscending: action.payload }
    case SET_SELECTED_GROUP:
      return { ...state, selectedGroup: action.payload }
    case SET_SELECTED_NEEDS_REFEREE:
      return { ...state, selectedNeedsReferee: action.payload }
    default:
      return state
  }
}
