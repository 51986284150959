import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  CircularProgress,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import BlockIcon from '@mui/icons-material/Block'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

import DialogMini from '../components/DialogMini'
import ButtonGroup from '../components/ButtonGroup'
import ButtonRunDownload from '../components/ButtonRunDownload'
import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import {
  GIORNI,
  colours,
  titlesDesignazioni as titles,
  phrasesDesignazione as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { sortFunctions } from '../hooks/sort-functions'
import { utilsFunctions } from '../hooks/utils-functions'
import {
  SET_DUMMY_UPDATE,
  SET_ERROR,
  SET_SELECTED_NUMERI_GARA,
} from '../container/home/types'
import { SET_CALENDARI } from '../container/calendari/types'
import {
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
} from '../container/designazioni/types'
import {
  SET_MODE,
  SET_DESIGNAZIONE,
  SET_SELECTED_GARA,
} from '../container/designazione/types'

const Designazioni = ({
  admin,
  arbitro,
  designatore,
  codiceUtente,
  dummyUpdate,
  iscrizioniLight,
  loadingDesignazioni,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
  selectedNumeroGara,
  availablePeriodi,
  selectedPeriodo,
  selectedArbitroB,
  arbitri,
  calendari,
  mode,
  designazione,
  selectedGara,
  selectedArbitro1,
  selectedArbitro2,
  checkAccept,
  checkReject,
  designazioni,
  sortingColumn,
  sortingAscending,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Designazioni'

  let columnsDesignazioni = [
    { label: 'Categoria', sortable: true, sortingField: 'categoria' },
    { label: 'Gara', sortable: true, sortingField: 'numeroGara' },
    { label: 'Data e Ora', sortable: true, sortingField: 'dataoragara' },
  ]
  if (admin || designatore)
    columnsDesignazioni = [
      ...columnsDesignazioni,
      { label: '1° Arbitro', sortable: true, sortingField: 'cognomenome1' },
      { label: '2° Arbitro', sortable: true, sortingField: 'cognomenome2' },
    ]
  if (arbitro)
    columnsDesignazioni = [
      ...columnsDesignazioni,
      { label: 'Squadra in casa', sortable: false },
      { label: 'Squadra in trasferta', sortable: false },
      { label: 'Ruolo', sortable: false },
      { label: 'Stato', sortable: false },
    ]

  // sort functions
  const { sortCategorie, sortGironi, sortNumeriGara, sortDataOra } =
    sortFunctions()
  const sortCategorieM = useCallback(sortCategorie, [])
  const sortGironiM = useCallback(sortGironi, [])
  const sortNumeriGaraM = useCallback(sortNumeriGara, [])
  const sortDataOraM = useCallback(sortDataOra, [])

  // utils functions
  const {
    fromStringsToDateTime,
    filterByNumeroGara,
    filterByPeriod,
    filterByDesStatus,
  } = utilsFunctions()

  // state to manage admin ButtonGroup selection
  let STATUSES = ['Tutte', 'In sospeso', 'Accettate', 'Rifiutate']
  if (admin || designatore) STATUSES = [...STATUSES, 'Da designare']
  if (arbitro) STATUSES = [...STATUSES, 'Annullate']
  const [selectedStatus, setSelectedStatus] = useState(STATUSES[0])

  const [mergedData, setMergedData] = useState()

  const [waitingSpostamenti, setWaitingSpostamenti] = useState()

  const [openDesignazione, setOpenDesignazione] = useState(false)

  // state to manage feedback dialog
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
  const [feedbackDialogTitle, setFeedbackDialogTitle] = useState()
  const [feedbackDialogPhrase, setFeedbackDialogPhrase] = useState()
  const [feedbackDialogSuccess, setFeedbackDialogSuccess] = useState()

  const handleClickOpenDesignazione = () => setOpenDesignazione(true)
  const handleCloseDesignazione = () => setOpenDesignazione(false)

  const handleClickOpenFeedbackDialog = (success, title, phrase) => {
    setFeedbackDialogSuccess(!!success)
    setFeedbackDialogTitle(title)
    setFeedbackDialogPhrase(phrase)
    setOpenFeedbackDialog(true)
  }

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false)
    setFeedbackDialogTitle()
    setFeedbackDialogPhrase()
    setFeedbackDialogSuccess()
  }

  // merge and filter data
  useEffect(() => {
    let mData
    if (admin || designatore) {
      const mCal = calendari?.map(x => {
        return {
          idGara: x.id,
          categoria: x.categoria,
          numeroGara: x.numeroGara,
          data: x.data,
          ora: x.ora,
        }
      })

      const mDes = designazioni
        ?.filter(x => x.codiceCampionato === selectedCampionato)
        ?.map(x => {
          return {
            idDesignazione: x.id,
            idArbitro: x.idArbitro,
            idGara: x.idGara,
            isValid: x.isValid,
            isSecondoArbitro: x.isSecondoArbitro,
            acceptedByArbitro: x.acceptedByArbitro,
            timeDomanda: x.timeDomanda,
          }
        })

      const mArb = arbitri?.map(x => {
        return { idArbitro: x.id, cognomenome: `${x.cognome} ${x.nome}` }
      })

      mData = mCal?.map(c => {
        const d1 = mDes
          ?.filter(x => x.idGara === c?.idGara && !x.isSecondoArbitro)
          ?.map(x => {
            return {
              idDesignazione1: x.idDesignazione,
              idArbitro1: x.idArbitro,
              idGara: x.idGara,
              isValid1: x.isValid,
              acceptedByArbitro1: x.acceptedByArbitro,
              timeDomanda1: x.timeDomanda,
            }
          })
          ?.sort((a, b) => (a.timeDomanda1 < b.timeDomanda1 ? 1 : -1))?.[0]

        const d2 = mDes
          ?.filter(x => x.idGara === c?.idGara && x.isSecondoArbitro)
          ?.map(x => {
            return {
              idDesignazione2: x.idDesignazione,
              idArbitro2: x.idArbitro,
              isValid2: x.isValid,
              acceptedByArbitro2: x.acceptedByArbitro,
              timeDomanda2: x.timeDomanda,
            }
          })
          ?.sort((a, b) => (a.timeDomanda2 < b.timeDomanda2 ? 1 : -1))?.[0]

        const a1 = mArb
          ?.filter(x => x.idArbitro === d1?.idArbitro1)
          ?.map(x => {
            return { cognomenome1: x.cognomenome }
          })?.[0]

        const a2 = mArb
          ?.filter(x => x.idArbitro === d2?.idArbitro2)
          ?.map(x => {
            return { cognomenome2: x.cognomenome }
          })?.[0]

        return { ...c, ...d1, ...d2, ...a1, ...a2 }
      })
    } else {
      const mCal = calendari?.map(x => {
        return {
          idGara: x.id,
          categoria: x.categoria,
          numeroGara: x.numeroGara,
          data: x.data,
          ora: x.ora,
          squadraCasaID: x.squadraCasaID,
          squadraTrasfertaID: x.squadraTrasfertaID,
        }
      })

      const mDes = designazioni
        ?.filter(x => x.codiceCampionato === selectedCampionato)
        ?.map(x => {
          return {
            idDesignazione: x.id,
            idArbitro: x.idArbitro,
            idGara: x.idGara,
            isValid: x.isValid,
            isSecondoArbitro: x.isSecondoArbitro,
            acceptedByArbitro: x.acceptedByArbitro,
            timeDomanda: x.timeDomanda,
          }
        })

      mData = mDes?.map(d => {
        const c = mCal?.find(x => x.idGara === d?.idGara)
        return { ...c, ...d }
      })
    }

    setMergedData(
      mData
        ?.filter(
          x =>
            x.categoria === selectedCategoria ||
            selectedCategoria === availableCategorie[0] ||
            !selectedCategoria
        )
        ?.sort(
          (a, b) =>
            sortDataOraM(a.data, a.ora, b.data, b.ora) *
              (admin || designatore ? 1 : -1) ||
            sortNumeriGaraM(a.numeroGara, b.numeroGara)
        )
    )
  }, [
    admin,
    arbitri,
    availableCategorie,
    calendari,
    designatore,
    designazioni,
    selectedCampionato,
    selectedCategoria,
    sortDataOraM,
    sortNumeriGaraM,
  ])

  // update numeri gara for Excel
  useEffect(() => {
    dispatch({
      type: SET_SELECTED_NUMERI_GARA,
      payload: filterByDesStatus(
        filterByNumeroGara(
          filterByPeriod(mergedData, availablePeriodi, selectedPeriodo),
          selectedNumeroGara
        )?.filter(
          m =>
            !selectedArbitroB ||
            (selectedArbitroB === m.idArbitro1 && m.isValid1) ||
            (selectedArbitroB === m.idArbitro2 && m.isValid2)
        ),
        STATUSES,
        selectedStatus,
        admin || designatore,
        arbitro
      )?.map(d => d.numeroGara),
    })
  }, [
    STATUSES,
    admin,
    arbitro,
    availablePeriodi,
    designatore,
    dispatch,
    filterByDesStatus,
    filterByNumeroGara,
    filterByPeriod,
    mergedData,
    selectedArbitroB,
    selectedNumeroGara,
    selectedPeriodo,
    selectedStatus,
  ])

  // fetch calendari
  useEffect(() => {
    const fetchCalendari = async () => {
      try {
        const calendariData =
          admin || designatore
            ? await sendRequest(
                `calendari/campionato/${selectedCampionato}${
                  selectedCategoria &&
                  selectedCategoria !== availableCategorie[0]
                    ? `/categoria/${selectedCategoria}`
                    : ''
                }/referee`
              )
            : await sendRequest(
                'calendari/many',
                'POST',
                JSON.stringify({ ids: designazioni?.map(d => d.idGara) }),
                { 'Content-Type': 'application/json' }
              )

        if (!calendariData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le partite.',
          })
        } else {
          dispatch({
            type: SET_CALENDARI,
            payload: calendariData.sort(
              (a, b) =>
                sortCategorieM(a.categoria, b.categoria) ||
                sortGironiM(a.girone, b.girone) ||
                sortNumeriGaraM(a.numeroGara, b.numeroGara)
            ),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (
      !!selectedCampionato &&
      !!selectedCategoria &&
      (admin || designatore || designazioni)
    ) {
      fetchCalendari()
    } else {
      dispatch({ type: SET_CALENDARI })
    }
  }, [
    admin,
    availableCategorie,
    designatore,
    designazioni,
    dispatch,
    dummyUpdate,
    selectedCampionato,
    selectedCategoria,
    sortCategorieM,
    sortGironiM,
    sortNumeriGaraM,
  ])

  // fetch waiting spostamenti
  useEffect(() => {
    const fetchWaitingSpostamenti = async () => {
      try {
        const waitingSpostamentiData = await sendRequest(
          `spostamenti/campionato/${selectedCampionato}/waiting`
        )

        if (!waitingSpostamentiData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare gli spostamenti in sospeso.',
          })
        } else {
          setWaitingSpostamenti(waitingSpostamentiData)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (selectedCampionato) {
      fetchWaitingSpostamenti()
    } else {
      setWaitingSpostamenti()
    }
  }, [dispatch, dummyUpdate, selectedCampionato])

  const performDesignazione = async () => {
    if (
      !!selectedGara?.id &&
      (!!selectedArbitro1?.id || !!selectedArbitro2?.id)
    ) {
      const garaData = mergedData?.find(x => x.idGara === selectedGara?.id)
      if (
        selectedArbitro1?.id !== selectedArbitro2?.id &&
        (!selectedArbitro1?.id ||
          !garaData?.isValid2 ||
          selectedArbitro1?.id !== garaData?.idArbitro2 ||
          selectedArbitro2?.id) &&
        (!selectedArbitro2?.id ||
          !garaData?.isValid1 ||
          selectedArbitro2?.id !== garaData?.idArbitro1 ||
          selectedArbitro1?.id)
      ) {
        let errors = []
        if (selectedArbitro1?.id) {
          try {
            await sendRequest(
              `designazioni/add`,
              'POST',
              JSON.stringify({
                idGara: selectedGara.id,
                idArbitro: selectedArbitro1.id,
              }),
              {
                'Content-Type': 'application/json',
                Authorization: codiceUtente,
              }
            )
          } catch (err) {
            errors.push(`1° arbitro: ${err.message}`)
          }
        }
        if (selectedArbitro2?.id) {
          try {
            await sendRequest(
              `designazioni/add`,
              'POST',
              JSON.stringify({
                idGara: selectedGara.id,
                idArbitro: selectedArbitro2.id,
                isSecondoArbitro: true,
              }),
              {
                'Content-Type': 'application/json',
                Authorization: codiceUtente,
              }
            )
          } catch (err) {
            errors.push(`2° arbitro: ${err.message}`)
          }
        }
        handleCloseDesignazione()
        if (errors.length === 0) {
          handleClickOpenFeedbackDialog(
            true,
            titles.titleDesignazioneDomanda,
            phrases.phraseDesignazioneDomandaSuccess
          )
        } else {
          handleClickOpenFeedbackDialog(false, 'Errore', errors.join(' - '))
        }
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } else {
        dispatch({
          type: SET_ERROR,
          payload:
            'Impossibile effettuare la designazione: il 1° arbitro non può fare anche il 2°!',
        })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          'Impossibile effettuare la designazione: gara o arbitri non trovati!',
      })
    }
  }

  // reply designazione
  const replyDesignazione = async () => {
    if (!checkAccept && !checkReject) {
      dispatch({
        type: SET_ERROR,
        payload:
          'Impossibile procedere: la designazione va accettata o rifiutata.',
      })
    } else {
      try {
        await sendRequest(
          `designazioni/${designazione.id}/${
            (checkAccept && 'accetta') || (checkReject && 'rifiuta')
          }`,
          'PATCH',
          null,
          { Authorization: codiceUtente }
        )
        handleCloseDesignazione()
        handleClickOpenFeedbackDialog(
          true,
          (checkAccept && titles.titleDesignazioneAccetta) ||
            (checkReject && titles.titleDesignazioneRifiuta),
          (checkAccept && phrases.phraseDesignazioneAccetta) ||
            (checkReject && phrases.phraseDesignazioneRifiuta)
        )
      } catch (err) {
        handleCloseDesignazione()
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    }
  }

  const DesignazioniTable = ({ data }) => {
    // variables without number are for arbitro
    // variables with 1/2 number are for admin
    const hasDate = data?.data && data?.ora
    const isValid = data?.isValid === true
    const isValid1 = data?.isValid1 === true
    const isValid2 = data?.isValid2 === true
    const isAccepted = data?.acceptedByArbitro === true
    const isAccepted1 = data?.acceptedByArbitro1 === true
    const isAccepted2 = data?.acceptedByArbitro2 === true
    const isRejected = data?.acceptedByArbitro === false
    const isRejected1 = data?.acceptedByArbitro1 === false
    const isRejected2 = data?.acceptedByArbitro2 === false
    const isPending = ![true, false].includes(data?.acceptedByArbitro)
    const isPending1 = ![true, false].includes(data?.acceptedByArbitro1)
    const isPending2 = ![true, false].includes(data?.acceptedByArbitro2)
    const isReplyable = data?.idDesignazione && isValid && isPending
    const isReplyable1 = data?.idDesignazione1 && isValid1 && isPending1
    const isReplyable2 = data?.idDesignazione2 && isValid2 && isPending2
    return (
      <TableRow
        sx={
          arbitro || hasDate
            ? {
                cursor: (admin || designatore || isValid) && 'pointer',
                backgroundColor: waitingSpostamenti?.includes(data.idGara)
                  ? colours.redError
                  : isReplyable || isReplyable1 || isReplyable2
                  ? colours.highlight
                  : colours.white,
                ':hover': { backgroundColor: colours.greyVeryLight },
              }
            : {
                backgroundColor: waitingSpostamenti?.includes(data.idGara)
                  ? colours.redError
                  : colours.greyVeryLight,
              }
        }
        onClick={() => {
          if (admin || designatore ? hasDate : isValid) {
            if (admin || designatore) {
              dispatch({ type: SET_MODE, payload: 'update' })
              dispatch({
                type: SET_SELECTED_GARA,
                payload: calendari?.find(x => x.id === data?.idGara),
              })
            }
            if (arbitro) {
              dispatch({
                type: SET_MODE,
                payload: isReplyable ? 'reply' : 'view',
              })
              dispatch({
                type: SET_DESIGNAZIONE,
                payload: designazioni?.find(x => x.id === data?.idDesignazione),
              })
            }
            handleClickOpenDesignazione()
          }
        }}
      >
        <TableCell align='center'>{data?.categoria}</TableCell>
        <TableCell align='center'>{data?.numeroGara}</TableCell>
        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
          {hasDate && (admin || designatore || isValid)
            ? `${GIORNI[
                (fromStringsToDateTime(data?.data, data?.ora)?.getDay() -
                  1 +
                  GIORNI.length) %
                  GIORNI.length
              ]
                ?.slice(0, 2)
                ?.toUpperCase()} ${data?.data} ${data?.ora}`
            : '-'}
        </TableCell>
        {(admin || designatore) && (
          <TableCell align='center'>
            {data?.idDesignazione1 && (
              <Grid container item alignItems='center'>
                <Grid item>
                  <Tooltip
                    placement='right'
                    title={
                      (isValid1 && isAccepted1 && 'Accettata') ||
                      (isValid1 && isPending1 && 'In sospeso') ||
                      (!isValid1 && isRejected1 && 'Rifiutata') ||
                      (!isValid1 && 'Annullata') ||
                      'Sconosciuto'
                    }
                  >
                    {(isValid1 && isAccepted1 && (
                      <CheckIcon color='success' />
                    )) ||
                      (isValid1 && isPending1 && (
                        <AccessTimeIcon color='warning' />
                      )) ||
                      (!isValid1 && isRejected1 && (
                        <ClearIcon color='error' />
                      )) ||
                      (!isValid1 && <BlockIcon color='error' />) || <></>}
                  </Tooltip>
                </Grid>
                <Grid item xs>
                  {data?.cognomenome1 || <i>Nome non trovato</i>}
                </Grid>
              </Grid>
            )}
          </TableCell>
        )}
        {(admin || designatore) && (
          <TableCell align='center'>
            {data?.idDesignazione2 && (
              <Grid container item alignItems='center'>
                <Grid item>
                  <Tooltip
                    placement='right'
                    title={
                      (isValid2 && isAccepted2 && 'Accettata') ||
                      (isValid2 && isPending2 && 'In sospeso') ||
                      (!isValid2 && isRejected2 && 'Rifiutata') ||
                      (!isValid2 && 'Annullata') ||
                      'Sconosciuto'
                    }
                  >
                    {(isValid2 && isAccepted2 && (
                      <CheckIcon color='success' />
                    )) ||
                      (isValid2 && isPending2 && (
                        <AccessTimeIcon color='warning' />
                      )) ||
                      (!isValid2 && isRejected2 && (
                        <ClearIcon color='error' />
                      )) ||
                      (!isValid2 && <BlockIcon color='error' />) || <></>}
                  </Tooltip>
                </Grid>
                <Grid item xs>
                  {data?.cognomenome2 || <i>Nome non trovato</i>}
                </Grid>
              </Grid>
            )}
          </TableCell>
        )}
        {arbitro && (
          <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
            {iscrizioniLight?.find(x => x.id === data?.squadraCasaID)
              ?.nomeSquadra || <i>Squadra non trovata</i>}
          </TableCell>
        )}
        {arbitro && (
          <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
            {iscrizioniLight?.find(x => x.id === data?.squadraTrasfertaID)
              ?.nomeSquadra || <i>Squadra non trovata</i>}
          </TableCell>
        )}
        {arbitro && (
          <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
            {data?.isSecondoArbitro ? '2° arbitro' : '1° arbitro'}
          </TableCell>
        )}
        {arbitro && (
          <TableCell align='center'>
            {data?.idDesignazione && (
              <Grid item>
                <Tooltip
                  placement='right'
                  title={
                    (isValid && isAccepted && 'Accettata') ||
                    (isValid && isPending && 'In sospeso') ||
                    (!isValid && isRejected && 'Rifiutata') ||
                    (!isValid && 'Annullata') ||
                    'Sconosciuto'
                  }
                >
                  {(isValid && isAccepted && <CheckIcon color='success' />) ||
                    (isValid && isPending && (
                      <AccessTimeIcon color='warning' />
                    )) ||
                    (!isValid && isRejected && <ClearIcon color='error' />) ||
                    (!isValid && <BlockIcon color='error' />) || <></>}
                </Tooltip>
              </Grid>
            )}
          </TableCell>
        )}
      </TableRow>
    )
  }

  return (
    <Grid container direction='column' paddingY={2}>
      <Grid item>
        <center>
          <h2>Designazioni</h2>
        </center>
      </Grid>
      &nbsp;
      <SelectionBar
        includeGirone={false}
        includeTeam={false}
        includeNumeroGara={admin || designatore}
        includePeriodo={true}
        includeArbitro={admin || designatore}
      />
      <Grid item>
        <center>
          <ButtonGroup
            list={STATUSES}
            value={selectedStatus}
            func={setSelectedStatus}
          />
        </center>
      </Grid>
      {(!!loadingDesignazioni && (
        <center>
          <CircularProgress disableShrink={true} />
        </center>
      )) ||
        (!calendari && (
          <Grid item>
            <center>
              <h4>
                Elenco delle {admin || designatore ? 'partite' : 'designazioni'}{' '}
                non trovato.
              </h4>
            </center>
          </Grid>
        )) ||
        (calendari.length === 0 && (
          <Grid item xs>
            <center>
              <h4>
                Nessuna {admin || designatore ? 'partita' : 'designazione'}{' '}
                trovata.
              </h4>
            </center>
          </Grid>
        )) || (
          <Grid container padding={3}>
            <Grid container item xs={12} rowSpacing={3}>
              <Grid item xs={12} md paddingX={5}>
                Le gare evidenziate in rosso hanno uno spostamento in sospeso.
                <br />
                Le gare evidenziate in giallo sono in attesa della conferma
                dell'arbitro.
                {(admin || designatore) && (
                  <>
                    <br />
                    Le gare evidenziate in grigio sono senza data e ora.
                  </>
                )}
              </Grid>
              {(admin || designatore) && (
                <Grid item xs={12} md={4}>
                  <ButtonRunDownload
                    buttonText='Scarica Excel'
                    algorithm='12_GenerateExcelDesignazioni'
                    type='XLSX'
                  />
                </Grid>
              )}
            </Grid>
            &nbsp;
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={mergedData?.map(d => {
                    return {
                      ...d,
                      dataoragara: `${fromStringsToDateTime(
                        d?.data,
                        d?.ora
                      )?.getTime()}-${d?.numeroGara}`,
                    }
                  })}
                  setTable={setMergedData}
                  columns={columnsDesignazioni}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {filterByDesStatus(
                    filterByNumeroGara(
                      filterByPeriod(
                        mergedData,
                        availablePeriodi,
                        selectedPeriodo
                      ),
                      selectedNumeroGara
                    )?.filter(m =>
                      selectedArbitroB
                        ? [m.idArbitro1, m.idArbitro2].includes(
                            selectedArbitroB
                          )
                        : true
                    ),
                    STATUSES,
                    selectedStatus,
                    admin || designatore,
                    arbitro
                  )?.map((m, index) => (
                    <DesignazioniTable key={index} data={m} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      <DialogMini
        open={openDesignazione}
        handleClose={handleCloseDesignazione}
        title={
          admin || designatore
            ? titles.titleDesignazione
            : designazione?.isSecondoArbitro
            ? titles.titleDesignazione2
            : titles.titleDesignazione1
        }
        textUndo='Chiudi'
        textConfirm={
          admin || designatore
            ? 'Designa'
            : mode === 'reply'
            ? 'Conferma'
            : 'Ok'
        }
        triggerFunction={
          mode === 'update'
            ? performDesignazione
            : mode === 'reply'
            ? replyDesignazione
            : handleCloseDesignazione
        }
        dialogDesignazione={true}
      />
      <DialogMini
        open={openFeedbackDialog}
        handleClose={handleCloseFeedbackDialog}
        title={feedbackDialogTitle}
        textContent={feedbackDialogPhrase}
        textConfirm='Ok'
        triggerFunction={handleCloseFeedbackDialog}
        colourBackground={!!feedbackDialogSuccess ? undefined : colours.red}
        colourHover={!!feedbackDialogSuccess ? undefined : colours.redDark}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  arbitro: state.home.arbitro,
  designatore: state.home.designatore,
  codiceUtente: state.home.codiceUtente,
  dummyUpdate: state.home.dummyUpdate,
  iscrizioniLight: state.home.iscrizioniLight,
  loadingDesignazioni: state.home.loadingDesignazioni,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  selectedNumeroGara: state.home.selectedNumeroGara,
  availablePeriodi: state.home.availablePeriodi,
  selectedPeriodo: state.home.selectedPeriodo,
  selectedArbitroB: state.home.selectedArbitro,
  arbitri: state.arbitri.arbitri,
  calendari: state.calendari.calendari,
  mode: state.designazione.mode,
  designazione: state.designazione.designazione,
  selectedGara: state.designazione.selectedGara,
  selectedArbitro1: state.designazione.selectedArbitro1,
  selectedArbitro2: state.designazione.selectedArbitro2,
  checkAccept: state.designazione.checkAccept,
  checkReject: state.designazione.checkReject,
  designazioni: state.designazioni.designazioni,
  sortingColumn: state.designazioni.sortingColumn,
  sortingAscending: state.designazioni.sortingAscending,
})

const ConnectedDesignazioni = connect(mapStateToProps)(Designazioni)

export default ConnectedDesignazioni
