import React from 'react'
import { useDispatch } from 'react-redux'
import { IconButton, TableHead, TableRow, TableCell } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import { colours } from '../settings/settings'

const SortableTableHead = ({
  table,
  setTable,
  columns,
  sortingColumn,
  setSortingColumn,
  sortingAscending,
  setSortingAscending,
}) => {
  const dispatch = useDispatch()

  return (
    <TableHead style={{ backgroundColor: colours.greyVeryLight }}>
      <TableRow>
        {columns.map(column => (
          <TableCell key={column.label} align='center'>
            {(column.sortable && (
              <>
                <IconButton
                  size='small'
                  disabled={true}
                  style={{ color: 'transparent' }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                &nbsp;
                <b style={{ color: colours.greyVeryDark }}>{column.label}</b>
                &nbsp;
                <IconButton
                  size='small'
                  style={{
                    color:
                      !!sortingColumn && sortingColumn === column.sortingField
                        ? colours.greyVeryDark
                        : colours.greyLight,
                  }}
                  onClick={() => {
                    if (
                      !!sortingColumn &&
                      sortingColumn === column.sortingField
                    ) {
                      dispatch({
                        type: setSortingAscending,
                        payload: !sortingAscending,
                      })
                      setTable(
                        table.sort((a, b) =>
                          a[column.sortingField] === undefined
                            ? 1
                            : b[column.sortingField] === undefined
                            ? -1
                            : a[column.sortingField] === b[column.sortingField]
                            ? 0
                            : a[column.sortingField] > b[column.sortingField]
                            ? !sortingAscending
                              ? 1
                              : -1
                            : !sortingAscending
                            ? -1
                            : 1
                        )
                      )
                    } else {
                      dispatch({
                        type: setSortingColumn,
                        payload: column.sortingField,
                      })
                      dispatch({
                        type: setSortingAscending,
                        payload: true,
                      })
                      setTable(
                        table.sort((a, b) =>
                          a[column.sortingField] === undefined
                            ? 1
                            : b[column.sortingField] === undefined
                            ? -1
                            : a[column.sortingField] === b[column.sortingField]
                            ? 0
                            : a[column.sortingField] > b[column.sortingField]
                            ? 1
                            : -1
                        )
                      )
                    }
                  }}
                >
                  {(!!sortingColumn &&
                    sortingColumn === column.sortingField &&
                    !sortingAscending && <ArrowUpwardIcon />) || (
                    <ArrowDownwardIcon />
                  )}
                </IconButton>
              </>
            )) || <b style={{ color: colours.greyVeryDark }}>{column.label}</b>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default SortableTableHead
