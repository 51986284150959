import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { sendRequest } from './http-hook'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import {
  RESET_ISCRIZIONE,
  SET_ISCRIZIONE,
  SET_MODE,
} from '../container/iscrizione/types'

export const useIscrizione = (admin, codiceUtente, dummyUpdate) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const editIscrizione = async id => {
    if (!!id) {
      try {
        const iscrizioniData = await sendRequest(`iscrizioni/${id}`)
        if (!iscrizioniData || !iscrizioniData.data) {
          dispatch({
            type: SET_ERROR,
            payload: "Impossibile trovare l'iscrizione.",
          })
        } else {
          dispatch({ type: RESET_ISCRIZIONE })
          dispatch({ type: SET_MODE, payload: 'update' })
          dispatch({ type: SET_ISCRIZIONE, payload: iscrizioniData.data })
          history.push('/iscrizione')
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: "Impossibile modificare l'iscrizione: ID non trovato.",
      })
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  const deleteIscrizione = async (id, deleteAlsoGare) => {
    if (!!id) {
      if (!!admin && !!deleteAlsoGare) {
        try {
          await sendRequest(`calendari/team/${id}`, 'DELETE', null, {
            Authorization: codiceUtente,
          })
        } catch (err) {
          console.log(err)
          dispatch({ type: SET_ERROR, payload: err.message })
        }
      }

      try {
        await sendRequest(`iscrizioni/${id}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: "Impossibile eliminare l'iscrizione: ID non trovato!",
      })
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  return { editIscrizione, deleteIscrizione }
}
