import {
  SET_DESIGNAZIONI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
} from './types'

const INIT_STATE = {
  sortingAscending: true,
}

export const designazioniReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DESIGNAZIONI:
      return { ...state, designazioni: action.payload }
    case SET_SORTING_COLUMN:
      return { ...state, sortingColumn: action.payload }
    case SET_SORTING_ASCENDING:
      return { ...state, sortingAscending: action.payload }
    default:
      return state
  }
}
