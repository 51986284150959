import React, { useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import GridCard from '../components/GridCard'
import SelectionBar from '../components/SelectionBar'
import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR, SET_DASHBOARD_STATS } from '../container/home/types'

const DashboardAdmin = ({
  codiceUtente,
  dashboardStats,
  selectedCampionatoGironiPubblicati,
  selectedCampionatoCalendariPubblicati,
  selectedCampionato,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Home'

  const getRequest = useCallback(
    async url =>
      await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
    [codiceUtente]
  )

  const dispatchError = useCallback(
    text =>
      dispatch({
        type: SET_ERROR,
        payload: `Impossibile trovare le statistiche ${text}.`,
      }),
    [dispatch]
  )

  const dispatchStats = useCallback(
    x => dispatch({ type: SET_DASHBOARD_STATS, payload: x }),
    [dispatch]
  )

  // fetch affiliazioni stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest('affiliazioni/stats')

        if (!data) {
          dispatchError('sulle società')
        } else {
          dispatchStats({ affiliazioniStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) fetch()
  }, [codiceUtente, dispatch, dispatchError, dispatchStats, getRequest])

  // fetch arbitri stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest('arbitri/stats')

        if (!data) {
          dispatchError('sugli arbitri')
        } else {
          dispatchStats({ arbitriStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) fetch()
  }, [codiceUtente, dispatch, dispatchError, dispatchStats, getRequest])

  // fetch iscrizioni stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest(
          `iscrizioni/stats/campionato/${selectedCampionato}`
        )

        if (!data) {
          dispatchError('sulle iscrizioni')
        } else {
          dispatchStats({ iscrizioniStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && selectedCampionato) fetch()
  }, [
    codiceUtente,
    dispatch,
    dispatchError,
    dispatchStats,
    getRequest,
    selectedCampionato,
  ])

  // fetch spostamenti stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest(
          `spostamenti/stats/campionato/${selectedCampionato}`
        )

        if (!data) {
          dispatchError('sugli spostamenti')
        } else {
          dispatchStats({ spostamentiStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && selectedCampionato) fetch()
  }, [
    codiceUtente,
    dispatch,
    dispatchError,
    dispatchStats,
    getRequest,
    selectedCampionato,
  ])

  // fetch designazioni stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest(
          `designazioni/stats/campionato/${selectedCampionato}`
        )

        if (!data) {
          dispatchError('sulle designazioni')
        } else {
          dispatchStats({ designazioniStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && selectedCampionato) fetch()
  }, [
    codiceUtente,
    dispatch,
    dispatchError,
    dispatchStats,
    getRequest,
    selectedCampionato,
  ])

  // fetch partite stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest(
          `calendari/stats/campionato/${selectedCampionato}`
        )

        if (!data) {
          dispatchError('sulle partite')
        } else {
          dispatchStats({ partiteStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && selectedCampionato) fetch()
  }, [
    codiceUtente,
    dispatch,
    dispatchError,
    dispatchStats,
    getRequest,
    selectedCampionato,
  ])

  // fetch tabelloni stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest(
          `calendari/categorie/campionato/${selectedCampionato}/finali`
        )

        if (!data) {
          dispatchError('sulle fasi finali')
        } else {
          dispatchStats({ tabelloniStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && selectedCampionato) fetch()
  }, [
    codiceUtente,
    dispatch,
    dispatchError,
    dispatchStats,
    getRequest,
    selectedCampionato,
  ])

  // fetch risultati mancanti
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest(
          `calendari/risultati-mancanti/campionato/${selectedCampionato}`
        )

        if (!data) {
          dispatchError('sui risultati mancanti')
        } else {
          dispatchStats({ risultatiMancanti: data.length })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && selectedCampionato) fetch()
  }, [
    codiceUtente,
    dispatch,
    dispatchError,
    dispatchStats,
    getRequest,
    selectedCampionato,
  ])

  // fetch referti mancanti
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest(
          `calendari/referti-mancanti/campionato/${selectedCampionato}`
        )

        if (!data) {
          dispatchError('sui referti mancanti')
        } else {
          dispatchStats({ refertiMancanti: data.length })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && selectedCampionato) fetch()
  }, [
    codiceUtente,
    dispatch,
    dispatchError,
    dispatchStats,
    getRequest,
    selectedCampionato,
  ])

  // fetch rimborsi stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest('rimborsi/stats')

        if (!data) {
          dispatchError('sui rimborsi')
        } else {
          dispatchStats({ rimborsiStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) fetch()
  }, [codiceUtente, dispatch, dispatchError, dispatchStats, getRequest])

  // fetch rendiconti stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest('rendiconti/stats')

        if (!data) {
          dispatchError('sui rendiconti')
        } else {
          dispatchStats({ rendicontiStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) fetch()
  }, [codiceUtente, dispatch, dispatchError, dispatchStats, getRequest])

  // fetch arbitraggi stats
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getRequest(
          `arbitraggi/stats/campionato/${selectedCampionato}`
        )

        if (!data) {
          dispatchError('sugli arbitraggi')
        } else {
          dispatchStats({ arbitraggiStats: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente && selectedCampionato) fetch()
  }, [
    codiceUtente,
    dispatch,
    dispatchError,
    dispatchStats,
    getRequest,
    selectedCampionato,
  ])

  return (
    <Grid container item xs={12}>
      &nbsp;
      <Grid
        container
        item
        xs={12}
        marginY={2}
        rowSpacing={5}
        alignItems='stretch'
      >
        <SelectionBar
          includeCategoria={false}
          includeGirone={false}
          includeTeam={false}
        />
        <GridCard
          title='Società'
          subtitle1={`${dashboardStats?.affiliazioniStats?.complete || 0} ${
            dashboardStats?.affiliazioniStats?.complete === 1
              ? 'completa'
              : 'complete'
          }`}
          subtitle2={`${dashboardStats?.affiliazioniStats?.incomplete || 0} ${
            dashboardStats?.affiliazioniStats?.incomplete === 1
              ? 'da completare'
              : 'da completare'
          }`}
          linkTo='/affiliazioni'
          isError={dashboardStats?.affiliazioniStats?.incomplete > 0}
        />
        <GridCard
          title='Arbitri'
          subtitle1={`${dashboardStats?.arbitriStats?.complete || 0} ${
            dashboardStats?.arbitriStats?.complete === 1
              ? 'completo'
              : 'completi'
          }`}
          subtitle2={`${dashboardStats?.arbitriStats?.incomplete || 0} ${
            dashboardStats?.arbitriStats?.incomplete === 1
              ? 'da completare'
              : 'da completare'
          }`}
          linkTo='/arbitri'
          isError={dashboardStats?.arbitriStats?.incomplete > 0}
        />
        <GridCard
          title='Iscrizioni'
          subtitle1={`${dashboardStats?.iscrizioniStats?.complete || 0} ${
            dashboardStats?.iscrizioniStats?.complete === 1
              ? 'completa'
              : 'complete'
          }`}
          subtitle2={`${dashboardStats?.iscrizioniStats?.incomplete || 0} ${
            dashboardStats?.iscrizioniStats?.incomplete === 1
              ? 'da completare'
              : 'da completare'
          }`}
          linkTo='/iscrizioni'
          isError={dashboardStats?.iscrizioniStats?.incomplete > 0}
        />
        <GridCard
          title='Spostamenti'
          subtitle1={`${dashboardStats?.spostamentiStats?.accepted || 0} ${
            dashboardStats?.spostamentiStats?.accepted === 1
              ? 'accettato'
              : 'accettati'
          }`}
          subtitle2={`${dashboardStats?.spostamentiStats?.waiting || 0} ${
            dashboardStats?.spostamentiStats?.waiting === 1
              ? 'in sospeso'
              : 'in sospeso'
          }`}
          linkTo='/spostamenti'
          isError={dashboardStats?.spostamentiStats?.waiting > 0}
        />
        <GridCard
          title='Designazioni'
          subtitle1={`${
            dashboardStats?.designazioniStats?.missing10days || 0
          } ${
            dashboardStats?.designazioniStats?.missing10days === 1
              ? 'mancante (10 giorni)'
              : 'mancanti (10 giorni)'
          }`}
          subtitle2={`${dashboardStats?.designazioniStats?.waiting || 0} ${
            dashboardStats?.designazioniStats?.waiting === 1
              ? 'in sospeso'
              : 'in sospeso'
          }`}
          linkTo='/designazioni'
          isError={
            dashboardStats?.designazioniStats?.waiting +
              dashboardStats?.designazioniStats?.missing10days >
            0
          }
        />
        <GridCard
          title='Gironi'
          subtitle1={`${
            selectedCampionatoGironiPubblicati
              ? 'Pubblicati'
              : 'Non ancora pubblicati'
          }`}
          subtitle2={`${dashboardStats?.iscrizioniStats?.gironi || 0} ${
            dashboardStats?.iscrizioniStats?.gironi === 1
              ? 'girone creato'
              : 'gironi creati'
          }`}
          linkTo='/gironi'
          isError={!selectedCampionatoGironiPubblicati}
        />
        <GridCard
          title='Calendari'
          subtitle1={`${
            selectedCampionatoCalendariPubblicati
              ? `${
                  dashboardStats?.partiteStats?.noDateNoForfeit || 0
                } senza data`
              : 'Non ancora pubblicati'
          }`}
          subtitle2={`${dashboardStats?.partiteStats?.all || 0} totali`}
          linkTo='/calendari'
          isError={
            !selectedCampionatoCalendariPubblicati ||
            dashboardStats?.partiteStats?.noDateNoForfeit
          }
        />
        <GridCard
          title='Classifiche'
          subtitle1={`${
            selectedCampionatoCalendariPubblicati
              ? 'Pubblicate'
              : 'Non ancora pubblicate'
          }`}
          subtitle2={`${dashboardStats?.partiteStats?.withResult || 0} ${
            dashboardStats?.partiteStats?.withResult === 1
              ? 'risultato inserito'
              : 'risultati inseriti'
          }`}
          linkTo='/classifiche'
          isError={!selectedCampionatoCalendariPubblicati}
        />
        <GridCard
          title='Fasi finali'
          subtitle1={`${
            dashboardStats?.tabelloniStats?.length > 0
              ? 'Pubblicati'
              : 'Non ancora creati'
          }`}
          subtitle2={`${dashboardStats?.tabelloniStats?.length || 0} ${
            dashboardStats?.tabelloniStats?.length === 1
              ? 'tabellone creato'
              : 'tabelloni creati'
          }`}
          linkTo='/finali'
          isError={!dashboardStats?.tabelloniStats}
        />
        <GridCard
          title='Provvedimenti'
          subtitle1={`${dashboardStats?.risultatiMancanti || 0} ${
            dashboardStats?.risultatiMancanti === 1
              ? 'risultato mancante'
              : 'risultati mancanti'
          }`}
          subtitle2={`${dashboardStats?.refertiMancanti || 0} ${
            dashboardStats?.refertiMancanti === 1
              ? 'referto mancante'
              : 'referti mancanti'
          }`}
          linkTo='/provvedimenti'
          isError={
            dashboardStats?.risultatiMancanti > 0 ||
            dashboardStats?.refertiMancanti > 0
          }
        />
        <GridCard
          title='Rimborsi'
          subtitle1={`${
            dashboardStats?.rimborsiStats?.nonPagati || 0
          } da pagare`}
          subtitle2={`${dashboardStats?.rimborsiStats?.pagati || 0} ${
            dashboardStats?.rimborsiStats?.pagati === 1 ? 'pagato' : 'pagati'
          }`}
          linkTo='/rimborsi'
          isError={dashboardStats?.rimborsiStats?.nonPagati > 0}
        />
        <GridCard
          title='Rendiconti'
          subtitle1={`${dashboardStats?.rendicontiStats?.all || 0} ${
            dashboardStats?.rendicontiStats?.all === 1 ? 'totale' : 'totali'
          }`}
          subtitle2={`${dashboardStats?.rendicontiStats?.paid || 0} ${
            dashboardStats?.rendicontiStats?.paid === 1 ? 'pagato' : 'pagati'
          }`}
          linkTo='/rendiconti'
          isError={
            dashboardStats?.rendicontiStats?.all >
            dashboardStats?.rendicontiStats?.paid
          }
        />
        <GridCard
          title='Arbitraggi'
          subtitle1={`${dashboardStats?.arbitraggiStats?.nRequired || 0} ${
            dashboardStats?.arbitraggiStats?.nRequired === 1
              ? 'richiesta'
              : 'richieste'
          }`}
          linkTo='/arbitraggi'
        />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  dashboardStats: state.home.dashboardStats,
  selectedCampionatoGironiPubblicati:
    state.home.selectedCampionatoGironiPubblicati,
  selectedCampionatoCalendariPubblicati:
    state.home.selectedCampionatoCalendariPubblicati,
  selectedCampionato: state.home.selectedCampionato,
})

const ConnectedDashboardAdmin = connect(mapStateToProps)(DashboardAdmin)

export default ConnectedDashboardAdmin
