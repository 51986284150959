import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'

import Select from '../components/Select'
import InputField from '../components/InputField'
import DialogMini from '../components/DialogMini'
import { ColouredButton } from '../components/Buttons'
import {
  colours,
  titlesAffiliazione as titles,
  phrasesAffiliazione as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { errorFunctions } from '../hooks/error-functions'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import {
  RESET_AFFILIAZIONE,
  SET_CODICE_SOCIETA,
  SET_DENOMINAZIONE_SOCIETA,
  SET_NOME_SOCIETA,
  SET_REFERENTE,
  SET_RUOLO,
  SET_TELEFONO,
  SET_MAIL,
  SET_ZONA,
  SET_PASSWORD,
  SET_IS_ACTIVE,
} from '../container/affiliazione/types'

const Affiliazione = ({
  codiceUtente,
  zone,
  dummyUpdate,
  mode,
  idSocieta,
  codiceSocieta,
  denominazioneSocieta,
  nomeSocieta,
  password,
  referente,
  ruolo,
  telefono,
  mail,
  zona,
  isActive,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = `PGS Milano - ${
      mode === 'add' ? 'Nuova' : 'Modifica'
    } società`
  }, [mode])

  // parameters
  const [completeInputs, setCompleteInputs] = useState(false)
  const [validInputs, setValidInputs] = useState(false)

  // error functions
  const { errorMinLength, errorTelefono, errorMail, errorCodice } =
    errorFunctions()

  const errorDenominazione = useCallback(
    x => errorMinLength(x, 10),
    [errorMinLength]
  )
  const errorNome = useCallback(x => errorMinLength(x, 4), [errorMinLength])
  const errorPassword = useCallback(x => errorMinLength(x, 8), [errorMinLength])
  const errorReferente = useCallback(
    x => errorMinLength(x, 6),
    [errorMinLength]
  )
  const errorZona = useCallback(
    x => !x || !zone?.map(z => z.zona).includes(x),
    [zone]
  )

  const [openUndo, setOpenUndo] = useState(false)
  const [openCheck, setOpenCheck] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openFailure, setOpenFailure] = useState(false)

  const handleClickOpenUndo = () => {
    setOpenUndo(true)
  }

  const handleClickOpenCheck = () => {
    setOpenCheck(true)
  }

  const handleClickOpenSuccess = () => {
    setOpenSuccess(true)
  }

  const handleClickOpenFailure = () => {
    setOpenFailure(true)
  }

  const handleCloseUndo = () => {
    setOpenUndo(false)
  }

  const handleCloseCheck = () => {
    setOpenCheck(false)
  }

  const handleCloseSuccess = () => {
    setOpenSuccess(false)
  }

  const handleCloseFailure = () => {
    setOpenFailure(false)
  }

  // check inputs completeness
  useEffect(() => {
    if (
      !errorCodice(codiceSocieta) &&
      !errorDenominazione(denominazioneSocieta) &&
      !errorNome(nomeSocieta) &&
      !errorPassword(password) &&
      !errorReferente(referente) &&
      !errorTelefono(telefono) &&
      !errorMail(mail) &&
      !errorZona(zona)
    ) {
      setCompleteInputs(true)
    } else {
      setCompleteInputs(false)
    }
  }, [
    codiceSocieta,
    denominazioneSocieta,
    errorCodice,
    errorDenominazione,
    errorMail,
    errorNome,
    errorPassword,
    errorReferente,
    errorTelefono,
    errorZona,
    mail,
    nomeSocieta,
    password,
    referente,
    telefono,
    zona,
  ])

  // check inputs validity
  useEffect(() => {
    if (
      !errorCodice(codiceSocieta) &&
      !errorDenominazione(denominazioneSocieta) &&
      !errorNome(nomeSocieta) &&
      !errorPassword(password)
    ) {
      setValidInputs(true)
    } else {
      setValidInputs(false)
    }
  }, [
    codiceSocieta,
    denominazioneSocieta,
    errorCodice,
    errorDenominazione,
    errorNome,
    errorPassword,
    nomeSocieta,
    password,
  ])

  // add function
  const addAffiliazione = async () => {
    try {
      await sendRequest(
        `affiliazioni/add`,
        'POST',
        JSON.stringify({
          codiceSocieta,
          denominazioneSocieta,
          nomeSocieta,
          password,
          referente,
          ruolo,
          telefono,
          mail,
          zona,
          isActive,
          status: (completeInputs && 'complete') || 'incomplete',
        }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      handleClickOpenSuccess()
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
      handleClickOpenFailure()
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // update function
  const updateAffiliazione = async () => {
    try {
      await sendRequest(
        `affiliazioni/${idSocieta}`,
        'PUT',
        JSON.stringify({
          codiceSocieta,
          denominazioneSocieta,
          nomeSocieta,
          password,
          referente,
          ruolo,
          telefono,
          mail,
          zona,
          isActive,
          status: (completeInputs && 'complete') || 'incomplete',
        }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      handleClickOpenSuccess()
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
      handleClickOpenFailure()
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  const confirmAffiliazione = () => {
    handleCloseCheck()
    if (mode === 'add') addAffiliazione()
    if (mode === 'update') updateAffiliazione()
  }

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: RESET_AFFILIAZIONE })
  }, [dispatch])

  return (
    <Grid container direction='column' paddingY={2}>
      <Grid item>
        <center>
          <h2>{`${mode === 'add' ? 'Nuova' : 'Modifica'} società`}</h2>
        </center>
      </Grid>
      &nbsp;
      <Grid container paddingX={3}>
        <Grid container item xs={12} columnSpacing={5}>
          <Grid item xs={12} md={6}>
            <InputField
              id='codice-societa'
              value={codiceSocieta}
              onChange={i =>
                dispatch({ type: SET_CODICE_SOCIETA, payload: i || '' })
              }
              autoFocus={true}
              fullWidth
              label='Codice di affiliazione PGS'
              errorText='Codice non valido!'
              errorFunc={errorCodice}
              mode={mode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              id='password'
              value={password}
              onChange={i => dispatch({ type: SET_PASSWORD, payload: i || '' })}
              fullWidth
              label='Password'
              errorText='Password troppo corta!'
              errorFunc={errorPassword}
              mode={mode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              id='denominazione-societa'
              value={denominazioneSocieta}
              onChange={i =>
                dispatch({
                  type: SET_DENOMINAZIONE_SOCIETA,
                  payload: i.toUpperCase() || '',
                })
              }
              fullWidth
              label='Denominazione ufficiale della società'
              errorText='Nome troppo corto!'
              errorFunc={errorDenominazione}
              mode={mode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              id='nome-societa'
              value={nomeSocieta}
              onChange={i =>
                dispatch({
                  type: SET_NOME_SOCIETA,
                  payload: i.toUpperCase() || '',
                })
              }
              fullWidth
              label='Nome breve della società'
              errorText='Nome troppo corto!'
              errorFunc={errorNome}
              mode={mode}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h4>Contatti della società</h4>
        </Grid>
        <Grid container item xs={12} columnSpacing={5}>
          <Grid item xs={12} md={3}>
            <InputField
              id='referente'
              value={referente}
              onChange={i =>
                dispatch({ type: SET_REFERENTE, payload: i || '' })
              }
              fullWidth
              label='Referente'
              errorText='Nome troppo corto!'
              errorFunc={errorReferente}
              adornment='person'
              mode={mode}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              id='ruolo'
              value={ruolo}
              onChange={i => dispatch({ type: SET_RUOLO, payload: i || '' })}
              fullWidth
              label='Ruolo'
              adornment='work'
              mode={mode}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              id='telefono'
              value={telefono}
              onChange={i =>
                dispatch({
                  type: SET_TELEFONO,
                  payload: i.replace(/\s/g, '') || '',
                })
              }
              fullWidth
              label='Telefono'
              errorText='Telefono non valido!'
              errorFunc={errorTelefono}
              type='tel'
              adornment='phone'
              mode={mode}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              id='mail'
              value={mail}
              onChange={i =>
                dispatch({
                  type: SET_MAIL,
                  payload: i.toLowerCase().replace(/\s/g, '') || '',
                })
              }
              fullWidth
              label='E-mail'
              errorText='E-mail non valida!'
              errorFunc={errorMail}
              type='email'
              adornment='email'
              mode={mode}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h4>Altre informazioni</h4>
        </Grid>
        <Grid container item xs={12} columnSpacing={5}>
          <Grid item xs={12} md={6}>
            <Select
              id='zona'
              value={zona}
              options={zone?.map(z => z.zona)}
              optionLabels={zone?.map(z => `${z.zona} - ${z.descrizione}`)}
              label='Zona geografica'
              onChange={i => dispatch({ type: SET_ZONA, payload: i })}
              errorText='Selezionare una zona!'
              mode={mode}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            Società attiva &nbsp;
            <Checkbox
              id='is-active'
              checked={isActive}
              onChange={event =>
                dispatch({ type: SET_IS_ACTIVE, payload: event.target.checked })
              }
              color='default'
            />
          </Grid>
        </Grid>
        &nbsp;
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <center>
              {!validInputs ? (
                <h4 style={{ color: colours.red }}>{phrases.phraseInvalid}</h4>
              ) : !!completeInputs ? (
                <h4 style={{ color: colours.blueDark }}>
                  {mode === 'add'
                    ? phrases.phraseCompleteAdd
                    : phrases.phraseCompleteUpdate}
                </h4>
              ) : (
                <h4 style={{ color: colours.red }}>
                  {mode === 'add'
                    ? phrases.phraseIncompleteAdd
                    : phrases.phraseIncompleteUpdate}
                </h4>
              )}
            </center>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs>
            <center>
              <ColouredButton
                textcolour={colours.white}
                backgroundcolour={colours.red}
                hovercolour={colours.redDark}
                onClick={handleClickOpenUndo}
              >
                Indietro
              </ColouredButton>
            </center>
          </Grid>
          <Grid item xs>
            <center>
              <ColouredButton
                textcolour={colours.white}
                textbold='bold'
                backgroundcolour={colours.blueDark}
                hovercolour={colours.blueVeryDark}
                disabled={!validInputs}
                onClick={handleClickOpenCheck}
              >
                Salva
              </ColouredButton>
            </center>
          </Grid>
        </Grid>
      </Grid>
      <DialogMini
        open={openUndo}
        handleClose={handleCloseUndo}
        title={titles.titleUndo}
        textContent={phrases.phraseUndo}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={handleCloseUndo}
        triggerPath='/affiliazioni'
        colourBackground={colours.red}
        colourHover={colours.redDark}
      />
      <DialogMini
        open={openCheck}
        handleClose={handleCloseCheck}
        title={titles.titleCheck}
        textContent={
          (!!completeInputs &&
            (mode === 'add'
              ? phrases.phraseCompleteAdd
              : phrases.phraseCompleteUpdate)) ||
          (mode === 'add'
            ? phrases.phraseIncompleteAdd
            : phrases.phraseIncompleteUpdate)
        }
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={confirmAffiliazione}
      />
      <DialogMini
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title={
          mode === 'add' ? titles.titleSuccessAdd : titles.titleSuccessUpdate
        }
        textContent={
          (!!completeInputs &&
            (mode === 'add'
              ? phrases.phraseCompleteSuccessAdd
              : phrases.phraseCompleteSuccessUpdate)) ||
          (mode === 'add'
            ? phrases.phraseIncompleteSuccessAdd
            : phrases.phraseIncompleteSuccessUpdate)
        }
        textConfirm='Ok'
        triggerFunction={handleCloseSuccess}
        triggerPath='/affiliazioni'
      />
      <DialogMini
        open={openFailure}
        handleClose={handleCloseFailure}
        title={titles.titleFailure}
        textContent={phrases.phraseFailure}
        textConfirm='Ok'
        triggerFunction={handleCloseFailure}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  zone: state.home.zone,
  dummyUpdate: state.home.dummyUpdate,
  mode: state.affiliazione.mode,
  idSocieta: state.affiliazione.idSocieta,
  codiceSocieta: state.affiliazione.codiceSocieta,
  denominazioneSocieta: state.affiliazione.denominazioneSocieta,
  nomeSocieta: state.affiliazione.nomeSocieta,
  password: state.affiliazione.password,
  referente: state.affiliazione.referente,
  ruolo: state.affiliazione.ruolo,
  telefono: state.affiliazione.telefono,
  mail: state.affiliazione.mail,
  zona: state.affiliazione.zona,
  isActive: state.affiliazione.isActive,
})

const ConnectedAffiliazione = connect(mapStateToProps)(Affiliazione)

export default ConnectedAffiliazione
