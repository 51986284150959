import {
  RESET_ISCRIZIONE,
  SET_ISCRIZIONE,
  SET_MODE,
  SET_ID_SQUADRA,
  SET_CODICE_CAMPIONATO,
  SET_CODICE_SOCIETA,
  SET_NOME_SQUADRA,
  SET_CATEGORIA,
  SET_ABBINAMENTI,
  SET_ALTERNANZE,
  SET_NOTE,
  SET_SENZA_PALESTRA,
  SET_GIORNO,
  SET_ORARIO,
  SET_CITTA,
  SET_INDIRIZZO,
  SET_PRESSO,
  SET_NOME_REFERENTE,
  SET_TELEFONO_REFERENTE,
  SET_MAIL_REFERENTE,
  SET_NOME_REFERENTE_2,
  SET_TELEFONO_REFERENTE_2,
  SET_MAIL_REFERENTE_2,
  SET_CHECK_PRIVACY,
  SET_HAS_DEROGA,
  SET_MOTIVO_DEROGA,
} from './types'

const INIT_STATE = {
  mode: 'add',
  idSquadra: undefined,
  codiceCampionato: null,
  codiceSocieta: null,
  nomeSquadra: '',
  categoria: null,
  abbinamenti: [],
  alternanze: [],
  note: '',
  senzaPalestra: false,
  giorno: null,
  orario: null,
  citta: '',
  indirizzo: '',
  presso: '',
  nomeReferente: '',
  telefonoReferente: '',
  mailReferente: '',
  nomeReferente2: '',
  telefonoReferente2: '',
  mailReferente2: '',
  checkPrivacy: false,
  girone: null,
  posizione: null,
  hasDeroga: false,
  motivoDeroga: '',
}

export const iscrizioneReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ISCRIZIONE:
      return INIT_STATE
    case SET_ISCRIZIONE:
      return { ...state, ...action.payload, idSquadra: action.payload._id }
    case SET_MODE:
      return { ...state, mode: action.payload }
    case SET_ID_SQUADRA:
      return { ...state, idSquadra: action.payload }
    case SET_CODICE_CAMPIONATO:
      return { ...state, codiceCampionato: action.payload }
    case SET_CODICE_SOCIETA:
      return { ...state, codiceSocieta: action.payload }
    case SET_NOME_SQUADRA:
      return { ...state, nomeSquadra: action.payload }
    case SET_CATEGORIA:
      return { ...state, categoria: action.payload }
    case SET_SENZA_PALESTRA:
      return { ...state, senzaPalestra: action.payload }
    case SET_ABBINAMENTI:
      return { ...state, abbinamenti: action.payload }
    case SET_ALTERNANZE:
      return { ...state, alternanze: action.payload }
    case SET_NOTE:
      return { ...state, note: action.payload }
    case SET_GIORNO:
      return { ...state, giorno: action.payload }
    case SET_ORARIO:
      return { ...state, orario: action.payload }
    case SET_CITTA:
      return { ...state, citta: action.payload }
    case SET_INDIRIZZO:
      return { ...state, indirizzo: action.payload }
    case SET_PRESSO:
      return { ...state, presso: action.payload }
    case SET_NOME_REFERENTE:
      return { ...state, nomeReferente: action.payload }
    case SET_TELEFONO_REFERENTE:
      return { ...state, telefonoReferente: action.payload }
    case SET_MAIL_REFERENTE:
      return { ...state, mailReferente: action.payload }
    case SET_NOME_REFERENTE_2:
      return { ...state, nomeReferente2: action.payload }
    case SET_TELEFONO_REFERENTE_2:
      return { ...state, telefonoReferente2: action.payload }
    case SET_MAIL_REFERENTE_2:
      return { ...state, mailReferente2: action.payload }
    case SET_CHECK_PRIVACY:
      return { ...state, checkPrivacy: action.payload }
    case SET_HAS_DEROGA:
      return { ...state, hasDeroga: action.payload }
    case SET_MOTIVO_DEROGA:
      return { ...state, motivoDeroga: action.payload }
    default:
      return state
  }
}
