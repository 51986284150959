import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import InputField from './InputField'
import { errorFunctions } from '../hooks/error-functions'
import { SET_OLD_PASSWORD, SET_NEW_PASSWORD } from '../container/home/types'

const DialogCambioPassword = ({ oldPassword, newPassword }) => {
  const dispatch = useDispatch()

  // error functions
  const { errorMinLength } = errorFunctions()
  const errorPassword = x => errorMinLength(x, 8)

  return (
    <>
      <Grid item xs={12}>
        <InputField
          id='old-password'
          value={oldPassword}
          onChange={i => dispatch({ type: SET_OLD_PASSWORD, payload: i })}
          fullWidth
          label='Vecchia password'
          type='password'
        />
      </Grid>
      <Grid item xs={12}>
        <InputField
          id='new-password'
          value={newPassword}
          onChange={i => dispatch({ type: SET_NEW_PASSWORD, payload: i })}
          fullWidth
          label='Nuova password'
          errorText='Password troppo corta!'
          errorFunc={errorPassword}
          type='password'
        />
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  oldPassword: state.home.oldPassword,
  newPassword: state.home.newPassword,
})

const ConnectedDialogCambioPassword =
  connect(mapStateToProps)(DialogCambioPassword)

export default ConnectedDialogCambioPassword
